import { greyPalette, secondaryPalette, theme } from "@aos/react-components";
import { IJobProcessUiModel, ITokenDecoded, IUserDbModel, JobStatusEnum } from "@kortex/aos-common";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import PreviewIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import * as React from "react";
import { useContext, useEffect, useState } from "react";

import TrackingIcon from "../../../../components/core/Icons/Tracking/Tracking";
import KortexLabelIcon from "../../../../components/core/KortexLabelIcon";
import { useTranslate } from "../../../../hooks/useTranslate";
import { jobJobProcessDelete, jobJobRoutingDelete } from "../../../../redux/scheduler-manager/scheduler-thunks-job";
import { userCanInsert } from "../../../../utilitites/IUserRights";
import { getProcessTitle } from "../../../../utilitites/getProcessTitle";
import PlayerDialog from "../../../core/ProcessPlayer/PlayerDialog";
import { SchedulerContext } from "../utilities";

import JobProcessCardContainer from "./JobProcessCardContainer";
import SchedulerJobCardHeader from "./SchedulerJobHeaderCard";
import SchedulerJobTrackingDialog from "./SchedulerJobTrackingDialog";

const useStyles = makeStyles({
    mainContainer: {
        height: "calc(100% - 32px)", // page paddings (32px)
    },
    cardSpacer: {
        flexGrow: 2,
    },
    cardFooter: {
        backgroundColor: greyPalette[200],
        color: greyPalette[600],
        borderTop: "solid 1px",
        borderTopColor: greyPalette[400],
        flexFlow: "row",
        flexDirection: "row",
        display: "flex",
        padding: "0px",
    },
    menuItem: {
        width: "68px",
    },
    dialogContent: {
        height: "60vh",
        width: "80vw",
        maxWidth: "1102px",
    },
    dialogTitle: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
    },
    processPage: {
        margin: "0px 0px",
        borderRadius: "0px",
        overflow: "auto",
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 183px)", // header (64px), page paddings (32px), search bar height (77px) and bottom margin (10px)
    },
    processPageContentMargin: {
        margin: "10px",
    },
    endBox: {
        width: "100%",
        minHeight: "5px",
        borderBottom: "1px",
        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
        borderTop: "0px",
    },
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 100,
    },
    noJobSelectedLabel: {
        height: "100%",
        color: secondaryPalette[500],
        textAlign: "center",
    },
});

interface IOwnProps {
    plannersList: IUserDbModel[];
    userInfo?: ITokenDecoded;
}

export default function SchedulerJobEditor(props: IOwnProps): JSX.Element {
    const { plannersList, userInfo } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const [selectedJobProcess, setSelectedJobProcess] = useState<IJobProcessUiModel | undefined>(undefined);
    const [selectedJobRoutingId, setSelectedJobRoutingId] = useState<number | undefined>(undefined);
    const [selectedOrderId, setSelectedOrderId] = useState<number>(0);
    const [playerDialogOpened, setPlayerDialogOpened] = useState<boolean>(false);
    const [isTrackingDialogOpen, setTrackingDialogOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedJobRoutingIndex, setSelectedJobRoutingIndex] = useState<number | undefined>(undefined);

    const [selectedJob] = useContext(SchedulerContext);

    /**
     * Updates index of selected process when selecting another job
     */
    useEffect((): void => {
        if (!selectedJob) {
            setSelectedJobProcess(undefined);
            setIsLoading(false);
        } else if (selectedJob.jobId !== selectedJob.jobId) {
            setSelectedJobProcess(undefined);
            setIsLoading(true);
        }
    }, [selectedJob]);

    /**
     * close the runner dialog
     *
     * opened {boolean} - Open or close the dialog
     */
    const togglePlayerDialog =
        (opened: boolean): (() => void) =>
        (): void => {
            setPlayerDialogOpened(opened);
        };

    /**
     * Handle called when the user hit delete to delete a process within a job header item is updated
     */
    const handleDeleteProcess = (): void => {
        if (selectedJob?.processList) {
            if (selectedJobProcess) {
                dispatch(jobJobProcessDelete(selectedJobProcess));
            } else if (selectedJobRoutingId) {
                dispatch(
                    jobJobRoutingDelete({
                        jobId: selectedJob.jobId,
                        orderId: selectedOrderId,
                        routingId: selectedJobRoutingId,
                    })
                );
            }
            setSelectedJobProcess(undefined);
            setSelectedJobRoutingIndex(undefined);
            setSelectedJobRoutingId(undefined);
        }
    };

    /**
     * Check if the element can be deleted
     *
     * return {boolean} - true if the card can not be deleted
     */
    const canNotBeDeleted = (): boolean => {
        if (!selectedJob) {
            return true;
        } else if (selectedJob.status !== JobStatusEnum.DRAFT) {
            return true;
        } else if (selectedJobProcess !== undefined && !selectedJobProcess.routingId && userCanInsert(userInfo?.roleRights.scheduler)) {
            return false;
        } else if (selectedJobProcess !== undefined && selectedJobProcess.routingId) {
            return true;
        } else if (selectedJobRoutingId && userCanInsert(userInfo?.roleRights.scheduler)) {
            return false;
        } else {
            return true;
        }
    };

    /**
     * Handle request to open the tracking dialog
     */
    const handleTrackingDialogOpen = (): void => {
        setTrackingDialogOpen(true);
    };

    /**
     * Handle request to close the tracking dialog
     */
    const handleTrackingDialogClose = (): void => {
        setTrackingDialogOpen(false);
    };

    /**
     * Handle that is called when the user select a process in the job
     *
     * @param {IJobProcessUiModel} process - process selected
     */
    const handleOnProcessSelected = (process: IJobProcessUiModel): void => {
        setSelectedJobRoutingId(undefined);
        setSelectedJobProcess(process);
    };

    /**
     * Handle that is called when the user select a routing in the job
     *
     * @param {number} routingId - routing selected
     */
    const handleOnRoutingSelected = (routingId: number): void => {
        setSelectedJobProcess(undefined);
        setSelectedJobRoutingId(routingId);
    };

    /**
     * Handle that is called when the user select a process in the job to set the orderId of the process selected
     *
     * @param {number} orderId - routing selected
     */
    const handleOnOrderIdSelected = (orderId: number): void => {
        setSelectedOrderId(orderId);
    };

    /**
     * Handle that jobProcess have been loaded, used to stop the spinner.
     */
    const handleJobsLoaded = (): void => {
        setIsLoading(false);
    };

    return (
        <div className={classes.mainContainer}>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{ display: "grid", gridTemplateRows: "auto 1fr auto", height: "calc(100vh - 64px - 32px)" }}>
                <div className={classes.processPage}>
                    <div className={classes.processPageContentMargin}>
                        {!selectedJob && (
                            <KortexPanelCard isSelected={false}>
                                <Typography className={classes.noJobSelectedLabel} variant="body1">
                                    {translate("scheduler.NoJobSelected")}
                                </Typography>
                            </KortexPanelCard>
                        )}
                        {selectedJob?.processList && (
                            <>
                                {/* JOB HEADER */}
                                <SchedulerJobCardHeader plannerList={plannersList} userInfo={userInfo} />
                                {/* JOB PROCESS EDITOR */}
                                <JobProcessCardContainer
                                    onProcessSelected={handleOnProcessSelected}
                                    onOrderIdSelected={handleOnOrderIdSelected}
                                    onRoutingSelected={handleOnRoutingSelected}
                                    onJobsLoaded={handleJobsLoaded}
                                    userInfo={userInfo}
                                    setSelectedJobRoutingIndex={setSelectedJobRoutingIndex}
                                    selectedJobRoutingIndex={selectedJobRoutingIndex}
                                />
                            </>
                        )}
                    </div>
                </div>
                {/* END SPACER*/}
                <div className={classes.cardSpacer} />
                {/* BOTTOM MENU */}
                <List className={classes.cardFooter}>
                    <ListItem
                        className={classes.menuItem}
                        button={true}
                        disabled={selectedJobProcess === undefined}
                        onClick={togglePlayerDialog(true)}
                    >
                        <KortexLabelIcon label={translate("scheduler.dryRun")}>
                            <PreviewIcon />
                        </KortexLabelIcon>
                    </ListItem>
                    <ListItem className={classes.menuItem} button={true} onClick={handleTrackingDialogOpen}>
                        <KortexLabelIcon label={translate("scheduler.trackingList")}>
                            <TrackingIcon />
                        </KortexLabelIcon>
                    </ListItem>
                    <ListItem className={classes.menuItem} button={true} disabled={canNotBeDeleted()} onClick={handleDeleteProcess}>
                        <KortexLabelIcon label={translate("scheduler.delete")}>
                            <DeleteIcon />
                        </KortexLabelIcon>
                    </ListItem>
                </List>
                {selectedJob && selectedJobProcess !== undefined && (
                    <PlayerDialog
                        processId={selectedJobProcess.processId}
                        dryRunMode={true}
                        testRunMode={false}
                        reworkId={selectedJob.reworkId}
                        onClose={togglePlayerDialog(false)}
                        open={Boolean(selectedJobProcess) && playerDialogOpened}
                        title={getProcessTitle(selectedJobProcess, true, translate)}
                    />
                )}
            </div>
            {isTrackingDialogOpen && selectedJob && (
                <SchedulerJobTrackingDialog isDialogOpen={isTrackingDialogOpen} onDialogClose={handleTrackingDialogClose} />
            )}
        </div>
    );
}
