export const processPlayer = {
    rpc: {
        play: {
            funcId: 6200,
            funcName: "processPlayerPlay",
        },
        stop: {
            funcId: 6201,
            funcName: "processPlayerStop",
        },
        pause: {
            funcId: 6202,
            funcName: "processPlayerPause",
        },
        resume: {
            funcId: 6203,
            funcName: "processPlayerResume",
        },
        retry: {
            funcId: 6204,
            funcName: "processPlayerRetry",
        },
        updateProcessState: {
            funcId: 6205,
            funcName: "processPlayerUpdateProcessState",
        },
        failStep: {
            funcId: 6206,
            funcName: "processPlayerFailStep",
        },
        getDashboardInfo: {
            funcId: 6207,
            funcName: "processPlayerGetDashboardInfo",
        },
        runnerDashboardInfoUpdated: {
            funcId: 6208,
            funcName: "runnerDashboardInfoUpdated",
        },
        getHistory: {
            funcId: 6209,
            funcName: "processPlayerGetHistory",
        },
        notificationReceived: {
            funcId: 6210,
            funcName: "processPlayerNotificationReceived",
        },
        insertRework: {
            funcId: 6211,
            funcName: "processPlayerInsertRework",
        },
        playNextEnabled: {
            funcId: 40011,
            funcName: "processPlayerPlayNextEnabled",
        },
        stateInitialized: {
            funcId: 40012,
            funcName: "processPlayerStateInitalized",
        },
        historyUpdated: {
            funcId: 40013,
            funcName: "processPlayerHistoryUpdated",
        },
        hubConnectionUpdated: {
            funcId: 40014,
            funcName: "processPlayerHubConnectionUpdated",
        },
        uiActionPropsUpdated: {
            funcId: 40015,
            funcName: "processPlayerUiActionPropsUpdated",
        },
        actionStateUpdated: {
            funcId: 40016,
            funcName: "processPlayerActionStateUpdated",
        },
        processStateUpdated: {
            funcId: 40017,
            funcName: "processPlayerProcessStateUpdated",
        },
    },
    serviceId: 62,
    serviceVersion: 1,
};
