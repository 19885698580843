export function createBomFollowUpHistory(bomFollowUpHistory) {
    const { bomFollowUpHistoryId = 0, justification = "", previousQuantity = 0, previousTraceability = "", quantity, traceability, type, userId, bomFollowUpId, } = bomFollowUpHistory;
    return {
        bomFollowUpHistoryId,
        justification,
        previousQuantity,
        previousTraceability,
        quantity,
        traceability,
        type,
        userId,
        bomFollowUpId,
    };
}
