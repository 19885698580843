import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    ProcessPlayerActionStateUpdatedNotif,
    ProcessPlayerFailStepReq,
    ProcessPlayerFailStepRes,
    ProcessPlayerGetDashboardInfoReq,
    ProcessPlayerGetDashboardInfoRes,
    ProcessPlayerGetHistoryReq,
    ProcessPlayerGetHistoryRes,
    ProcessPlayerHistoryUpdatedNotif,
    ProcessPlayerHubConnectionUpdatedNotif,
    ProcessPlayerInsertReworkReq,
    ProcessPlayerInsertReworkRes,
    ProcessPlayerNotificationReceivedNotif,
    ProcessPlayerPauseReq,
    ProcessPlayerPauseRes,
    ProcessPlayerPlayDesktopReq,
    ProcessPlayerPlayDesktopRes,
    ProcessPlayerPlayNextEnabledNotif,
    ProcessPlayerPlayReq,
    ProcessPlayerPlayRes,
    ProcessPlayerProcessStateUpdatedNotif,
    ProcessPlayerResumeReq,
    ProcessPlayerResumeRes,
    ProcessPlayerRetryReq,
    ProcessPlayerRetryRes,
    ProcessPlayerRunnerDashboardInfoUpdatedNotif,
    ProcessPlayerStateInitializedNotif,
    ProcessPlayerStopReq,
    ProcessPlayerStopRes,
    ProcessPlayerUiActionPropsUpdatedNotif,
    ProcessPlayerUpdateProcessStateReq,
    ProcessPlayerUpdateProcessStateRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { processPlayerActionStateUpdated } from "./actionStateUpdated";
import { processPlayerPlay as processPlayerPlayDesktop } from "./desktop/play";
import { processPlayerFailStep } from "./failStep";
import { processPlayerGetDashboardInfo } from "./getDashboardInfo";
import { processPlayerGetHistory } from "./getHistory";
import { processPlayerHistoryUpdated } from "./historyUpdated";
import { processPlayerHubConnectionUpdated } from "./hubConnectionUpdated";
import { processPlayerInsertRework } from "./insertRework";
import { processPlayerOnNotificationReceived } from "./notificationReceived";
import { processPlayerPause } from "./pause";
import { processPlayerPlayNextEnabled } from "./playNextEnabled";
import { processPlayerProcessStateUpdated } from "./processStateUpdated";
import { processPlayerResume } from "./resume";
import { processPlayerRetry } from "./retry";
import { processPlayerRunnerDashboardInfoUpdated } from "./runnerDashboardInfoUpdated";
import { processPlayerStateInitalized } from "./stateInitialized";
import { processPlayerStop } from "./stop";
import { processPlayerPlay as processPlayerPlayUI } from "./ui/play";
import { processPlayerUiActionPropsUpdated } from "./uiActionPropsUpdated";
import { processPlayerUpdateProcessState } from "./updateProcessState";

interface IAOSClientServiceWrappedProcessPlayerDesktop {
    failStep: AOSClientServiceRequestWrapped<ProcessPlayerFailStepReq, ProcessPlayerFailStepRes>;
    getHistory: AOSClientServiceRequestWrapped<ProcessPlayerGetHistoryReq, ProcessPlayerGetHistoryRes>;
    insertRework: AOSClientServiceRequestWrapped<ProcessPlayerInsertReworkReq, ProcessPlayerInsertReworkRes>;
    pause: AOSClientServiceRequestWrapped<ProcessPlayerPauseReq, ProcessPlayerPauseRes>;
    play: AOSClientServiceRequestWrapped<ProcessPlayerPlayDesktopReq, ProcessPlayerPlayDesktopRes>;
    resume: AOSClientServiceRequestWrapped<ProcessPlayerResumeReq, ProcessPlayerResumeRes>;
    retry: AOSClientServiceRequestWrapped<ProcessPlayerRetryReq, ProcessPlayerRetryRes>;
    stop: AOSClientServiceRequestWrapped<ProcessPlayerStopReq, ProcessPlayerStopRes>;
    updateProcessState: AOSClientServiceRequestWrapped<ProcessPlayerUpdateProcessStateReq, ProcessPlayerUpdateProcessStateRes>;
    getDashboardInfo: AOSClientServiceRequestWrapped<ProcessPlayerGetDashboardInfoReq, ProcessPlayerGetDashboardInfoRes>;
    onActionStateUpdated: AOSClientServiceNotificationWrapped<ProcessPlayerActionStateUpdatedNotif>;
    onHistoryUpdated: AOSClientServiceNotificationWrapped<ProcessPlayerHistoryUpdatedNotif>;
    onHubConnectionUpdated: AOSClientServiceNotificationWrapped<ProcessPlayerHubConnectionUpdatedNotif>;
    onNotificationReceived: AOSClientServiceNotificationWrapped<ProcessPlayerNotificationReceivedNotif>;
    onPlayNextEnabled: AOSClientServiceNotificationWrapped<ProcessPlayerPlayNextEnabledNotif>;
    onProcessStateUpdated: AOSClientServiceNotificationWrapped<ProcessPlayerProcessStateUpdatedNotif>;
    onRunnerDashboardInfoUpdated: AOSClientServiceNotificationWrapped<ProcessPlayerRunnerDashboardInfoUpdatedNotif>;
    onStateInitialized: AOSClientServiceNotificationWrapped<ProcessPlayerStateInitializedNotif>;
    onUiActionPropsUpdated: AOSClientServiceNotificationWrapped<ProcessPlayerUiActionPropsUpdatedNotif>;
}

interface IAOSClientServiceWrappedProcessPlayerUi {
    failStep: AOSClientServiceRequestWrapped<ProcessPlayerFailStepReq, ProcessPlayerFailStepRes>;
    getHistory: AOSClientServiceRequestWrapped<ProcessPlayerGetHistoryReq, ProcessPlayerGetHistoryRes>;
    insertRework: AOSClientServiceRequestWrapped<ProcessPlayerInsertReworkReq, ProcessPlayerInsertReworkRes>;
    pause: AOSClientServiceRequestWrapped<ProcessPlayerPauseReq, ProcessPlayerPauseRes>;
    play: AOSClientServiceRequestWrapped<ProcessPlayerPlayReq, ProcessPlayerPlayRes>;
    resume: AOSClientServiceRequestWrapped<ProcessPlayerResumeReq, ProcessPlayerResumeRes>;
    retry: AOSClientServiceRequestWrapped<ProcessPlayerRetryReq, ProcessPlayerRetryRes>;
    stop: AOSClientServiceRequestWrapped<ProcessPlayerStopReq, ProcessPlayerStopRes>;
    updateProcessState: AOSClientServiceRequestWrapped<ProcessPlayerUpdateProcessStateReq, ProcessPlayerUpdateProcessStateRes>;
    getDashboardInfo: AOSClientServiceRequestWrapped<ProcessPlayerGetDashboardInfoReq, ProcessPlayerGetDashboardInfoRes>;
    onActionStateUpdated: AOSClientServiceNotificationWrapped<ProcessPlayerActionStateUpdatedNotif>;
    onHistoryUpdated: AOSClientServiceNotificationWrapped<ProcessPlayerHistoryUpdatedNotif>;
    onHubConnectionUpdated: AOSClientServiceNotificationWrapped<ProcessPlayerHubConnectionUpdatedNotif>;
    onNotificationReceived: AOSClientServiceNotificationWrapped<ProcessPlayerNotificationReceivedNotif>;
    onPlayNextEnabled: AOSClientServiceNotificationWrapped<ProcessPlayerPlayNextEnabledNotif>;
    onProcessStateUpdated: AOSClientServiceNotificationWrapped<ProcessPlayerProcessStateUpdatedNotif>;
    onRunnerDashboardInfoUpdated: AOSClientServiceNotificationWrapped<ProcessPlayerRunnerDashboardInfoUpdatedNotif>;
    onStateInitialized: AOSClientServiceNotificationWrapped<ProcessPlayerStateInitializedNotif>;
    onUiActionPropsUpdated: AOSClientServiceNotificationWrapped<ProcessPlayerUiActionPropsUpdatedNotif>;
}

type AOSClientServiceWrappedProcessPlayerDesktop = IAOSClientServiceWrappedProcessPlayerDesktop;
type AOSClientServiceWrappedProcessPlayerUi = IAOSClientServiceWrappedProcessPlayerUi;

interface IAOSClientServiceWrappedProcessPlayer {
    desktop: AOSClientServiceWrappedProcessPlayerDesktop;
    ui: AOSClientServiceWrappedProcessPlayerUi;
}

type AOSClientServiceWrappedProcessPlayer = IAOSClientServiceWrappedProcessPlayer;

// ADD_API_CALL Process player
export function generateProcessPlayerServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedProcessPlayer {
    return {
        ui: {
            failStep: AOSClientServiceWrapperUI.request(processPlayerFailStep(router)),
            getDashboardInfo: AOSClientServiceWrapperUI.request(processPlayerGetDashboardInfo(router)),
            getHistory: AOSClientServiceWrapperUI.request(processPlayerGetHistory(router)),
            insertRework: AOSClientServiceWrapperUI.request(processPlayerInsertRework(router)),
            onActionStateUpdated: AOSClientServiceWrapperUI.notification(processPlayerActionStateUpdated(router, notifier)),
            onHistoryUpdated: AOSClientServiceWrapperUI.notification(processPlayerHistoryUpdated(router, notifier)),
            onHubConnectionUpdated: AOSClientServiceWrapperUI.notification(processPlayerHubConnectionUpdated(router, notifier)),
            onNotificationReceived: AOSClientServiceWrapperUI.notification(processPlayerOnNotificationReceived(router, notifier)),
            onPlayNextEnabled: AOSClientServiceWrapperUI.notification(processPlayerPlayNextEnabled(router, notifier)),
            onProcessStateUpdated: AOSClientServiceWrapperUI.notification(processPlayerProcessStateUpdated(router, notifier)),
            onRunnerDashboardInfoUpdated: AOSClientServiceWrapperUI.notification(processPlayerRunnerDashboardInfoUpdated(router, notifier)),
            onStateInitialized: AOSClientServiceWrapperUI.notification(processPlayerStateInitalized(router, notifier)),
            onUiActionPropsUpdated: AOSClientServiceWrapperUI.notification(processPlayerUiActionPropsUpdated(router, notifier)),
            pause: AOSClientServiceWrapperUI.request(processPlayerPause(router)),
            play: AOSClientServiceWrapperUI.request(processPlayerPlayUI(router)),
            resume: AOSClientServiceWrapperUI.request(processPlayerResume(router)),
            retry: AOSClientServiceWrapperUI.request(processPlayerRetry(router)),
            stop: AOSClientServiceWrapperUI.request(processPlayerStop(router)),
            updateProcessState: AOSClientServiceWrapperUI.request(processPlayerUpdateProcessState(router)),
        },
        desktop: {
            failStep: AOSClientServiceWrapperUI.request(processPlayerFailStep(router)),
            getDashboardInfo: AOSClientServiceWrapperUI.request(processPlayerGetDashboardInfo(router)),
            getHistory: AOSClientServiceWrapperUI.request(processPlayerGetHistory(router)),
            insertRework: AOSClientServiceWrapperUI.request(processPlayerInsertRework(router)),
            onActionStateUpdated: AOSClientServiceWrapperUI.notification(processPlayerActionStateUpdated(router, notifier)),
            onHistoryUpdated: AOSClientServiceWrapperUI.notification(processPlayerHistoryUpdated(router, notifier)),
            onHubConnectionUpdated: AOSClientServiceWrapperUI.notification(processPlayerHubConnectionUpdated(router, notifier)),
            onNotificationReceived: AOSClientServiceWrapperUI.notification(processPlayerOnNotificationReceived(router, notifier)),
            onPlayNextEnabled: AOSClientServiceWrapperUI.notification(processPlayerPlayNextEnabled(router, notifier)),
            onProcessStateUpdated: AOSClientServiceWrapperUI.notification(processPlayerProcessStateUpdated(router, notifier)),
            onRunnerDashboardInfoUpdated: AOSClientServiceWrapperUI.notification(processPlayerRunnerDashboardInfoUpdated(router, notifier)),
            onStateInitialized: AOSClientServiceWrapperUI.notification(processPlayerStateInitalized(router, notifier)),
            onUiActionPropsUpdated: AOSClientServiceWrapperUI.notification(processPlayerUiActionPropsUpdated(router, notifier)),
            pause: AOSClientServiceWrapperUI.request(processPlayerPause(router)),
            play: AOSClientServiceWrapperUI.request(processPlayerPlayDesktop(router)),
            resume: AOSClientServiceWrapperUI.request(processPlayerResume(router)),
            retry: AOSClientServiceWrapperUI.request(processPlayerRetry(router)),
            stop: AOSClientServiceWrapperUI.request(processPlayerStop(router)),
            updateProcessState: AOSClientServiceWrapperUI.request(processPlayerUpdateProcessState(router)),
        },
    };
}

generateProcessPlayerServiceClient.serviceId = 62;
