import { KortexTextField, theme } from "@aos/react-components";
import {
    ApprovalStatusEnum,
    EnumUserStatus,
    GroupEditorRightsEnum,
    IProcessApprovalGroupDbModel,
    IProcessUiModel,
    ITreeNodeDbModel,
    IUserDbModel,
    IUserGroupDbModel,
    UserGroupStatusEnum,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { Grid, MenuItem, Tooltip, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import KortexLabel from "../../../../components/core/KortexLabel";
import { useTranslate } from "../../../../hooks/useTranslate";
import { useEntitiesUsers } from "../../../../redux/effects";
import { processGet } from "../../../../redux/process-manager/process-thunks-process";
import { IUserRightsProps, userCanWrite } from "../../../../utilitites/IUserRights";

const useStyles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
    },
    textField: {
        width: "200px",
        marginTop: "10px",
    },
    dropDown: {
        width: "200px",
        marginTop: "10px",
    },
    itemGroup: {
        marginTop: "10px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        borderBottomWidth: "1px",
        borderBottomColor: theme.palette.grey[300],
        borderBottomStyle: "solid",
    },
    items: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        overflowY: "auto",
    },
    item: {
        display: "flex",
        flexDirection: "column",
        width: "250px",
        marginRight: "16px",
        marginBottom: "16px",
    },
    groupTitle: {
        paddingBottom: "10px",
    },
    userItem: {
        padding: "15px",
        width: "25%",
    },
    pathItem: {
        padding: "15px",
        width: "50%",
    },
    tooltip: {
        maxWidth: "300px",
        fontSize: "16px",
    },
});

const INPUT_DELAY_MS = 500;

interface IOwnProps extends IUserRightsProps<GroupEditorRightsEnum> {
    processApprovalGroups: IProcessApprovalGroupDbModel[];
    groupToEdit: IUserGroupDbModel;
    onEditedGroupChanged: (modedGroup: IUserGroupDbModel) => void;
    processes: IProcessUiModel[];
    treeNodes: ITreeNodeDbModel[];
}

export default function GroupEditorForm(props: IOwnProps): JSX.Element {
    const { userAccessLevel, groupToEdit, processApprovalGroups, onEditedGroupChanged } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const users = useEntitiesUsers();
    const dispatch = useThunkDispatch();

    const [inGroupUsers, setInGroupUsers] = useState<IUserDbModel[]>([]);
    const [canArchiveGroup, setCanArchiveGroup] = useState<boolean>(true);

    const userCanEdit = userCanWrite(userAccessLevel);

    // set users in group
    useEffect((): void => {
        // Update in group user list
        const foundUsers = users.reduce((foundUsers: IUserDbModel[], user: IUserDbModel) => {
            if (user.groups.find((group) => group === groupToEdit.userGroupId)) {
                foundUsers.push(user);
            }
            return foundUsers;
        }, []);
        setInGroupUsers(foundUsers);
    }, [users, groupToEdit]);

    // Get list of process IDs attached to this userGroup via processApprovalGroups
    useEffect(() => {
        const attachedProcessesIdsArray: number[] = [];
        processApprovalGroups.forEach((group) => {
            if (group.userGroupId === groupToEdit.userGroupId) {
                attachedProcessesIdsArray.push(group.processId);
            }
        });
        // setAttachedProcessesIds(attachedProcessesIdsArray); // FIXME: Temporarily disabled due to AOS-1818. Can be restored with AOS-1822.
    }, [groupToEdit, processApprovalGroups]);

    // Check if linked process approval groups contain pending approvals and set canArchiveGroup accordingly
    useEffect(() => {
        const containsPendingProcessApproval: boolean = processApprovalGroups.some(async (processApproval) => {
            const process = await dispatch(processGet(processApproval.processId));
            if (process) {
                return process.versionStatus === ApprovalStatusEnum.AWAITING;
            }
            return false;
        });

        setCanArchiveGroup(!containsPendingProcessApproval);
    }, [processApprovalGroups]);

    /**
     * Handles group property editing
     *
     * @param {keyof IUserGroupDbModel} groupProps - which property we are editing
     */

    const handleGroupChange =
        (groupProps: keyof IUserGroupDbModel): ((value: string | boolean | number | UserGroupStatusEnum) => void) =>
        (value: string | boolean | number | UserGroupStatusEnum): void => {
            // return if value is same as original one
            if (groupToEdit[groupProps] === value) {
                return;
            }

            // create updatedGroup to pass to action handler
            const updatedGroup = { ...groupToEdit, [groupProps]: value };

            // set approver quantity and approvalMandatory depending if group is set to approver or not
            if (groupProps === "isApprover") {
                if (value === "true") {
                    updatedGroup.approverQty = 1;
                } else {
                    updatedGroup.approverQty = 0;
                    updatedGroup.approvalMandatory = false;
                }
            }

            // set approver quantity to 0 and approvalMandatory + isApprover to false when archiving group
            if (groupProps === "status") {
                if (value === UserGroupStatusEnum.ARCHIVED) {
                    updatedGroup.isApprover = false;
                    updatedGroup.approverQty = 0;
                    updatedGroup.approvalMandatory = false;
                }
            }

            onEditedGroupChanged(updatedGroup);
        };

    return (
        <div className={classes.form} id="groupEditorFormId">
            <div className={classes.itemGroup}>
                <Typography className={classes.groupTitle} variant="h5">
                    {translate("settings.groups.groupDetails")}
                </Typography>
                <div className={classes.items}>
                    <div className={classes.item}>
                        <KortexLabel label={translate("settings.groups.name")} variant="compact" />
                        <KortexTextField
                            className={classes.textField}
                            variant="standard"
                            value={groupToEdit.name}
                            onChanged={handleGroupChange("name")}
                            changedDelayMS={INPUT_DELAY_MS}
                            TextFieldProps={{
                                id: "groupNameFormId",
                                disabled: !userCanEdit,
                            }}
                        />
                    </div>
                    <div className={classes.item}>
                        <KortexLabel label={translate("settings.groups.isApprover")} variant="compact" />
                        <KortexTextField
                            className={classes.dropDown}
                            variant="standard"
                            value={groupToEdit.isApprover.toString()}
                            onChanged={handleGroupChange("isApprover")}
                            changedDelayMS={INPUT_DELAY_MS}
                            TextFieldProps={{
                                id: "groupIsApproverFormId",
                                disabled: !userCanEdit,
                                select: true,
                            }}
                            InputProps={{
                                id: "groupIsApproverFormInputId",
                            }}
                        >
                            <MenuItem value="false">{translate("settings.groups.no")}</MenuItem>
                            <MenuItem value="true">{translate("settings.groups.yes")}</MenuItem>
                        </KortexTextField>
                    </div>
                    <div className={classes.item}>
                        <KortexLabel label={translate("settings.groups.isTrainer")} variant="compact" />
                        <KortexTextField
                            className={classes.dropDown}
                            variant="standard"
                            value={groupToEdit.isTrainer.toString()}
                            onChanged={handleGroupChange("isTrainer")}
                            changedDelayMS={INPUT_DELAY_MS}
                            TextFieldProps={{
                                id: "groupIsTrainerFormId",
                                disabled: !userCanEdit,
                                select: true,
                            }}
                            InputProps={{
                                id: "groupIsTrainerFormInputId",
                            }}
                        >
                            <MenuItem value="false">{translate("settings.groups.no")}</MenuItem>
                            <MenuItem value="true">{translate("settings.groups.yes")}</MenuItem>
                        </KortexTextField>
                    </div>
                    <div className={classes.item}>
                        <KortexLabel label={translate("settings.groups.trainedAfterReading")} variant="compact" />
                        <KortexTextField
                            className={classes.dropDown}
                            variant="standard"
                            value={groupToEdit.trainedAfterReading.toString()}
                            onChanged={handleGroupChange("trainedAfterReading")}
                            changedDelayMS={INPUT_DELAY_MS}
                            TextFieldProps={{
                                id: "groupTrainedAfterReadingFormId",
                                disabled: !userCanEdit,
                                select: true,
                            }}
                            InputProps={{
                                id: "groupTrainedAfterReadingFormInputId",
                            }}
                        >
                            <MenuItem value="false">{translate("settings.groups.no")}</MenuItem>
                            <MenuItem value="true">{translate("settings.groups.yes")}</MenuItem>
                        </KortexTextField>
                    </div>
                    {groupToEdit.isApprover && (
                        <>
                            <div className={classes.item}>
                                <KortexLabel label={translate("settings.groups.approvalMandatory")} variant="compact" />
                                <KortexTextField
                                    className={classes.dropDown}
                                    variant="standard"
                                    value={groupToEdit.approvalMandatory.toString()}
                                    onChanged={handleGroupChange("approvalMandatory")}
                                    changedDelayMS={INPUT_DELAY_MS}
                                    TextFieldProps={{
                                        id: "groupIsMandatoryFormId",
                                        disabled: !userCanEdit,
                                        select: true,
                                    }}
                                    InputProps={{
                                        id: "groupIsMandatoryFormInputId",
                                    }}
                                >
                                    <MenuItem value="false">{translate("settings.groups.no")}</MenuItem>
                                    <MenuItem value="true">{translate("settings.groups.yes")}</MenuItem>
                                </KortexTextField>
                            </div>
                            <div className={classes.item}>
                                <KortexLabel label={translate("settings.groups.approverQty")} variant="compact" />
                                <KortexTextField
                                    className={classes.dropDown}
                                    variant="standard"
                                    value={groupToEdit.approverQty || 0}
                                    onChanged={handleGroupChange("approverQty")}
                                    changedDelayMS={INPUT_DELAY_MS}
                                    TextFieldProps={{
                                        id: "groupApproverQtyFormId",
                                        disabled: !userCanEdit || !groupToEdit.isApprover,
                                        select: true,
                                    }}
                                    InputProps={{
                                        id: "groupApproverQtyFormInputId",
                                    }}
                                >
                                    <MenuItem style={{ display: groupToEdit.isApprover ? "none" : undefined }} value="0">
                                        0
                                    </MenuItem>
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="3">3</MenuItem>
                                    <MenuItem value="4">4</MenuItem>
                                    <MenuItem value="5">5</MenuItem>
                                    <MenuItem value="6">6</MenuItem>
                                    <MenuItem value="7">7</MenuItem>
                                    <MenuItem value="8">8</MenuItem>
                                    <MenuItem value="9">9</MenuItem>
                                    <MenuItem value="10">10</MenuItem>
                                </KortexTextField>
                            </div>
                        </>
                    )}
                    <div className={classes.item}>
                        <Tooltip
                            disableHoverListener={canArchiveGroup}
                            title={translate("settings.groups.cannotArchive")}
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <div>
                                <KortexLabel label={translate("settings.groups.status")} variant="compact" />

                                <KortexTextField
                                    className={classes.dropDown}
                                    variant="standard"
                                    value={groupToEdit.status}
                                    onChanged={handleGroupChange("status")}
                                    changedDelayMS={INPUT_DELAY_MS}
                                    TextFieldProps={{
                                        id: "groupStatusFormId",
                                        disabled: !userCanEdit || !canArchiveGroup,
                                        select: true,
                                    }}
                                    InputProps={{
                                        id: "groupStatusFormInputId",
                                    }}
                                >
                                    <MenuItem value={UserGroupStatusEnum.ACTIVE}>{translate("settings.groups.active")}</MenuItem>
                                    <MenuItem value={UserGroupStatusEnum.ARCHIVED}>{translate("settings.groups.archived")}</MenuItem>
                                </KortexTextField>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div className={classes.itemGroup}>
                <Typography className={classes.groupTitle} variant="h5">
                    {translate("settings.groups.assignedTo")}
                </Typography>
                <KortexLabel label={translate("settings.groups.users")} variant="compact" />
                <Grid container={true}>
                    {inGroupUsers
                        .filter((user) => user.status === EnumUserStatus.ACTIVE) // Keep only active users
                        .sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)) // Sort alphabetically
                        .map(
                            (userItem): JSX.Element => (
                                <Grid item className={classes.userItem} key={userItem.userId}>
                                    {`${userItem.firstName} ${userItem.lastName}`}
                                </Grid>
                            )
                        )}
                </Grid>
            </div>
            {/* FIXME: Temporarily disabled due to AOS-1818. Can be restored with AOS-1822.
            <div className={classes.itemGroup}>
                <Typography className={classes.groupTitle} variant="h5">
                    {translate("settings.groups.attachedTo")}
                </Typography>
                <KortexLabel label={translate("settings.groups.processes")} variant="compact" />
                <Grid container={true}>
                    {attachedProcessesIds.map(
                        (processId): JSX.Element => (
                            <Grid item={true} className={classes.pathItem} key={processId}>
                                <ProcessPathDisplay processId={processId} treeNodes={treeNodes} />
                            </Grid>
                        )
                    )}
                </Grid>
            </div>
            */}
        </div>
    );
}
