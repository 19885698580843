import EventEmitter from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    BomFollowUpEditItemReq,
    BomFollowUpEditItemRes,
    BomFollowUpInsertReq,
    BomFollowUpInsertRes,
    BomFollowUpInsertedNotif,
    BomFollowUpMultipleTraceabilitiesItemReq,
    BomFollowUpMultipleTraceabilitiesItemRes,
    BomFollowUpOverconsumeItemReq,
    BomFollowUpOverconsumeItemRes,
    BomFollowUpReplaceItemReq,
    BomFollowUpReplaceItemRes,
    BomFollowUpUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { bomFollowUpEditItem } from "./editItem";
import { bomFollowUpInsert } from "./insert";
import { bomFollowUpInserted } from "./inserted";
import { bomFollowUpMultipleTraceabilitiesItem } from "./multipleTraceabilitiesItem";
import { bomFollowUpOverconsumeItem } from "./overconsumeItem";
import { bomFollowUpReplaceItem } from "./replaceItem";
import { bomFollowUpUpdated } from "./updated";

interface IAOSClientServiceWrappedBomFollowUp {
    editItem: AOSClientServiceRequestWrapped<BomFollowUpEditItemReq, BomFollowUpEditItemRes>;
    insert: AOSClientServiceRequestWrapped<BomFollowUpInsertReq, BomFollowUpInsertRes>;
    multilpleTraceabilitiesItem: AOSClientServiceRequestWrapped<
        BomFollowUpMultipleTraceabilitiesItemReq,
        BomFollowUpMultipleTraceabilitiesItemRes
    >;
    onInserted: AOSClientServiceNotificationWrapped<BomFollowUpInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<BomFollowUpUpdatedNotif>;
    overconsumeItem: AOSClientServiceRequestWrapped<BomFollowUpOverconsumeItemReq, BomFollowUpOverconsumeItemRes>;
    replaceItem: AOSClientServiceRequestWrapped<BomFollowUpReplaceItemReq, BomFollowUpReplaceItemRes>;
}

type AOSClientServiceWrappedBomFollowUp = IAOSClientServiceWrappedBomFollowUp;

// ADD_API_CALL BOM Follow-Up
export function generateBomFollowUpServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedBomFollowUp {
    return {
        editItem: AOSClientServiceWrapperUI.request(bomFollowUpEditItem(router)),
        insert: AOSClientServiceWrapperUI.request(bomFollowUpInsert(router)),
        multilpleTraceabilitiesItem: AOSClientServiceWrapperUI.request(bomFollowUpMultipleTraceabilitiesItem(router)),
        onInserted: AOSClientServiceWrapperUI.notification(bomFollowUpInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(bomFollowUpUpdated(router, notifier)),
        overconsumeItem: AOSClientServiceWrapperUI.request(bomFollowUpOverconsumeItem(router)),
        replaceItem: AOSClientServiceWrapperUI.request(bomFollowUpReplaceItem(router)),
    };
}

generateBomFollowUpServiceClient.serviceId = 37;
