import { util } from "@kortex/aos-api-client";
import { TransportClientOnCloseCallback } from "@kortex/aos-api-client/definitions/client/ITransportClient";
import { ITokenDecoded, KanbanRightsEnum, LocationKeys, jwtDecodeUnsecured, mangleURL } from "@kortex/aos-common";
import { Snackbar } from "@kortex/aos-ui/components/layout/snackbarConfigurator";

import appConfigs from "../../configs/app";
import { defaultFrontPage } from "../../configs/menu";
import { getDesktopUrl, isDesktopMode } from "../../utilitites/desktopUtils";
import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import * as CloudStorage from "../../utilitites/storage";
import { EnumLocalStorageItem } from "../EnumLocalStorageItem";
import { bomFollowUpSymptomInsertedAction, bomFollowUpSymptomUpdatedAction } from "../bom-follow-up/bom-follow-up-actions";
import {
    bomItemEditedAction,
    bomItemInsertedAction,
    bomItemMultipleTraceabilitiesAction,
    bomItemOverconsumedAction,
    bomItemReplacedAction,
} from "../bom-manager/bom-actions";
import { electronicSignatureInsertedAction } from "../electronic-signature-manager/electronic-signature-actions";
import {
    failureSymptomsInsertedAction,
    failureSymptomsUpdatedAction,
    failureTypesInsertedAction,
    failureTypesUpdatedAction,
} from "../failures-manager/failures-actions";
import { filesInsertedAction, filesUpdatedAction } from "../file-manager/file-actions";
import {
    reconnectingFailed,
    setConnectionListChangedAction,
    setReconnecting,
    setRouteAction,
    setUplinkAuthenticated,
    setUplinkClosed,
    setUplinkClosing,
    setUplinkConnected,
    setUplinkConnecting,
    setUplinkReady,
} from "../general-manager/general-actions";
import { connectionList, setLanguage, setUpdateReady } from "../general-manager/general-thunks-general";
import { EnumPageTypes, GeneralActionType } from "../general-manager/general-types";
import { handleAPIError } from "../handleAPIError";
import { handleApiErrorNotification, handleMessageNotification } from "../handleNotification";
import {
    playerEnablePlayNext,
    playerInitState,
    playerUpdateActionState,
    playerUpdateHistory,
    playerUpdateHubConnection,
    playerUpdateProcessState,
    playerUpdateUiActionProps,
} from "../player-manager/player-thunks-player";
import {
    clearProcessesAction,
    processActionDeletedAction,
    processActionInsertedAction,
    processActionSettingsUpdatedAction,
    processActionUpdatedAction,
    processInsertedAction,
    processUpdatedAction,
} from "../process-manager/process-actions";
import {
    processTrainingUpdateProcessAction,
    processTrainingUpsertedTrainingAction,
} from "../process-training-manager/process-training-actions";
import { resultSettingItemUpsertedAction, resultSettingMetadataUpsertedAction } from "../result-setting-manager/result-setting-actions";
import { resultSettingItemsGetAll } from "../result-setting-manager/result-setting-item-thunks";
import { resultSettingMetadataGetAll } from "../result-setting-manager/result-setting-metadata-thunks";
import {
    reworkDeletedAction,
    reworkInsertedAction,
    reworkItemStatusDeletedAction,
    reworkItemStatusInsertedAction,
    reworkItemStatusUpdatedAction,
    reworkLogInsertedAction,
    reworkLogUpdatedAction,
    reworkUpdatedAction,
    rootCauseInsertedAction,
    rootCauseUpdatedAction,
} from "../rework-manager/rework-actions";
import { archiveCompletedJobsAction, jobInsertedAction, jobUpdatedAction } from "../scheduler-manager/scheduler-actions";
import { setSettingOrganizationListAction } from "../setting-organization-manager/setting-organization-actions";
import { settingOrganizationGetAll } from "../setting-organization-manager/setting-organization-thunks-settings";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { taskDeletedAction, taskInsertedAction, taskUpdatedAction } from "../task-manager/task-actions";
import { taskGetAll } from "../task-manager/task-thunks-task";
import {
    trainingCertificationPendingListDeletedAction,
    trainingCertificationPendingOfUserInsertedAction,
} from "../training-certification-pending-manager/training-certification-pending-actions";
import {
    trainingPendingListDeletedAction,
    trainingPendingOfUserInsertedAction,
} from "../training-pending-manager/training-pending-actions";
import {
    clearTreesAction,
    treeFileInsertedAction,
    treeFileUpdatedAction,
    treeProcessInsertedAction,
    treeProcessUpdatedAction,
    treeWorkZoneInsertedAction,
    treeWorkZoneUpdatedAction,
} from "../tree-manager/tree-actions";
import { userTrainingInsertedAction, userTrainingUpdatedAction } from "../user-training-manager/user-training-actions";
import { debugThunk, fetchedOnce } from "../utils";
import {
    jobProcessesDeletedAction,
    jobProcessesInsertedAction,
    jobProcessesUpdatedAction,
} from "../work-scheduler-manager/work-schedule-actions";

import {
    clearUserSessionAction,
    setUserSessionAction,
    userGroupInsertedAction,
    userGroupUpdatedAction,
    userInsertedAction,
    userRoleDeletedAction,
    userRoleInsertedAction,
    userRoleUpdatedAction,
    userUpdatedAction,
} from "./users-actions";
import { userGet } from "./users-thunks-user";

// Keep initial login credentials
let _userName = "";
let _password = "";
let _domain = "";

/**
 * Establish connection to services
 * 1 - cloud
 * 2 - desktop (if available)
 *
 * @param {{ cloud: { host: string } }} options - connect options
 */
export function connect(options: { cloud: { host: string } }): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        await dispatch(connectCloud(options.cloud.host));
        await dispatch(connectDesktop());
    };
}

/**
 * Establish connection to cloud services
 *
 * @param {string} hubHost - hub host to connect to
 */
export function connectCloud(hubHost: string): StandardThunk<void> {
    // debug trail
    debugThunk(connectCloud.name, "begin");

    return async (dispatch: StandardDispatch, _getState: () => AppState, { apiUI: api }): Promise<void> => {
        const mangledHubHost = mangleURL(hubHost);

        dispatch(setUplinkConnecting());
        await api
            .connect({
                host: mangledHubHost.host,
                pathname: `${mangledHubHost.path}${mangledHubHost.queryString ? `?${mangledHubHost.queryString}` : ""}`,
                secured: mangledHubHost.secured,
                port: mangledHubHost.port ?? (mangledHubHost.secured ? 443 : 80),
            })
            .then(
                () => {
                    dispatch(setUplinkConnected());
                },
                (reason) => {
                    dispatch(setUplinkClosed());
                    throw reason;
                }
            )
            .then(() => {
                // debug trail
                debugThunk(connectCloud.name, "hub api root: %s", (api.router.transport.protocol as WebSocket).url);
            })
            .then(() => {
                // debug trail
                debugThunk(connectCloud.name, "end");
            })
            .catch((reason) => {
                throw reason;
            });
    };
}

/**
 * Establish connection to desktop services
 */
export function connectDesktop(): StandardThunk<void> {
    // debug trail
    debugThunk(connectDesktop.name, "begin");

    return async (_dispatch: StandardDispatch, _getState: () => AppState, { apiDesktop }): Promise<void> => {
        if (!isDesktopMode) {
            // debug trail
            debugThunk(connectDesktop.name, "end", "not desktop");

            return;
        }

        const desktopUrlInfo = getDesktopUrl();

        return apiDesktop
            .connect({
                host: desktopUrlInfo.address,
                port: desktopUrlInfo.port,
            })
            .then(() => {
                // debug trail
                debugThunk(connectDesktop.name, "end");
            });
    };
}

/**
 * Disconnect from services
 * 1 - cloud
 * 2 - desktop (if available)
 */
export function disconnect(): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        // close connection with cloud
        await dispatch(disconnectCloud());

        // close connection with electron
        await dispatch(disconnectDesktop());
    };
}

/**
 * Disconnect from cloud services
 */
export function disconnectCloud(): StandardThunk<void> {
    // debug trail
    debugThunk(disconnectCloud.name, "begin");

    return async (dispatch: StandardDispatch, _getState: () => AppState, { apiUI: api }): Promise<void> => {
        dispatch(setUplinkClosing());

        if (api.router.transport.isOpen()) {
            try {
                await api.router.transport.disconnect();
            } catch {
                try {
                    // dirty disconnect
                    await api.router.transport.disconnect({ isClean: false, quick: true });
                } catch {
                    // noop; we did what we could
                }
            }
        } else {
            // debug trail
            debugThunk(disconnectCloud.name, "already closed");
        }

        dispatch(setUplinkClosed());

        // debug trail
        debugThunk(disconnectCloud.name, "end");
    };
}

/**
 * Disconnect from desktop services
 */
export function disconnectDesktop(): StandardThunk<void> {
    // debug trail
    debugThunk(disconnectDesktop.name, "begin");

    return async (_dispatch: StandardDispatch, _getState: () => AppState, { apiDesktop }): Promise<void> => {
        if (!isDesktopMode) {
            // debug trail
            debugThunk(disconnectDesktop.name, "end", "not desktop");

            return;
        }

        if (apiDesktop.router.transport.isOpen()) {
            try {
                await apiDesktop.router.transport.disconnect();
            } catch {
                try {
                    // dirty disconnect
                    await apiDesktop.router.transport.disconnect({ isClean: false, quick: true });
                } catch {
                    // noop; we did what we could
                }
            }
        } else {
            // debug trail
            debugThunk(disconnectDesktop.name, "already closed");
        }

        // debug trail
        debugThunk(disconnectDesktop.name, "end");
    };
}

/**
 * Set Redux store back to default "connection less" state
 */
export function clearStore(): StandardThunk<void> {
    // debug trail
    debugThunk(clearStore.name, "begin");

    return async (dispatch: StandardDispatch): Promise<void> => {
        // clear
        dispatch(setSettingOrganizationListAction([]));
        dispatch({ type: GeneralActionType.SET_ROUTE, value: EnumPageTypes.LOGIN });
        dispatch(clearUserSessionAction());
        dispatch(clearProcessesAction());
        dispatch(clearTreesAction());

        // debug trail
        debugThunk(clearStore.name, "end");
    };
}

/**
 * Promise Function to delay for a certain amount of time
 */
function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Promise Function to retry to login with the same credentials as first
 */
export async function relogin(dispatch: StandardDispatch, clean: boolean, quick: boolean): Promise<void> {
    const timeoutExpires = [1, 2, 4, 8, 15, 30];

    // Toggle reconnecting state in store
    dispatch(setReconnecting());

    // 1 minute total max
    for (const timeout of timeoutExpires) {
        await delay(timeout * 1000);

        const outOfLoop = await dispatch(login(_userName, _password, _domain, true))
            .then(() => {
                return true; // Do not finish the for
            })
            .catch(() => {
                if (timeout === timeoutExpires[timeoutExpires.length - 1]) {
                    dispatch(reconnectingFailed());

                    // Last timeout, out
                    if (!clean) {
                        // debug trail
                        debugThunk(login.name, "cloud disconnected %o", {
                            clean,
                            quick,
                        });

                        dispatch(logout());
                    }
                    return true; // Out of the loop
                }
                return false;
            });

        if (outOfLoop) {
            break;
        }
    }
    return void 0;
}
/**
 * Login to AOS service
 *
 * @param {string} userName - username to use for the session
 * @param {string} password - password to use for the session
 * @param {string} domain - domain to use to establish connection to cloud services
 */
export function login(userName: string, password: string, domain: string, reconnecting?: boolean): StandardThunk<void> {
    // debug trail
    debugThunk(login.name, "begin");
    _userName = userName;
    _password = password;
    _domain = domain;

    let isAllRegistered = false;

    return async (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api, apiDesktop }): Promise<void> => {
        try {
            try {
                await dispatch(
                    connect({
                        cloud: {
                            host: domain,
                        },
                    })
                );
            } catch (reason) {
                // TODO: what do we really want to do here; continue with player disabled ?
                await dispatch(disconnect());

                throw reason;
            }

            // this is an advanced feature of the TypeScript API (not supported by hinting, not CPP and PYTHON implementation)
            // but since the transport extends an EventEmitter we can leverage "once" listeners instead of the "on" listeners
            api.router.transport.once(
                "close",
                (...params: Parameters<TransportClientOnCloseCallback>): ReturnType<TransportClientOnCloseCallback> => {
                    const [clean, quick] = params;

                    dispatch(setUplinkClosed());

                    if (!clean) {
                        // Means that is is not manual or requested
                        relogin(dispatch, clean, quick);
                    }
                }
            );

            // FIXME: should be revisited
            if (isDesktopMode) {
                // this is an advanced feature of the TypeScript API (not supported by hinting, not CPP and PYTHON implementation)
                // but since the transport extends an EventEmitter we can leverage "once" listeners instead of the "on" listeners
                apiDesktop.router.transport.once(
                    "close",
                    (...params: Parameters<TransportClientOnCloseCallback>): ReturnType<TransportClientOnCloseCallback> => {
                        const [clean, quick] = params;

                        dispatch(setUplinkClosed());

                        if (!clean) {
                            // Means that is is not manual or requested
                            relogin(dispatch, clean, quick);
                        }
                    }
                );
            }
        } catch (reason) {
            if (!reconnecting) {
                Snackbar.error("Can't connect to host");
                console.error("Can't connect to host");
                return void 0;
            }

            throw new Error("Can't connect to host");
        }

        try {
            const { loginRes, session } = await api.services.users
                .login({
                    userName,
                    password,
                })()
                .then((loginRes) => {
                    dispatch(setUplinkAuthenticated(true));

                    return loginRes;
                })
                .then(async (loginRes) => {
                    // FIXME: bootstrap me elsewhere (different thunk)

                    // magically creates the storage hostname from hubhost
                    // FIXME: utterly error prone
                    CloudStorage.set({
                        host: CloudStorage.helpers.toHostFromHubHost(domain),
                    });

                    const session: ITokenDecoded = jwtDecodeUnsecured(loginRes.token);

                    // If password is expired, bypass all the setup
                    if (session.passwordExpired) {
                        return { loginRes, session };
                    }

                    // If scanner, no need to have all the notifications
                    if (session.roleRights.kanban === KanbanRightsEnum.INSERT) {
                        isAllRegistered = true;
                        return { loginRes, session };
                    }

                    // ADD_API_CALL - Add Notification callbacks
                    // Login
                    await api.services.general.onConnectionListChanged(emptyObject, async function (payload): Promise<void> {
                        dispatch(setConnectionListChangedAction(payload));
                    })();

                    try {
                        // Bom follow-up symptom
                        await api.services.bomFollowUpSymptom.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(bomFollowUpSymptomInsertedAction(payload));
                        })();
                        await api.services.bomFollowUpSymptom.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(bomFollowUpSymptomUpdatedAction(payload));
                        })();
                        // BOMs
                        await api.services.bomFollowUp.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(bomItemInsertedAction(payload));
                        })();

                        await api.services.bomFollowUp.onUpdated(emptyObject, async function (payload): Promise<void> {
                            for (const followUp of payload.followUps) {
                                switch (payload.type) {
                                    case "edit":
                                        dispatch(bomItemEditedAction(followUp));
                                        break;
                                    case "multiTraceability":
                                        dispatch(bomItemMultipleTraceabilitiesAction([followUp]));
                                        break;
                                    case "overconsumption":
                                        dispatch(bomItemOverconsumedAction(followUp));
                                        break;
                                    case "replacement":
                                        dispatch(bomItemReplacedAction(followUp));
                                        break;
                                    case "standard":
                                        dispatch(bomItemInsertedAction(followUp));
                                        break;
                                }
                            }
                        })();

                        // Files
                        await api.services.file.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(filesInsertedAction([...payload]));
                        })();

                        await api.services.file.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(filesUpdatedAction([...payload]));
                        })();

                        // Groups
                        await api.services.userGroups.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(userGroupInsertedAction(payload));
                        })();
                        await api.services.userGroups.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(userGroupUpdatedAction([payload]));
                        })();

                        // Process
                        await api.services.process.onActionDeleted(emptyObject, async function (payload): Promise<void> {
                            dispatch(processActionDeletedAction([...payload]));
                        })();
                        await api.services.process.onActionInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(processActionInsertedAction([...payload]));
                        })();
                        await api.services.process.onActionUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(processActionUpdatedAction([...payload]));
                        })();
                        await api.services.process.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(processUpdatedAction(payload));
                        })();
                        await api.services.process.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(processInsertedAction(payload));

                            // Update user trainings when a new process version is inserted
                            if (!payload.isDraft) {
                                dispatch(processInsertedAction(payload));
                            }
                        })();

                        // Process Action Settings
                        await api.services.processActionSettings.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(processActionSettingsUpdatedAction(payload));
                        })();

                        // Rework
                        await api.services.rework.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(reworkInsertedAction([payload]));
                        })();
                        await api.services.rework.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(reworkUpdatedAction([...payload]));
                        })();
                        await api.services.rework.onDeleted(emptyObject, async function (payload): Promise<void> {
                            dispatch(reworkDeletedAction(payload));
                        })();

                        // Result Setting
                        await api.services.uncategorized.onUpsertedResultSettingItem(emptyObject, async function (payload): Promise<void> {
                            dispatch(resultSettingItemUpsertedAction([...payload]));
                        })();
                        await api.services.uncategorized.onUpsertedResultSettingMetadata(
                            emptyObject,
                            async function (payload): Promise<void> {
                                dispatch(resultSettingMetadataUpsertedAction([...payload]));
                            }
                        )();

                        // Rework Status
                        await api.services.reworkItemStatus.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(reworkItemStatusInsertedAction([payload]));
                        })();
                        await api.services.reworkItemStatus.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(reworkItemStatusUpdatedAction([...payload]));
                        })();
                        await api.services.reworkItemStatus.onDeleted(emptyObject, async function (payload): Promise<void> {
                            dispatch(reworkItemStatusDeletedAction(payload));
                        })();

                        // Rework Log
                        await api.services.reworkLog.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(reworkLogInsertedAction([...payload]));
                        })();
                        await api.services.reworkLog.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(reworkLogUpdatedAction([...payload]));
                        })();

                        // Root cause
                        await api.services.rootCause.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(rootCauseInsertedAction(payload));
                        })();
                        await api.services.rootCause.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(rootCauseUpdatedAction(payload));
                        })();

                        // Roles
                        await api.services.userRoles.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(userRoleInsertedAction(payload));
                        })();
                        await api.services.userRoles.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(userRoleUpdatedAction([payload]));
                        })();
                        await api.services.userRoles.onDeleted(emptyObject, async function (payload): Promise<void> {
                            dispatch(userRoleDeletedAction([payload]));
                        })();

                        // Scheduler Jobs
                        await api.services.job.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(jobInsertedAction([...payload]));
                        })();
                        await api.services.job.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(jobUpdatedAction([...payload]));
                        })();
                        await api.services.job.archivedAllCompleted(emptyObject, async function (payload): Promise<void> {
                            dispatch(archiveCompletedJobsAction(payload.archivedJobs));
                        })();

                        // Work Schedule job processes
                        await api.services.workSchedule.onDeleted(emptyObject, async function (payload): Promise<void> {
                            dispatch(jobProcessesDeletedAction([...payload]));
                        })();
                        await api.services.workSchedule.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(jobProcessesInsertedAction([...payload]));
                        })();
                        await api.services.workSchedule.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(jobProcessesUpdatedAction([...payload]));
                        })();

                        // Tasks
                        await api.services.tasks.onInserted(emptyObject, async function (payload): Promise<void> {
                            // Not sure that was the best solution, to be review by Jay and Francis post Covid
                            //    const tasks = payload.map((task) => TaskFactory(task.type, task));
                            dispatch(taskInsertedAction([...payload]));
                        })();
                        await api.services.tasks.onUpdated(emptyObject, async function (payload): Promise<void> {
                            // Not sure that was the best solution, to be review by Jay and Francis post Covid
                            //const tasks = payload.map((task) => TaskFactory(task.type, task));
                            dispatch(taskUpdatedAction([...payload]));
                        })();
                        await api.services.tasks.onDeleted(emptyObject, async function (payload): Promise<void> {
                            // Not sure that was the best solution, to be review by Jay and Francis post Covid
                            //const tasks = payload.map((task) => TaskFactory(task.type, task));
                            dispatch(taskDeletedAction([...payload]));
                        })();

                        // Process training
                        await api.services.processTraining.onLatestApprovedProcessUpdated(emptyObject, (payload) => {
                            dispatch(processTrainingUpdateProcessAction(payload));
                        })();

                        // Trainings
                        await api.services.processTraining.onInserted(emptyObject, (payload) => {
                            dispatch(processTrainingUpsertedTrainingAction([...payload]));
                        })();
                        await api.services.processTraining.onUpdated(emptyObject, (payload) => {
                            dispatch(processTrainingUpsertedTrainingAction([...payload]));
                        })();

                        // User training
                        await api.services.userTraining.onInserted(emptyObject, (payload) => {
                            dispatch(userTrainingInsertedAction([...payload]));
                        })();
                        await api.services.userTraining.onUpdated(emptyObject, (payload) => {
                            dispatch(userTrainingUpdatedAction([payload]));
                        })();

                        // Training pending
                        await api.services.trainingPending.onInserted(emptyObject, (payload) => {
                            dispatch(
                                trainingPendingOfUserInsertedAction(
                                    payload.filter((trainingPending) => trainingPending.user.userId === session.userId)
                                )
                            );
                        })();

                        await api.services.trainingPending.onDeleted(emptyObject, (payload) => {
                            dispatch(trainingPendingListDeletedAction([...payload]));
                        })();

                        // Training certification pending
                        await api.services.trainingCertificationPending.onInserted(emptyObject, (payload) => {
                            dispatch(
                                trainingCertificationPendingOfUserInsertedAction(
                                    payload.filter(
                                        (trainingCertificationPending) => trainingCertificationPending.user.userId === session.userId
                                    )
                                )
                            );
                        })();
                        await api.services.trainingCertificationPending.onDeleted(emptyObject, (payload) => {
                            dispatch(trainingCertificationPendingListDeletedAction([...payload]));
                        })();

                        // Tree
                        await api.services.treeFile.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(treeFileInsertedAction([...payload]));
                        })();
                        await api.services.treeFile.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(treeFileUpdatedAction([...payload]));
                        })();
                        await api.services.treeProcess.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(treeProcessInsertedAction(payload));
                        })();
                        await api.services.treeProcess.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(treeProcessUpdatedAction([...payload]));
                        })();
                        await api.services.treeWorkZone.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(treeWorkZoneInsertedAction(payload));
                        })();
                        await api.services.treeWorkZone.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(treeWorkZoneUpdatedAction([...payload]));
                        })();

                        // Users
                        await api.services.users.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(userInsertedAction(payload));
                        })();
                        await api.services.users.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(userUpdatedAction([payload]));
                        })();

                        // Failures
                        await api.services.failureTypes.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(failureTypesInsertedAction([...payload]));
                        })();
                        await api.services.failureTypes.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(failureTypesUpdatedAction([...payload]));
                        })();
                        await api.services.failureSymptoms.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(failureSymptomsInsertedAction([...payload]));
                        })();
                        await api.services.failureSymptoms.onUpdated(emptyObject, async function (payload): Promise<void> {
                            dispatch(failureSymptomsUpdatedAction([...payload]));
                        })();

                        // Electronic Signature
                        await api.services.electronicSignature.onInserted(emptyObject, async function (payload): Promise<void> {
                            dispatch(electronicSignatureInsertedAction(payload));
                        })();

                        // Runners
                        if (isDesktopMode) {
                            await apiDesktop.services.processPlayer.onActionStateUpdated(
                                emptyObject,
                                async function (payload): Promise<void> {
                                    dispatch(playerUpdateActionState(payload));
                                }
                            )();

                            await apiDesktop.services.processPlayer.onHistoryUpdated(emptyObject, async function (payload): Promise<void> {
                                dispatch(playerUpdateHistory([...payload]));
                            })();

                            await apiDesktop.services.processPlayer.onHubConnectionUpdated(
                                emptyObject,
                                async function (payload): Promise<void> {
                                    dispatch(playerUpdateHubConnection(payload));
                                }
                            )();

                            await apiDesktop.services.processPlayer.onNotificationReceived(
                                emptyObject,
                                async function (payload): Promise<void> {
                                    if (payload.type === "apiError") {
                                        // TODO: Add payload options to API notifications: make it possible to send errors, warnings, informative messages, etc.
                                        handleApiErrorNotification(new util.errors.ApiError(payload.payload));
                                    } else {
                                        handleMessageNotification(payload.payload.message, payload.payload.level);
                                    }
                                }
                            )();

                            await apiDesktop.services.processPlayer.onPlayNextEnabled(emptyObject, async function (payload): Promise<void> {
                                dispatch(playerEnablePlayNext(payload.playNextEnabled));
                            })();

                            await apiDesktop.services.processPlayer.onProcessStateUpdated(
                                emptyObject,
                                async function (payload): Promise<void> {
                                    dispatch(playerUpdateProcessState(payload));
                                }
                            )();

                            /* Unused for now, to be enabled soon 
                            await apiDesktop.services.processPlayer.onRunnerDashboardInfoUpdated(emptyObject, async function (
                                payload
                            ): Promise<void> {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                //dispatch(playerUpdateActionState(payload));
                                console.log("Shall Update store with payload", payload);
                            })();
                            */

                            await apiDesktop.services.processPlayer.onStateInitialized(
                                emptyObject,
                                async function (payload): Promise<void> {
                                    dispatch(playerInitState(payload));
                                }
                            )();

                            await apiDesktop.services.processPlayer.onUiActionPropsUpdated(
                                emptyObject,
                                async function (payload): Promise<void> {
                                    dispatch(playerUpdateUiActionProps(payload));
                                }
                            )();
                        } else {
                            await api.services.processPlayer.onActionStateUpdated(emptyObject, async function (payload): Promise<void> {
                                dispatch(playerUpdateActionState(payload));
                            })();

                            await api.services.processPlayer.onHistoryUpdated(emptyObject, async function (payload): Promise<void> {
                                dispatch(playerUpdateHistory([...payload]));
                            })();

                            await api.services.processPlayer.onHubConnectionUpdated(emptyObject, async function (payload): Promise<void> {
                                dispatch(playerUpdateHubConnection(payload));
                            })();

                            await api.services.processPlayer.onNotificationReceived(emptyObject, async function (payload): Promise<void> {
                                if (payload.type === "apiError") {
                                    // TODO: Add payload options to API notifications: make it possible to send errors, warnings, informative messages, etc.
                                    handleApiErrorNotification(new util.errors.ApiError(payload.payload));
                                } else {
                                    handleMessageNotification(payload.payload.message, payload.payload.level);
                                }
                            })();

                            await api.services.processPlayer.onPlayNextEnabled(emptyObject, async function (payload): Promise<void> {
                                dispatch(playerEnablePlayNext(payload.playNextEnabled));
                            })();

                            await api.services.processPlayer.onProcessStateUpdated(emptyObject, async function (payload): Promise<void> {
                                dispatch(playerUpdateProcessState(payload));
                            })();

                            /* Unused for now, to be enabled soon 
                            await api.services.processPlayer.onRunnerDashboardInfoUpdated(emptyObject, async function (
                                payload
                            ): Promise<void> {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                //dispatch(playerUpdateActionState(payload));
                                console.log("Shall Update store with payload", payload);
                            })();
                            */

                            await api.services.processPlayer.onStateInitialized(emptyObject, async function (payload): Promise<void> {
                                dispatch(playerInitState(payload));
                            })();

                            await api.services.processPlayer.onUiActionPropsUpdated(emptyObject, async function (payload): Promise<void> {
                                dispatch(playerUpdateUiActionProps(payload));
                            })();
                        }

                        isAllRegistered = true;
                    } catch (error) {
                        isAllRegistered = false;
                        handleAPIError(error, dispatch);
                    }

                    // Desktop
                    if (isDesktopMode) {
                        apiDesktop.services.general.onDesktopUpdateAvailable(emptyObject, async function (): Promise<void> {
                            dispatch(setUpdateReady());
                        })();
                    }

                    if (isAllRegistered) {
                        // other calls
                        await dispatch(settingOrganizationGetAll());
                        await dispatch(connectionList());
                        await dispatch(taskGetAll());
                        await dispatch(resultSettingItemsGetAll());
                        await dispatch(resultSettingMetadataGetAll());
                    }

                    return { loginRes, session };
                })
                .catch(async (reason) => {
                    // debug trail
                    debugThunk(login.name, reason.message);

                    // Desktop
                    if (isDesktopMode) {
                        apiDesktop.services.general.onDesktopUpdateAvailable(emptyObject, async function (): Promise<void> {
                            dispatch(setUpdateReady());
                        })();
                    }

                    await dispatch(logout());

                    throw reason;
                });

            window.localStorage.setItem(EnumLocalStorageItem.USER_NAME, userName);
            window.localStorage.setItem(EnumLocalStorageItem.CLIENT_ID, loginRes.clientId);
            window.localStorage.setItem(EnumLocalStorageItem.INSTANCE, domain);

            if (isDesktopMode) {
                // FIXME: this is a real patch until we get better :'(
                apiDesktop.patch.apiToken = { ...session };

                // FIXME: ... et boboy
                await apiDesktop.services.electron.updateHost({
                    hubHost: domain,
                })({ timeout: 15_000 }); // 15 seconds timeout
            }

            if (isAllRegistered) {
                // set app language based on logged user
                const loggedUser = await dispatch(userGet({ userId: session.userId }));
                // FIXME: use active organization when multiple orgs are supported
                dispatch(
                    setLanguage(
                        (loggedUser?.language as LocationKeys) ||
                            getState().setting.settingOrganization[0]?.defaultLoc || // first fallback
                            appConfigs?.acceptedLanguages[0] // second fallback
                    )
                );
            }

            // When reconnecting, leave the user at the current page
            if (!reconnecting) {
                dispatch(setRouteAction(defaultFrontPage(session, isAllRegistered)));
            }

            dispatch(setUserSessionAction(session));

            dispatch(setUplinkReady());
        } catch (error) {
            handleAPIError(error, dispatch);

            return void 0;
        }

        // debug trail
        debugThunk(login.name, "end");
    };
}

/**
 * Logout of AOS service
 */
export function logout(): StandardThunk<void> {
    // debug trail
    debugThunk(logout.name, "begin");

    return async (dispatch: StandardDispatch): Promise<void> => {
        // close connection
        await dispatch(disconnect());

        // clear store
        await dispatch(clearStore());

        // release resource locks
        await dispatch(releaseResources());

        // debug trail
        debugThunk(logout.name, "end");
    };
}

/**
 * Release resources (anything but redux related)
 */
export function releaseResources(): StandardThunk<void> {
    // debug trail
    debugThunk(releaseResources.name, "begin");

    return async (): Promise<void> => {
        // FIXME: do this properly when kortex api will be implemented
        fetchedOnce.unsealAll();

        // debug trail
        debugThunk(releaseResources.name, "end");
    };
}
