import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceRequestWrapped,
    ErpGetBomByTrackingIdReq,
    ErpGetBomByTrackingIdRes,
    ErpGetBomPlayerReq,
    ErpGetBomPlayerRes,
    ErpGetBomReq,
    ErpGetBomRes,
    ErpGetWoBomReq,
    ErpGetWoBomRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { erpGetBom } from "./getBom";
import { erpGetBomByTrackingId } from "./getBomByTrackingId";
import { erpGetBomPlayer } from "./getBomPlayer";
import { erpGetWoBom } from "./getWoBom";

interface IAOSClientServiceWrappedErp {
    getBom: AOSClientServiceRequestWrapped<ErpGetBomReq, ErpGetBomRes>;
    getBomByTrackingId: AOSClientServiceRequestWrapped<ErpGetBomByTrackingIdReq, ErpGetBomByTrackingIdRes>;
    getBomPlayer: AOSClientServiceRequestWrapped<ErpGetBomPlayerReq, ErpGetBomPlayerRes>;
    getWoBom: AOSClientServiceRequestWrapped<ErpGetWoBomReq, ErpGetWoBomRes>;
}

type AOSClientServiceWrappedErp = IAOSClientServiceWrappedErp;

// ADD_API_CALL Electronic Signature
export function generateErpServiceClient(router: IRouterClient, _: EventEmitter): AOSClientServiceWrappedErp {
    return {
        getBom: AOSClientServiceWrapperUI.request(erpGetBom(router)),
        getBomByTrackingId: AOSClientServiceWrapperUI.request(erpGetBomByTrackingId(router)),
        getBomPlayer: AOSClientServiceWrapperUI.request(erpGetBomPlayer(router)),
        getWoBom: AOSClientServiceWrapperUI.request(erpGetWoBom(router)),
    };
}

generateErpServiceClient.serviceId = 150;
