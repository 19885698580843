var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import objectAssign from 'object-assign';
import omit from 'object.omit';
import Core from './lib/ElementRelativeCursorPosition';
import addEventListener from './utils/addEventListener';
import * as constants from './constants';
import noop from './utils/noop';

var _class = function (_React$Component) {
    _inherits(_class, _React$Component);

    function _class(props) {
        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, props));

        _initialiseProps.call(_this);

        _this.state = {
            detectedEnvironment: {
                isMouseDetected: false,
                isTouchDetected: false
            },
            elementDimensions: {
                width: 0,
                height: 0
            },
            isActive: false,
            isPositionOutside: true,
            position: {
                x: 0,
                y: 0
            }
        };

        _this.shouldGuardAgainstMouseEmulationByDevices = false;
        _this.eventListeners = [];
        _this.timers = [];
        _this.elementOffset = {
            x: 0,
            y: 0
        };

        _this.onTouchStart = _this.onTouchStart.bind(_this);
        _this.onTouchMove = _this.onTouchMove.bind(_this);
        _this.onTouchEnd = _this.onTouchEnd.bind(_this);
        _this.onTouchCancel = _this.onTouchCancel.bind(_this);
        _this.onMouseEnter = _this.onMouseEnter.bind(_this);
        _this.onMouseMove = _this.onMouseMove.bind(_this);
        _this.onMouseLeave = _this.onMouseLeave.bind(_this);
        return _this;
    }

    _createClass(_class, [{
        key: 'onTouchStart',
        value: function onTouchStart(e) {
            this.init();
            this.onTouchDetected();
            this.setShouldGuardAgainstMouseEmulationByDevices();

            var position = this.core.getCursorPosition(this.getTouchEvent(e));
            this.setPositionState(position);

            if (this.props.isActivatedOnTouch) {
                e.preventDefault();
                this.activate();
                return;
            }

            this.initPressEventCriteria(position);
            this.setPressEventTimer();
        }
    }, {
        key: 'onTouchMove',
        value: function onTouchMove(e) {
            if (!this.isCoreReady) {
                return;
            }

            var position = this.core.getCursorPosition(this.getTouchEvent(e));

            if (!this.state.isActive) {
                this.setPressEventCriteria(position);
                return;
            }

            this.setPositionState(position);
            e.preventDefault();

            if (this.props.shouldStopTouchMovePropagation) {
                e.stopPropagation();
            }
        }
    }, {
        key: 'onTouchEnd',
        value: function onTouchEnd() {
            this.deactivate();
            this.unsetShouldGuardAgainstMouseEmulationByDevices();
        }
    }, {
        key: 'onTouchCancel',
        value: function onTouchCancel() {
            this.deactivate();
            this.unsetShouldGuardAgainstMouseEmulationByDevices();
        }
    }, {
        key: 'onMouseEnter',
        value: function onMouseEnter(e) {
            if (this.shouldGuardAgainstMouseEmulationByDevices) {
                return;
            }

            this.init();
            this.onMouseDetected();
            this.setPositionState(this.core.getCursorPosition(e));
            this.clearActivationTimers();
            this.schedulActivation(this.props.hoverDelayInMs);
        }
    }, {
        key: 'onMouseMove',
        value: function onMouseMove(e) {
            if (!this.isCoreReady) {
                return;
            }

            this.setPositionState(this.core.getCursorPosition(e));
        }
    }, {
        key: 'onMouseLeave',
        value: function onMouseLeave() {
            this.clearActivationTimers();
            this.scheduleDeactivation(this.props.hoverOffDelayInMs);
            this.setState({ isPositionOutside: true });
        }
    }, {
        key: 'onTouchDetected',
        value: function onTouchDetected() {
            var environment = {
                isTouchDetected: true,
                isMouseDetected: false
            };

            this.setState({ detectedEnvironment: environment });
            this.props.onDetectedEnvironmentChanged(environment);
        }
    }, {
        key: 'onMouseDetected',
        value: function onMouseDetected() {
            var environment = {
                isTouchDetected: false,
                isMouseDetected: true
            };

            this.setState({ detectedEnvironment: environment });
            this.props.onDetectedEnvironmentChanged(environment);
        }
    }, {
        key: 'componentDidMount',
        value: function componentDidMount() {
            if (this.props.isEnabled) {
                this.enable();
            }
        }
    }, {
        key: 'componentWillReceiveProps',
        value: function componentWillReceiveProps(_ref) {
            var willBeEnabled = _ref.isEnabled;
            var isEnabled = this.props.isEnabled;

            var isEnabledWillChange = isEnabled !== willBeEnabled;

            if (!isEnabledWillChange) {
                return;
            }

            if (willBeEnabled) {
                this.enable();
            } else {
                this.disable();
            }
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            this.clearTimers();
            this.disable();
        }
    }, {
        key: 'enable',
        value: function enable() {
            this.addEventListeners();
        }
    }, {
        key: 'disable',
        value: function disable() {
            this.removeEventListeners();
        }
    }, {
        key: 'init',
        value: function init() {
            this.core = new Core(this.el);

            this.setElementDimensionsState(this.getElementDimensions(this.el));
        }
    }, {
        key: 'reset',
        value: function reset() {
            var _core = this.core;
            _core = _core === undefined ? {} : _core;
            var lastMouseEvent = _core.lastEvent;


            this.init();

            if (!lastMouseEvent) {
                return;
            }

            this.setPositionState(this.core.getCursorPosition(lastMouseEvent));
        }
    }, {
        key: 'activate',
        value: function activate() {
            this.setState({ isActive: true });
            this.props.onActivationChanged({ isActive: true });
        }
    }, {
        key: 'deactivate',
        value: function deactivate() {
            var _this2 = this;

            this.clearTimer(constants.PRESS_EVENT_TIMER_NAME);

            this.setState({ isActive: false }, function () {
                var _state = _this2.state,
                    isPositionOutside = _state.isPositionOutside,
                    position = _state.position;


                _this2.props.onPositionChanged({
                    isPositionOutside: isPositionOutside,
                    position: position
                });

                _this2.props.onActivationChanged({ isActive: false });
            });
        }
    }, {
        key: 'setPositionState',
        value: function setPositionState(position) {
            var isPositionOutside = this.getIsPositionOutside(position);

            this.setState({
                isPositionOutside: isPositionOutside,
                position: position
            }, this.onPositionChanged);
        }
    }, {
        key: 'setElementDimensionsState',
        value: function setElementDimensionsState(dimensions) {
            this.setState({
                elementDimensions: dimensions
            });
        }
    }, {
        key: 'schedulActivation',
        value: function schedulActivation(schedule) {
            var _this3 = this;

            var scheduleId = setTimeout(function () {
                _this3.activate();
            }, schedule);

            this.timers.push({
                id: scheduleId,
                name: constants.SET_ACTIVATION_TIMER_NAME
            });
        }
    }, {
        key: 'scheduleDeactivation',
        value: function scheduleDeactivation(schedule) {
            var _this4 = this;

            var scheduleId = setTimeout(function () {
                _this4.deactivate();
            }, schedule);

            this.timers.push({
                id: scheduleId,
                name: constants.UNSET_ACTIVATION_TIMER_NAME
            });
        }
    }, {
        key: 'clearActivationTimers',
        value: function clearActivationTimers() {
            this.clearTimer(constants.SET_ACTIVATION_TIMER_NAME);
            this.clearTimer(constants.UNSET_ACTIVATION_TIMER_NAME);
        }
    }, {
        key: 'setPressEventTimer',
        value: function setPressEventTimer() {
            var _this5 = this;

            var _props = this.props,
                pressDuration = _props.pressDuration,
                pressMoveThreshold = _props.pressMoveThreshold;


            this.timers.push({
                name: constants.PRESS_EVENT_TIMER_NAME,
                id: setTimeout(function () {
                    if (Math.abs(_this5.currentElTop - _this5.initialElTop) < pressMoveThreshold) {
                        _this5.activate();
                    }
                }, pressDuration)
            });
        }
    }, {
        key: 'setPressEventCriteria',
        value: function setPressEventCriteria(position) {
            this.currentElTop = position.y;
        }
    }, {
        key: 'initPressEventCriteria',
        value: function initPressEventCriteria(position) {
            var top = position.y;
            this.initialElTop = top;
            this.currentElTop = top;
        }
    }, {
        key: 'setShouldGuardAgainstMouseEmulationByDevices',
        value: function setShouldGuardAgainstMouseEmulationByDevices() {
            this.shouldGuardAgainstMouseEmulationByDevices = true;
        }
    }, {
        key: 'unsetShouldGuardAgainstMouseEmulationByDevices',
        value: function unsetShouldGuardAgainstMouseEmulationByDevices() {
            var _this6 = this;

            this.timers.push({
                name: constants.MOUSE_EMULATION_GUARD_TIMER_NAME,
                id: setTimeout(function () {
                    _this6.shouldGuardAgainstMouseEmulationByDevices = false;
                }, 0)
            });
        }
    }, {
        key: 'clearTimers',
        value: function clearTimers() {
            var timers = this.timers;
            while (timers.length) {
                var timer = timers.pop();
                clearTimeout(timer.id);
            }
        }
    }, {
        key: 'clearTimer',
        value: function clearTimer(timerName) {
            this.timers.forEach(function (timer) {
                if (timer.name === timerName) {
                    clearTimeout(timer.id);
                }
            });
        }
    }, {
        key: 'getElementDimensions',
        value: function getElementDimensions(el) {
            var _el$getBoundingClient = el.getBoundingClientRect(),
                width = _el$getBoundingClient.width,
                height = _el$getBoundingClient.height;

            return {
                width: width,
                height: height
            };
        }
    }, {
        key: 'getIsPositionOutside',
        value: function getIsPositionOutside(position) {
            var x = position.x,
                y = position.y;
            var _state$elementDimensi = this.state.elementDimensions,
                width = _state$elementDimensi.width,
                height = _state$elementDimensi.height;


            var isPositionOutside = x < 0 || y < 0 || x > width || y > height;

            return isPositionOutside;
        }
    }, {
        key: 'getTouchEvent',
        value: function getTouchEvent(e) {
            return e.touches[0];
        }
    }, {
        key: 'getIsReactComponent',
        value: function getIsReactComponent(reactElement) {
            return typeof reactElement.type === 'function';
        }
    }, {
        key: 'shouldDecorateChild',
        value: function shouldDecorateChild(child) {
            return !!child && this.getIsReactComponent(child) && this.props.shouldDecorateChildren;
        }
    }, {
        key: 'decorateChild',
        value: function decorateChild(child, props) {
            return cloneElement(child, props);
        }
    }, {
        key: 'decorateChildren',
        value: function decorateChildren(children, props) {
            var _this7 = this;

            return Children.map(children, function (child) {
                return _this7.shouldDecorateChild(child) ? _this7.decorateChild(child, props) : child;
            });
        }
    }, {
        key: 'addEventListeners',
        value: function addEventListeners() {
            this.eventListeners.push(addEventListener(this.el, 'touchstart', this.onTouchStart, { passive: false }), addEventListener(this.el, 'touchmove', this.onTouchMove, { passive: false }), addEventListener(this.el, 'touchend', this.onTouchEnd), addEventListener(this.el, 'touchcancel', this.onTouchCancel), addEventListener(this.el, 'mouseenter', this.onMouseEnter), addEventListener(this.el, 'mousemove', this.onMouseMove), addEventListener(this.el, 'mouseleave', this.onMouseLeave));
        }
    }, {
        key: 'removeEventListeners',
        value: function removeEventListeners() {
            while (this.eventListeners.length) {
                this.eventListeners.pop().removeEventListener();
            }
        }
    }, {
        key: 'getPassThroughProps',
        value: function getPassThroughProps() {
            var ownPropNames = Object.keys(this.constructor.propTypes);
            return omit(this.props, ownPropNames);
        }
    }, {
        key: 'render',
        value: function render() {
            var _this8 = this;

            var _props2 = this.props,
                children = _props2.children,
                className = _props2.className,
                mapChildProps = _props2.mapChildProps,
                style = _props2.style;

            var props = objectAssign({}, mapChildProps(this.state), this.getPassThroughProps());

            return React.createElement(
                'div',
                {
                    className: className,
                    ref: function ref(el) {
                        return _this8.el = el;
                    },
                    style: objectAssign({}, style, {
                        WebkitUserSelect: 'none'
                    })
                },
                this.decorateChildren(children, props)
            );
        }
    }, {
        key: 'isCoreReady',
        get: function get() {
            return !!this.core;
        }
    }]);

    return _class;
}(React.Component);

_class.displayName = 'ReactCursorPosition';
_class.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    hoverDelayInMs: PropTypes.number,
    hoverOffDelayInMs: PropTypes.number,
    isActivatedOnTouch: PropTypes.bool,
    isEnabled: PropTypes.bool,
    mapChildProps: PropTypes.func,
    onActivationChanged: PropTypes.func,
    onPositionChanged: PropTypes.func,
    onDetectedEnvironmentChanged: PropTypes.func,
    pressDuration: PropTypes.number,
    pressMoveThreshold: PropTypes.number,
    shouldDecorateChildren: PropTypes.bool,
    shouldStopTouchMovePropagation: PropTypes.bool,
    style: PropTypes.object
};
_class.defaultProps = {
    isActivatedOnTouch: false,
    isEnabled: true,
    hoverDelayInMs: 0,
    hoverOffDelayInMs: 0,
    mapChildProps: function mapChildProps(props) {
        return props;
    },
    onActivationChanged: noop,
    onPositionChanged: noop,
    onDetectedEnvironmentChanged: noop,
    pressDuration: 500,
    pressMoveThreshold: 5,
    shouldDecorateChildren: true,
    shouldStopTouchMovePropagation: false
};

var _initialiseProps = function _initialiseProps() {
    var _this9 = this;

    this.onPositionChanged = function () {
        var onPositionChanged = _this9.props.onPositionChanged;

        onPositionChanged(_this9.state);
    };
};

export default _class;