import { BomItems, OrUndefined, ProcessActionStepWorkInstructions } from "@kortex/aos-common";
import { TableBody } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import { useProcessEditorContext } from "../../../../../../context";
import { ActionEditorContext } from "../../../../../context";
import { useWorkInstructionBomContext } from "../../../context";

import WorkInstructionsBomItemsRow from "./Row/WorkInstructionsBomItemsTableRow";
import WorkInstructionsBomStepItemsRow from "./Row/WorkInstructionsBomStepItemsTableRow";

interface IOwnProps {
    displayStepItems: boolean;
}

function WorkInstructionsBomItemsTableBody(props: IOwnProps): JSX.Element {
    const { displayStepItems } = props;

    const { bom } = useProcessEditorContext();
    const { editedActionStep } = useContext(ActionEditorContext);
    const { itemFilterCb } = useWorkInstructionBomContext();
    const [items, seItems] = useState<BomItems>({});

    useEffect(() => {
        seItems(assignItems());
    }, [bom, editedActionStep]);

    const assignItems = (): BomItems => {
        let workInstructionStepBomItems: BomItems = {};
        const workInstructionsStep = editedActionStep as OrUndefined<ProcessActionStepWorkInstructions>; // To help with typing

        if (bom) {
            if (displayStepItems && workInstructionsStep) {
                for (const partNumber of Object.keys(workInstructionsStep.config.bomItems ?? {})) {
                    if (bom.items[partNumber]) {
                        workInstructionStepBomItems[partNumber] = {
                            ...bom.items[partNumber],
                            ...workInstructionsStep.config.bomItems[partNumber],
                        };
                    }
                }
            } else {
                workInstructionStepBomItems = bom.items;
            }
        }

        return workInstructionStepBomItems;
    };

    return (
        <TableBody>
            {Object.values(items).map((item, index) =>
                itemFilterCb(item) ? (
                    displayStepItems ? (
                        <WorkInstructionsBomStepItemsRow index={index} item={item} />
                    ) : (
                        <WorkInstructionsBomItemsRow index={index} item={item} />
                    )
                ) : null
            )}
        </TableBody>
    );
}

export default WorkInstructionsBomItemsTableBody;
