export const erp = {
    rpc: {
        getBom: {
            funcId: 15001,
            funcName: "erpGetBom",
        },
        getWoBom: {
            funcId: 15002,
            funcName: "erpGetWoBom",
        },
        getBomPlayer: {
            funcId: 15003,
            funcName: "erpGetBomPlayer",
        },
        getBomByJob: {
            funcId: 15004,
            funcName: "erpgetBomByJob",
        },
        getBomByTrackingId: {
            funcId: 15005,
            funcName: "erpGetBomByTrackingId",
        },
        getItemsByPartNumber: {
            funcId: 15006,
            funcName: "erpGetItemsByPartNumber",
        },
        getItemsByTraceability: {
            funcId: 15007,
            funcName: "erpGetItemsByTraceability",
        },
        getItemsByAdjustmentType: {
            funcId: 15008,
            funcName: "erpGetItemsByAdjustmentType",
        },
    },
    serviceId: 150,
    serviceVersion: 1,
};
