import { KortexTextField, secondaryPalette, theme } from "@aos/react-components";
import {
    Button,
    Divider,
    IconButton,
    InputAdornment,
    InputBaseProps,
    InputProps,
    makeStyles,
    OutlinedInputProps,
    Popover,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import * as React from "react";
import { useRef, useState, useEffect } from "react";

import FiltersIcon from "../../components/core/Icons/Filters/Filters";
import { useTranslate } from "../../hooks/useTranslate";

const useStyles = makeStyles({
    formControl: {
        margin: 0,
        position: "relative",
    },
    input: {
        padding: "14px 12px 14px 8px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
    },
    inputContainer: {
        width: "-webkit-fill-available",
    },
    popOver: {
        borderRadius: "2px",
    },
    active: {
        color: secondaryPalette[400],
    },
    divider: {
        width: "1px",
        height: "38px",
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 20px",
    },
    sectionButton: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
        padding: "0px 20px 15px 20px",
    },
    closeFilterButton: {
        color: secondaryPalette[500],
        flex: 1,
        //padding: "15px",
    },
});

interface IOwnProps {
    value?: string;
    placeholder?: string;
    className?: string;
    clearInput?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
    children?: JSX.Element;
    isFilterActive?: boolean;
    hideFiltersButton?: boolean;
    onChange?: (val: string) => void;
    onClose?: () => void;
    InputProps?: InputBaseProps | OutlinedInputProps | InputProps;
    buttonText?: string;
    changedDelayMS?: number;
    icon?: JSX.Element;
}

export default function KortexSearchField(props: IOwnProps): JSX.Element {
    const {
        value = "",
        onChange,
        onClose,
        placeholder,
        className,
        disabled,
        style,
        children,
        isFilterActive,
        clearInput,
        hideFiltersButton,
        InputProps,
        buttonText,
        changedDelayMS = 500,
        icon = <SearchIcon />,
    } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [optionsOpen, setOptionsOpen] = useState(false);
    const [stateValue, setStateValue] = useState(value);
    const refElement = useRef(null);

    const optionWidth = refElement.current != null ? (refElement.current as unknown as HTMLDivElement).clientWidth : 0;

    /**
     * When filters change, execute onChange cb
     */
    useEffect((): void => {
        if (clearInput === true) {
            setStateValue(""); // Clear input
        }
    }, [clearInput]);

    /**
     * Opens the filters popper
     */
    const handleOpenOptions = (): void => {
        setOptionsOpen(true);
    };

    /**
     * Closes the filters popper
     */
    const handleCloseOptions = (): void => {
        setOptionsOpen(false);
        onClose?.();
    };

    /**
     * Called when the ClearInput button is clicked
     */
    const handleClearInput = (): void => {
        setStateValue("");

        if (onChange) {
            onChange("");
        }
    };

    /**
     * Called when the searchField text changes
     */
    const handleOnChange = (value: string): void => {
        setStateValue(value);

        if (onChange) {
            onChange(value);
        }
    };

    const endAdornmentComponent = (hideFiltersButton?: boolean): JSX.Element => {
        if (hideFiltersButton) {
            return <></>;
        }

        return (
            <InputAdornment position="end">
                <IconButton onClick={handleOpenOptions} id={"filterId"}>
                    <FiltersIcon className={isFilterActive ? classes.active : undefined} />
                </IconButton>
                <Divider className={classes.divider} />
                {stateValue ? (
                    <IconButton onClick={handleClearInput} id={"closeFilterId"}>
                        <CloseIcon />
                    </IconButton>
                ) : (
                    <IconButton onClick={handleOpenOptions} id={"dropDownFilterId"}>
                        <ArrowDropDownIcon />
                    </IconButton>
                )}
            </InputAdornment>
        );
    };

    return (
        <div ref={refElement} style={style} className={`${classes.formControl} ${className}`}>
            <KortexTextField
                className={classes.inputContainer}
                value={stateValue}
                changedDelayMS={changedDelayMS}
                onChanged={handleOnChange}
                InputProps={{
                    id: InputProps?.id,
                    classes: {
                        input: classes.input,
                    },
                    disabled,
                    startAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
                    endAdornment: { ...endAdornmentComponent(hideFiltersButton) },
                    placeholder: placeholder || translate("kortexSearchField.placeholder"),
                }}
            />
            <Popover
                id={"popoverId"}
                open={optionsOpen}
                anchorEl={refElement.current}
                onClose={handleCloseOptions}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                classes={{
                    paper: classes.popOver,
                }}
                PaperProps={{
                    style: { width: optionWidth },
                }}
            >
                <div style={{ padding: "16px" }}>{children}</div>

                <div className={classes.sectionButton}>
                    <div className={classes.closeFilterButton} />
                    <Button
                        id="closeButtonId"
                        variant="contained"
                        color="secondary"
                        onClick={handleCloseOptions}
                        className={classes.dialogButtons}
                    >
                        {buttonText ?? translate("player.close")}
                    </Button>
                    <div />
                </div>
            </Popover>
        </div>
    );
}
