import { IBomItem, ProcessActionStepWorkInstructions } from "@kortex/aos-common";
import { useProcessEditorContext } from "@kortex/aos-ui/components/pages/ProcessEditor/ProcessEditor/context";
import { deepClone } from "@kortex/utilities";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import React, { useContext } from "react";

import MoveUpIcon from "../../../../../../../../../../core/Icons/MoveUp/MoveUp";
import { ActionEditorContext } from "../../../../../../context";
import ItemCardOfTooltip from "../../../utilities/ItemCardOfTooltip";

interface IOwnProps {
    item: IBomItem;
    index: number;
}

function WorkInstructionsBomItemsRow(props: IOwnProps): JSX.Element {
    const { item, index } = props;

    const { bom } = useProcessEditorContext();
    const { onChangedStep, editedActionStep } = useContext(ActionEditorContext);

    /**
     * Add BOM item to the step
     */
    const handleMoveItemBomToItemStep = (partNumber: string) => (): void => {
        if (editedActionStep && bom) {
            const step = deepClone(editedActionStep as ProcessActionStepWorkInstructions);
            step.config.bomItems[partNumber] = { partNumber, quantity: 1 };

            onChangedStep(step);
        }
    };

    return (
        <TableRow key={index}>
            <TableCell component="th" scope="row" id={`${item.partNumber}BubbleNumberId`}>
                {item.bubbleNumber}
            </TableCell>
            <ItemCardOfTooltip item={item} />
            <TableCell align="right" width={"5px"} />
            <TableCell align="center" width={"10%"} id={`${item.partNumber}QuantityId`}>
                {item.quantity}
            </TableCell>
            <TableCell align="right" width={"5px"} />
            <TableCell align="right" width={"5px"}>
                <IconButton onClick={handleMoveItemBomToItemStep(item.partNumber)} id={`move${item.partNumber}ItemBomToItemStepButtonId`}>
                    <MoveUpIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

export default WorkInstructionsBomItemsRow;
