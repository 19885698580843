import { magentaPalette } from "@aos/react-components";
import {
    EnumElementType,
    ICoords,
    ICoordsPair,
    IWorkInstructionsElementConfig,
    IWorkInstructionsFormConfig,
    IWorkInstructionsImageConfig,
    IWorkInstructionsLineConfig,
    IWorkInstructionsMarkerConfig,
    IWorkInstructionsPDFConfig,
    IWorkInstructionsShapeConfig,
    IWorkInstructionsTemplateConfig,
    IWorkInstructionsTextConfig,
    IWorkInstructionsVideoProps,
    ProcessActionStepWorkInstructions,
    TWorkInstructionsExtendedConfig,
    WorkInstructionsElementConfig,
    WorkInstructionsFormConfig,
    WorkInstructionsImageConfig,
    WorkInstructionsLineConfig,
    WorkInstructionsMarkerConfig,
    WorkInstructionsPDFConfig,
    WorkInstructionsShapeConfig,
    WorkInstructionsStepState,
    WorkInstructionsTemplateConfig,
    WorkInstructionsTextConfig,
    WorkInstructionsVideoConfig,
    formConfigHelpers,
} from "@kortex/aos-common";
import { TrainingIcon } from "@kortex/aos-ui/components/core/Icons/training";
import { useKeybind, useKeybindCopyPaste } from "@kortex/aos-ui/hooks/useKeybind";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import shortid from "shortid";

import { EnumClipboardDataType } from "../../../../../../configs/EnumClipboardDataType";
import { useForeground } from "../../../../../../hooks/useForeground";
import { useKeyPressed } from "../../../../../../hooks/useKeyPressed";
import { processActionSettingsUpdate } from "../../../../../../redux/process-manager/process-thunks-process-action-settings";
import { useSelectorProcessActionSettings } from "../../../../../../redux/selectors";
import { userCanWrite } from "../../../../../../utilitites/IUserRights";
import { calcBoundingBox } from "../../../../../../utilitites/math/math";
import { normalizeSVGDimension } from "../../../../../../utilitites/normalizeSVGDimension";
import DraggableElementManipulator, {
    EnumTransformType,
    IConnector,
    IDraggableElementManipulator,
    ITransformInfo,
} from "../../../../../core/DraggableElementManipulator/DraggableElementManipulator";
import SvgMouseDraw from "../../../../../core/SvgMouseDraw/SvgMouseDraw";
import { useProcessEditorContext } from "../../context";
import { IActionStepProps } from "../IActionStepProps";
import { ActionEditorContext } from "../context";

import WorkInstructionsBom from "./Bom/WorkInstructionsBom";
import { WorkInstructionsBomProvider } from "./Bom/context";
import WorkInstructionsElementMenu, { EnumSortDirection } from "./ElementMenu/WorkInstructionsElementMenu";
import WorkInstructionsElement from "./Elements/Element/WorkInstructionsElement";
import WorkInstructionsFormEditor from "./Elements/Form/Editor/WorkInstructionsFormEditor";
import WorkInstructionsImageEditor from "./Elements/Image/Editor/WorkInstructionsImageEditor";
import WorkInstructionsLineEditor from "./Elements/Line/Editor/WorkInstructionsLineEditor";
import WorkInstructionsMarkerEditor from "./Elements/Marker/Editor/WorkInstructionsMarkerEditor";
import WorkInstructionsPDFEditor from "./Elements/PDF/Editor/WorkInstructionsPDFEditor";
import WorkInstructionsShapeEditor from "./Elements/Shape/Editor/WorkInstructionsShapeEditor";
import WorkInstructionsTextEditor from "./Elements/Text/Editor/WorkInstructionsTextEditor";
import WorkInstructionsVideoEditor from "./Elements/Video/Editor/WorkInstructionsVideoEditor";
import WorkInstructionsTemplateEditor from "./Template/Editor/WorkInstructionsTemplateEditor";
import WorkInstructionsTemplateSelector from "./Template/Selector/WorkInstructionsTemplateSelector";
import WorkInstructionsDisplayModesToolbar, { EnumDisplayMode } from "./Toolbars/DisplayModes/WorkInstructionsDisplayModesToolbar";
import WorkInstructionsElementsToolbar from "./Toolbars/Elements/WorkInstructionsElementsToolbar";
import { WorkInstructionsTrainingCommuniqueEditor } from "./TrainingCommunique";
import { VIEW_BOX_HEIGHT, VIEW_BOX_WIDTH, VIEW_RATIO } from "./WorkInstructionsConstants";
import { IWorkInstructionsElementMenuItem, WorkInstructionsEditorContext } from "./utilities";

const IMAGE_SIZE_FACTOR = 0.8;
const EDIT_ICON_HEIGHT = 48;
const DEFAULT_PASTE_OFFSET = 20;
let PASTE_OFFSET = DEFAULT_PASTE_OFFSET;
const ACTION_EDITOR_ELEMENTS_HEIGHT = 176; // Header(75px), margins(32px), toolbar icons(53px), header/toolbar grid gap(16px)
const ACTION_EDITOR_ELEMENTS_WIDTH = 236; // Steps (188px), margins(32px), steps/editor grid gap(16px)

const useStyles = makeStyles({
    displayModesToolbar: {
        justifySelf: "end",
    },
    elementsToolbar: {
        justifySelf: "start",
    },
    mediaViewbox: {
        height: "100%",
        position: "relative",
        display: "grid",
        alignItems: "start",
        gridTemplateRows: "auto 1fr",
        gridRowGap: "16px",
    },
    root: {
        height: "100%",
        display: "grid",
    },
    stepModifierTrainingIcon: {
        color: magentaPalette["magenta"],
    },
    svgContainer: {
        display: "flex",
    },
    toolbarContainer: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
    },
});

const initManipulatorProps: IDraggableElementManipulator = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    cropBottom: 0,
    cropTop: 0,
    cropLeft: 0,
    cropRight: 0,
    rotation: 0,
    points: [],
    scale: 1,

    canvasScale: 0,
    origin: { x: 0, y: 0 },

    showGuideLines: true,
};

type TElement = IWorkInstructionsElementConfig<TWorkInstructionsExtendedConfig>;

export default function WorkInstructionEditor(props: IActionStepProps<ProcessActionStepWorkInstructions>): JSX.Element {
    const classes = useStyles();
    const { actionStepProps, userAccessLevel } = props;

    const dispatch = useThunkDispatch();
    const { bom } = useProcessEditorContext();
    const processActionSettings = useSelectorProcessActionSettings();

    const [editedElement, setEditedElement] = useState<TElement | undefined>(undefined);
    const [elementEditorOpen, setElementEditorOpen] = useState(false);
    const [elementManipulatorProps, setElementManipulatorProps] = useState<IDraggableElementManipulator>(initManipulatorProps);
    const [elementMenuPosition, setElementMenuPosition] = useState<ICoords | undefined>(undefined);
    const [mediaHeight, setMediaHeight] = useState(0);
    const [selectedElementsId, setSelectedElementsId] = useState<string[]>([]);
    const [trainingCommuniqueEditorOpen, setTrainingCommuniqueEditorOpen] = useState(false);
    const [templateEditorOpen, setTemplateEditorOpen] = useState(false);
    const [templateSelectorOpen, setTemplateSelectorOpen] = useState(false);
    const [workInstructionElements, setWorkInstructionElements] = useState<TElement[]>([]);
    const [mouseDrawCoords, setMouseDrawCoords] = useState<Partial<ICoords>>({ x: undefined, y: undefined });
    const [elementMenuItems, setElementMenuItems] = useState<IWorkInstructionsElementMenuItem[]>([]);

    const { setStepModifiersCb } = useContext(ActionEditorContext);

    const translate = useTranslate();

    const svgCanvasRef = useRef<SVGSVGElement | null>(null);
    const elementManipulatorRef = useRef(null);

    const readOnly = !userCanWrite(userAccessLevel);
    const isForeground = useForeground();

    // keyboard events
    const ctrlPressed = useKeyPressed(17, readOnly);
    const shiftPressed = useKeyPressed(16, readOnly);

    const elementManipulatorDisabled = readOnly || selectedElementsId.length === 0 || !isForeground;

    useEffect(() => {
        // Event listener on the document to handle clicks outside the SVG element
        const handleClickOutside = (event: MouseEvent): void => {
            if (svgCanvasRef.current && !svgCanvasRef.current.contains(event.target as Node)) {
                // Click outside SVG element
                setSelectedElementsId([]); // Deselect the item
                setElementMenuPosition(undefined);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    /**
     * Key down event - Delete
     * Deletes selected elements if any
     */
    useKeybind("Delete", () => handleDeleteSelectedElements(), {
        disabled: readOnly || !selectedElementsId.length,
        next: true,
    });

    /**
     * Copy and paste work instruction elements
     */
    useKeybindCopyPaste(
        // Copy
        (event: ClipboardEvent): void => {
            const selectedElements = workInstructionElements.filter((element: TElement): boolean => {
                return Boolean(selectedElementsId.find((x: string): boolean => x === element._id));
            });

            if (!selectedElements.length) {
                event.clipboardData?.clearData(EnumClipboardDataType.WI_ELEMENTS);
                return void 0;
            }

            event.preventDefault();
            event.stopPropagation();

            if (event.clipboardData) {
                event.clipboardData.clearData();
                event.clipboardData.setData(EnumClipboardDataType.WI_ELEMENTS, JSON.stringify(selectedElements));
            }

            // Reset PASTE_OFFSET
            PASTE_OFFSET = DEFAULT_PASTE_OFFSET;
        },
        // Paste
        (event: ClipboardEvent): void => {
            if (!event.clipboardData || !event.clipboardData.types.find((x: string): boolean => x === EnumClipboardDataType.WI_ELEMENTS)) {
                return;
            }

            event.preventDefault();
            event.stopPropagation();

            const copiedElements: TElement[] = JSON.parse(event.clipboardData.getData(EnumClipboardDataType.WI_ELEMENTS));
            let zIndex = getMaxZIndex();

            for (const element of copiedElements) {
                element._id = shortid(); // reasign new ids for every inserted element
                element.x += PASTE_OFFSET; // x offset to not completely overlap the copied element
                element.y += PASTE_OFFSET; // y offset to not completely overlap the copied element
                element.zIndex = zIndex++; // z-index is incremented for each copied element

                // Assign new ID to all form items
                if (element.type === EnumElementType.FORM) {
                    for (const formItem of (element.extendedProps as IWorkInstructionsFormConfig).formItems) {
                        formItem._id = shortid();
                    }
                }

                // Move all arrowheads
                if (element.type === EnumElementType.LINE) {
                    for (const arrowhead of (element.extendedProps as IWorkInstructionsLineConfig).arrowheads) {
                        arrowhead.x += PASTE_OFFSET;
                        arrowhead.y += PASTE_OFFSET;
                    }
                }

                // Move all arrows
                if (element.type === EnumElementType.MARKER) {
                    for (const arrow of (element.extendedProps as IWorkInstructionsMarkerConfig).arrows) {
                        arrow.x += PASTE_OFFSET;
                        arrow.y += PASTE_OFFSET;
                    }
                }
            }

            // increment PASTE_OFFSET
            PASTE_OFFSET += DEFAULT_PASTE_OFFSET;

            setSelectedElementsId(copiedElements.map((element: TElement): string => element._id));

            const nextWorkInstructionElements = [...workInstructionElements, ...copiedElements];

            setWorkInstructionElements(nextWorkInstructionElements);

            // propagate change
            updateElements(nextWorkInstructionElements);
        },
        {
            disabled: elementEditorOpen || templateSelectorOpen || templateEditorOpen || readOnly,
        }
    );

    /**
     * Resize canvas when window is resized
     */
    useEffect((): (() => void | undefined) => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        setStepModifiersCb<ProcessActionStepWorkInstructions>((stepProps) =>
            stepProps.config.trainingCommunique
                ? {
                      icons: [
                          {
                              element: <TrainingIcon className={classes.stepModifierTrainingIcon} />,
                              tooltip: translate("action.workInstructions.stepModifier.trainingCommunique"),
                          },
                      ],
                  }
                : undefined
        );

        return (): void => {
            window.removeEventListener("resize", updateDimensions);

            setStepModifiersCb();
        };
    }, []);

    /**
     * Reset selected Element if step is changed
     */
    useEffect((): void => {
        setEditedElement(undefined);
        setElementMenuPosition(undefined);
        setSelectedElementsId([]);

        // reset PASTE_OFFSET
        PASTE_OFFSET = 0;
    }, [props.selectedStepIndex]);

    /**
     * Updates state when props change
     */
    useEffect((): void => {
        if (props.actionStepProps) {
            setWorkInstructionElements(props.actionStepProps.config.workInstructionElements);
        }
    }, [props.actionStepProps?.config.workInstructionElements]);

    /**
     * Effect that reset manipulator props when no element is selected
     */
    useEffect((): void => {
        if (selectedElementsId.length === 0) {
            updateManipulator([]);
            setElementMenuItems([]);
        } else if (selectedElementsId.length === 1) {
            setElementMenuItems(
                elementMenuItems.map((item) => ({
                    ...item,
                    hidden: selectedElementsId[0] !== item.elementId,
                }))
            );
        }
    }, [selectedElementsId]);

    /**
     * Effect that reset manipulator props when elemenents are changed
     * Used to capture element changes triggered by the do/undo mechanism
     */
    useEffect((): void => {
        updateManipulator(selectedElementsId);
    }, [workInstructionElements]);

    /**
     * Effect that reposition the menu icon (3dots) when the manipulator props change
     */
    useEffect((): void => {
        if (selectedElementsId.length === 1) {
            if (!elementManipulatorRef.current) {
                return;
            }

            const bounds = (elementManipulatorRef.current as unknown as HTMLElement).getBoundingClientRect();
            const newCoords = { x: bounds.left + bounds.width, y: bounds.top - EDIT_ICON_HEIGHT / 2 };

            setElementMenuPosition(newCoords);
        } else {
            setElementMenuPosition(undefined);
        }
    }, [elementManipulatorProps]);

    /**
     * Update elements
     *
     * @param {object[]} elements - new wi elements
     */
    const updateElements = (elements: TElement[]): void => {
        props.onChanged({
            ...props.actionStepProps,
            config: {
                ...props.actionStepProps.config,
                workInstructionElements: elements,
            },
        });
    };

    /**
     * Display editor according to the edited element's type
     */
    const displayElementEditor = (element: TElement): JSX.Element | undefined => {
        switch (element.type) {
            case EnumElementType.FORM:
                return (
                    <WorkInstructionsFormEditor
                        actionFormElement={element as IWorkInstructionsElementConfig<IWorkInstructionsFormConfig>}
                        onCancel={handleCloseFormEditor}
                        onSave={saveElement}
                        open={elementEditorOpen}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case EnumElementType.IMAGE:
                return (
                    <WorkInstructionsImageEditor
                        imageProps={element as IWorkInstructionsElementConfig<IWorkInstructionsImageConfig>}
                        maxHeight={VIEW_BOX_HEIGHT * IMAGE_SIZE_FACTOR}
                        maxWidth={VIEW_BOX_WIDTH * IMAGE_SIZE_FACTOR}
                        onCancel={handleCloseFormEditor}
                        onSave={saveElement}
                        open={elementEditorOpen}
                    />
                );
            case EnumElementType.LINE:
                return (
                    <WorkInstructionsLineEditor
                        lineProps={element as IWorkInstructionsElementConfig<IWorkInstructionsLineConfig>}
                        onCancel={handleCloseFormEditor}
                        onSave={saveElement}
                        open={elementEditorOpen}
                    />
                );
            case EnumElementType.MARKER:
                return (
                    <WorkInstructionsMarkerEditor
                        markerProps={element as IWorkInstructionsElementConfig<IWorkInstructionsMarkerConfig>}
                        onCancel={handleCloseFormEditor}
                        onSave={saveElement}
                        open={elementEditorOpen}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case EnumElementType.PDF:
                return (
                    <WorkInstructionsPDFEditor
                        onCancel={handleCloseFormEditor}
                        onSave={saveElement}
                        open={elementEditorOpen}
                        pdfProps={element as IWorkInstructionsElementConfig<IWorkInstructionsPDFConfig>}
                    />
                );
            case EnumElementType.SHAPE:
                return (
                    <WorkInstructionsShapeEditor
                        onCancel={handleCloseFormEditor}
                        onSave={saveElement}
                        open={elementEditorOpen}
                        shapeProps={element as IWorkInstructionsElementConfig<IWorkInstructionsShapeConfig>}
                    />
                );
            case EnumElementType.TEXT:
                return (
                    <WorkInstructionsTextEditor
                        labelProps={element as IWorkInstructionsElementConfig<IWorkInstructionsTextConfig>}
                        onCancel={handleCloseFormEditor}
                        onSave={saveElement}
                        open={elementEditorOpen}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case EnumElementType.VIDEO:
                return (
                    <WorkInstructionsVideoEditor
                        onCancel={handleCloseFormEditor}
                        onSave={saveElement}
                        open={elementEditorOpen}
                        videoProps={element as IWorkInstructionsElementConfig<IWorkInstructionsVideoProps>}
                    />
                );
            default:
                return;
        }
    };

    /**
     * Open the editor to edit selected element
     */
    const editElement = (): void => {
        setEditedElement(workInstructionElements.find((x: TElement): boolean => x._id === selectedElementsId[0]));
        setElementEditorOpen(true);
    };

    /**
     * Return the elements' greatest z-index value
     */
    const getMaxZIndex = (): number => {
        const zIndexList = workInstructionElements.map((element: TElement): number => element.zIndex);
        return zIndexList.length > 0 ? Math.max(...zIndexList) + 1 : 0;
    };

    /**
     * Close template dialog
     */
    const handleCancelTemplate = (): void => {
        setTemplateEditorOpen(false);
    };

    /**
     * Close form editor
     */
    const handleCloseFormEditor = (): void => {
        setElementEditorOpen(false);
    };

    /**
     * Close template editor
     */
    const handleCloseTemplateSelector = (): void => {
        setTemplateSelectorOpen(false);
    };

    /**
     * Functiom which handles the creation of a new template or the overwriting of an existing template
     *
     * @param {WorkInstructionsTemplateConfig} template - Template info
     * @param {boolean} overwriteAll - True to overwrite all steps using the template, false to overwrite only current step's template
     */
    const handleCreateTemplate = (template: WorkInstructionsTemplateConfig, overwriteAll: boolean): void => {
        const elementsCopy = workInstructionElements.map((element) => {
            return {
                ...element,
                templateId: template._id,
            };
        });

        const templateSettings = processActionSettings.find((settings) => settings.type === "core-work-instructions");
        const newTemplate = { ...template, workInstructionElements: elementsCopy };

        if (templateSettings) {
            const index = templateSettings.settings.templates.findIndex(
                (template: IWorkInstructionsTemplateConfig): boolean => template._id === newTemplate._id
            );

            if (index === -1) {
                templateSettings.settings.templates.push(newTemplate);
            } else {
                templateSettings.settings.templates[index] = newTemplate;
            }

            dispatch(processActionSettingsUpdate(templateSettings));
        }

        if (overwriteAll && props.forEachStep) {
            props.forEachStep((step, index) => {
                const formStep = step as ProcessActionStepWorkInstructions;

                if (index !== props.selectedStepIndex && formStep.config.templateId === template._id) {
                    formStep.config.workInstructionElements = formStep.config.workInstructionElements
                        .filter((element: TElement): boolean => element.templateId !== template._id)
                        .concat(props.actionStepProps.config.workInstructionElements);
                }
                return formStep;
            });
        }

        setTemplateEditorOpen(false);

        props.onChanged({
            ...props.actionStepProps,
            config: {
                ...props.actionStepProps.config,
                workInstructionElements: elementsCopy,
                templateId: template._id,
            },
        });
    };

    /**
     * Delete selected element(s)
     */
    const handleDeleteSelectedElements = (): void => {
        let elementsCopy = [...workInstructionElements];
        elementsCopy = elementsCopy
            // filter out selected elements
            .filter((element) => !selectedElementsId.includes(element._id))
            // sort elements by zIndex
            .sort((a: TElement, b: TElement): number => a.zIndex - b.zIndex)
            // apply new index to each element
            .map((sortedElement: TElement, sortedIndex: number): TElement => {
                return { ...sortedElement, zIndex: sortedIndex };
            });

        // clear selection
        setSelectedElementsId([]);

        // hide contextual menu button
        setElementMenuPosition(undefined);

        // preemptively remove elements
        setWorkInstructionElements(elementsCopy);

        // propagate change
        updateElements(elementsCopy);
    };

    /**
     * Called when an option from the display mode toolbar is clicked
     *
     * @param {EnumDisplayMode} displayMode - clicked option in the toolbar
     */
    const handleDisplayModeChange = (displayMode: EnumDisplayMode): void => {
        switch (displayMode) {
            case EnumDisplayMode.TEMPLATE_EDITOR:
                setTemplateEditorOpen(true);
                break;

            case EnumDisplayMode.TEMPLATE_SELECTOR:
                setTemplateSelectorOpen(true);
                break;

            case EnumDisplayMode.TRAINING_COMMUNIQUE:
                setTrainingCommuniqueEditorOpen(true);
                break;

            default:
                return;
        }
    };

    /**
     * Called when an Element is double clicked. Opens Edit window
     */
    const handleElementDoubleClick = (event: React.MouseEvent<SVGGElement, MouseEvent>): void => {
        event.stopPropagation();
        event.preventDefault();
        editElement();
    };

    /**
     * Opens an element editor
     */
    const handleElementEdit = (): void => {
        editElement();
    };

    /**
     * Called when a Work Instruction Element mousedown event is triggered
     * Updates the manipulator
     *
     * @param {string} id - id of the Element that triggered the mousedown event
     */
    const handleElementMouseDown =
        (id: string): ((event: React.MouseEvent<SVGUseElement | SVGCircleElement | SVGRectElement, MouseEvent>) => void) =>
        (event: React.MouseEvent<SVGUseElement | SVGCircleElement | SVGRectElement, MouseEvent>): void => {
            event.stopPropagation();

            // build a new selectedElementsId array based on the state and based on which element the click was trigged
            let selectedElementsIdClone = [...selectedElementsId];
            if (ctrlPressed || shiftPressed) {
                const index = selectedElementsIdClone.findIndex((selectedId) => selectedId === id);
                if (index === -1) {
                    selectedElementsIdClone.push(id);
                } else {
                    selectedElementsIdClone.splice(index, 1);
                }
            } else if (!selectedElementsIdClone.some((selectedElementsId) => selectedElementsId === id)) {
                selectedElementsIdClone = [id];
            }

            // select elements
            setSelectedElementsId(selectedElementsIdClone);

            // update manipulator with the selected elements
            updateManipulator(selectedElementsIdClone, ctrlPressed || shiftPressed ? undefined : event);
        };

    /**
     * Handles the z-index change applied to elements
     *
     * @param {EnumSortDirection} sort - sort method
     */
    const handleElementSort = (sort: EnumSortDirection): void => {
        let index = -1;
        const elementToSort = props.actionStepProps.config.workInstructionElements.find((element) => element._id === selectedElementsId[0]);

        if (elementToSort) {
            switch (sort) {
                case EnumSortDirection.FRONT:
                    {
                        const zIndexList = props.actionStepProps.config.workInstructionElements.map(
                            (element: TElement): number => element.zIndex
                        );
                        const maxZIndex = zIndexList.length > 0 ? Math.max(...zIndexList) : 0;
                        if (elementToSort && elementToSort.zIndex !== maxZIndex) {
                            elementToSort.zIndex = maxZIndex + 1;
                        }
                    }
                    break;

                case EnumSortDirection.FORWARD:
                    index = props.actionStepProps.config.workInstructionElements.findIndex((element) =>
                        Boolean(elementToSort && elementToSort.zIndex + 1 === element.zIndex)
                    );
                    if (index !== -1) {
                        props.actionStepProps.config.workInstructionElements[index] = {
                            ...props.actionStepProps.config.workInstructionElements[index],
                            zIndex: props.actionStepProps.config.workInstructionElements[index].zIndex - 1,
                        };
                        elementToSort.zIndex = props.actionStepProps.config.workInstructionElements[index].zIndex + 1;
                    }
                    break;

                case EnumSortDirection.BACKWARD:
                    index = props.actionStepProps.config.workInstructionElements.findIndex((element) =>
                        Boolean(elementToSort && elementToSort.zIndex - 1 === element.zIndex)
                    );
                    if (index !== -1) {
                        props.actionStepProps.config.workInstructionElements[index] = {
                            ...props.actionStepProps.config.workInstructionElements[index],
                            zIndex: props.actionStepProps.config.workInstructionElements[index].zIndex + 1,
                        };
                        elementToSort.zIndex = props.actionStepProps.config.workInstructionElements[index].zIndex - 1;
                    }
                    break;

                case EnumSortDirection.BACK:
                    {
                        const zIndexList = props.actionStepProps.config.workInstructionElements.map((element) => element.zIndex);
                        const minZIndex = zIndexList.length > 0 ? Math.min(...zIndexList) : 0;
                        if (minZIndex !== elementToSort.zIndex) {
                            elementToSort.zIndex = minZIndex - 1;
                        }
                    }
                    break;
            }

            const updatedWorkInstructionElements = props.actionStepProps.config.workInstructionElements
                .map((element) => (elementToSort && elementToSort._id === element._id ? { ...elementToSort } : element))
                .sort((first, second) => first.zIndex - second.zIndex);

            // preemptively set elements state
            setWorkInstructionElements(updatedWorkInstructionElements);

            // propagate change
            updateElements(updatedWorkInstructionElements);
        }
    };

    /**
     * Applies manipulator's changes to selected element(s)
     *
     * @param {ITransformInfo} transformInfo - tranformation data
     */
    const handleElementTransform = (transformInfo: ITransformInfo): void => {
        if (!isForeground || elementManipulatorDisabled) {
            return void 0;
        }

        const nextWorkInstructionElements = [...workInstructionElements];
        for (const workInstructionElementId of selectedElementsId) {
            const element = nextWorkInstructionElements.find((element) => element._id === workInstructionElementId);

            if (element) {
                element.x += transformInfo.diffX;
                element.y += transformInfo.diffY;
                element.width = element.width + transformInfo.diffWidth;
                element.height = element.height + transformInfo.diffHeight;
                element.cropLeft = element.cropLeft + transformInfo.diffCropLeft;
                element.cropRight = element.cropRight + transformInfo.diffCropRight;
                element.cropTop = element.cropTop + transformInfo.diffCropTop;
                element.cropBottom = element.cropBottom + transformInfo.diffCropBottom;

                element.rotation += transformInfo.diffRotation;

                if (element.type === EnumElementType.LINE) {
                    if (transformInfo.transformType === EnumTransformType.TRANSLATE_ELEMENT) {
                        const lineProps = element.extendedProps as IWorkInstructionsLineConfig;

                        for (const tip of lineProps.arrowheads) {
                            tip.x += transformInfo.diffX;
                            tip.y += transformInfo.diffY;
                        }
                        formConfigHelpers.updateLineElementBounderies(
                            element as IWorkInstructionsElementConfig<IWorkInstructionsLineConfig>
                        );
                    } else if (transformInfo.transformType === EnumTransformType.TRANSLATE_POINT && transformInfo.diffPointIndex >= 0) {
                        const lineProps = element.extendedProps as IWorkInstructionsLineConfig;
                        lineProps.arrowheads[transformInfo.diffPointIndex].x += transformInfo.diffPointX;
                        lineProps.arrowheads[transformInfo.diffPointIndex].y += transformInfo.diffPointY;
                        formConfigHelpers.updateLineElementBounderies(
                            element as IWorkInstructionsElementConfig<IWorkInstructionsLineConfig>
                        );
                    }
                }

                if (element.type === EnumElementType.MARKER) {
                    if (transformInfo.transformType === EnumTransformType.TRANSLATE_POINT && transformInfo.diffPointIndex >= 0) {
                        const markerProps = element.extendedProps as IWorkInstructionsMarkerConfig;
                        markerProps.arrows[transformInfo.diffPointIndex].x += transformInfo.diffPointX;
                        markerProps.arrows[transformInfo.diffPointIndex].y += transformInfo.diffPointY;
                    }
                }

                if (element.type === EnumElementType.FORM || element.type === EnumElementType.VIDEO) {
                    if (transformInfo.transformType === EnumTransformType.SCALE) {
                        element.scale += transformInfo.diffScale;
                    }
                }
            }
        }

        updateManipulator(selectedElementsId);
        setWorkInstructionElements(nextWorkInstructionElements);

        // propagate change
        updateElements(nextWorkInstructionElements);
    };

    /**
     * Inserts an element in the editor
     *
     * @param {EnumElementType} type - type of the inserted element
     */
    const handleInsertElement = (type: EnumElementType): void => {
        let insertedElement: TElement | undefined;
        const zIndex = getMaxZIndex();

        switch (type) {
            case EnumElementType.IMAGE:
                insertedElement = new WorkInstructionsElementConfig<IWorkInstructionsImageConfig>(
                    type,
                    new WorkInstructionsImageConfig(),
                    zIndex
                );
                break;

            case EnumElementType.VIDEO:
                insertedElement = new WorkInstructionsElementConfig<IWorkInstructionsVideoProps>(
                    type,
                    new WorkInstructionsVideoConfig(),
                    zIndex
                );
                break;

            case EnumElementType.PDF:
                insertedElement = new WorkInstructionsElementConfig<IWorkInstructionsPDFConfig>(
                    type,
                    new WorkInstructionsPDFConfig(),
                    zIndex
                );
                break;

            case EnumElementType.TEXT:
                insertedElement = new WorkInstructionsElementConfig<IWorkInstructionsTextConfig>(
                    type,
                    new WorkInstructionsTextConfig(),
                    zIndex
                );
                break;

            case EnumElementType.LINE:
                insertedElement = new WorkInstructionsElementConfig<IWorkInstructionsLineConfig>(
                    type,
                    new WorkInstructionsLineConfig(),
                    zIndex
                );
                formConfigHelpers.updateLineElementBounderies(
                    insertedElement as IWorkInstructionsElementConfig<IWorkInstructionsLineConfig>
                );
                break;

            case EnumElementType.SHAPE:
                insertedElement = new WorkInstructionsElementConfig<IWorkInstructionsShapeConfig>(
                    type,
                    new WorkInstructionsShapeConfig(),
                    zIndex
                );
                break;

            case EnumElementType.MARKER:
                insertedElement = new WorkInstructionsElementConfig<IWorkInstructionsMarkerConfig>(
                    type,
                    new WorkInstructionsMarkerConfig(),
                    zIndex
                );
                break;

            case EnumElementType.FORM:
                insertedElement = new WorkInstructionsElementConfig<IWorkInstructionsFormConfig>(
                    type,
                    new WorkInstructionsFormConfig(),
                    zIndex
                );
                break;

            default:
                break;
        }

        if (insertedElement) {
            setElementEditorOpen(true);
            setEditedElement(insertedElement);
        }
    };

    /**
     * Saves an element
     */
    const saveElement = (element: TElement): void => {
        let elementsCopy: TElement[] = [...workInstructionElements];

        if (!elementsCopy.find((x: TElement): boolean => x._id === element._id)) {
            elementsCopy.push({ ...element });
        } else {
            elementsCopy = workInstructionElements.map((x: TElement): TElement => (x._id === element._id ? element : x));
        }

        props.onChanged({
            ...props.actionStepProps,
            config: {
                ...props.actionStepProps.config,
                workInstructionElements: elementsCopy,
            },
        });

        setElementEditorOpen(false);
    };

    /**
     * Function which handles the selection of a template
     *
     * @param {string} templateId - Selected template's ID
     * @param {object[]} templateElements - Selected template's Elements
     */
    const handleSelectTemplate = (templateId: string, templateElements: TElement[]): void => {
        setTemplateSelectorOpen(false);

        // Reasign new ids for every inserted ui Elements
        for (const element of templateElements) {
            element._id = shortid();
            element.templateId = templateId;
        }

        props.onChanged({
            ...props.actionStepProps,
            config: {
                ...props.actionStepProps.config,
                workInstructionElements: workInstructionElements
                    .filter((element: TElement): boolean => !element.templateId)
                    .concat(templateElements),
                templateId,
            },
        });
    };

    /**
     * Updates the canvas dimensions
     */
    const updateDimensions = (): void => {
        setMediaHeight(
            Math.min(window.innerHeight - ACTION_EDITOR_ELEMENTS_HEIGHT, (window.innerWidth - ACTION_EDITOR_ELEMENTS_WIDTH) / VIEW_RATIO)
        );
    };

    /**
     * Updates the manipulator props depdending on the number of selected elements
     *
     * @param {string[]} elementIds - selected elements' id list
     * @param {React.MouseEvent<SVGUseElement | SVGCircleElement | SVGRectElement, MouseEvent>} translationEvent - data from the mousedown event on a element
     */
    const updateManipulator = (
        elementIds: string[],
        translationEvent?: React.MouseEvent<SVGUseElement | SVGCircleElement | SVGRectElement, MouseEvent>
    ): void => {
        if (elementIds.length === 1) {
            const element = workInstructionElements.find((e: TElement): boolean => e._id === elementIds[0]);
            if (element) {
                setElementManipulatorProps({
                    ...initManipulatorProps,
                    x: element.x,
                    y: element.y,
                    width: element.width,
                    height: element.height,
                    cropLeft: element.cropLeft,
                    cropRight: element.cropRight,
                    cropTop: element.cropTop,
                    cropBottom: element.cropBottom,
                    rotation: element.rotation,
                    scale: element.scale,
                    points:
                        element.type === EnumElementType.LINE
                            ? (element.extendedProps as IWorkInstructionsLineConfig).arrowheads
                            : element.type === EnumElementType.MARKER
                            ? (element.extendedProps as IWorkInstructionsMarkerConfig).arrows
                            : [],
                    connectors:
                        element.type === EnumElementType.MARKER
                            ? (element.extendedProps as IWorkInstructionsMarkerConfig).arrows.map((arrow: ICoords): IConnector => {
                                  return {
                                      coordsPair: {
                                          x1: element.x + element.width / 2,
                                          y1: element.y + element.height / 2,
                                          x2: arrow.x,
                                          y2: arrow.y,
                                      },
                                  };
                              })
                            : [],
                    showResizeControl:
                        element.type !== EnumElementType.LINE &&
                        element.type !== EnumElementType.VIDEO &&
                        element.type !== EnumElementType.FORM,
                    showCornerResizeControl:
                        element.type !== EnumElementType.LINE &&
                        element.type !== EnumElementType.FORM &&
                        element.type !== EnumElementType.VIDEO,
                    showRotateControl:
                        element.type !== EnumElementType.LINE &&
                        element.type !== EnumElementType.VIDEO &&
                        element.type !== EnumElementType.FORM,
                    showScaleControl: element.type === EnumElementType.FORM || element.type === EnumElementType.VIDEO,
                    cropMode: element.type === EnumElementType.IMAGE,
                    origin:
                        element.type === EnumElementType.LINE
                            ? null
                            : { x: element.x + element.width / 2, y: element.y + element.height / 2 },
                    translationEvent,
                });
            }
        } else if (elementIds.length > 1) {
            let x = Number.MAX_VALUE;
            let y = Number.MAX_VALUE;
            let width = 0;
            let height = 0;

            for (const id of elementIds) {
                const element = workInstructionElements.find((e: TElement): boolean => e._id === id);

                if (element) {
                    const boundingBox = calcBoundingBox(element.x, element.y, element.width, element.height, element.rotation);
                    x = Math.min(boundingBox.x, x);
                    y = Math.min(boundingBox.y, y);
                }
            }

            for (const id of elementIds) {
                const element = workInstructionElements.find((e: TElement): boolean => e._id === id);

                if (element) {
                    const boundingBox = calcBoundingBox(element.x, element.y, element.width, element.height, element.rotation);
                    width = Math.max(boundingBox.x + boundingBox.width - element.cropLeft - element.cropRight - x, width);
                    height = Math.max(boundingBox.y + boundingBox.height - element.cropTop - element.cropBottom - y, height);
                }
            }

            setElementManipulatorProps({ ...initManipulatorProps, x, y, width, height, translationEvent });
        } else {
            setElementManipulatorProps(initManipulatorProps);
        }
    };

    /**
     * Handles the mousedown event on the svg canvas
     * Resets the selected elements array
     * Resets the 3-dots menu position
     * Saves mousedown coordinates for mouse drawing
     *
     * @param {React.MouseEvent<SVGSVGElement>} event - mouse event data
     */
    const handleSvgMouseDown = (event: React.MouseEvent<SVGSVGElement>): void => {
        setSelectedElementsId([]);
        setElementMenuPosition(undefined);
        setMouseDrawCoords({ x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY });
    };

    /**
     * Handles the mouseup event after drawing a zone in the svg canvas
     * Uses this zone to select any element inside or to insert a new element
     *
     * @param {ICoordsPair} coords - coordinates of the origin (x1,y1) and the final (x2,y2) positions of the drawn zone
     */
    const handleSvgMouseDrawCompleted = (coords: ICoordsPair): void => {
        const newElementSelection: string[] = [];
        const newCoords: ICoordsPair = {
            x1: Math.min(coords.x1, coords.x2),
            y1: Math.min(coords.y1, coords.y2),
            x2: Math.max(coords.x1, coords.x2),
            y2: Math.max(coords.y1, coords.y2),
        };

        // If no element type has been selected, use the zone as a multi-select box
        // Select any element inside the drawn zone
        for (const element of workInstructionElements) {
            const elementFinalHeight = (element.height - element.cropBottom - element.cropTop) * element.scale;
            const elementFinalWidth = (element.width - element.cropRight - element.cropLeft) * element.scale;
            if (
                newCoords.x1 <= element.x &&
                newCoords.x2 >= element.x + elementFinalWidth &&
                newCoords.y1 <= element.y &&
                newCoords.y2 >= element.y + elementFinalHeight
            ) {
                newElementSelection.push(element._id);
            }
        }

        setSelectedElementsId(newElementSelection);
        updateManipulator(newElementSelection);

        setMouseDrawCoords({ x: undefined, y: undefined });
    };

    const handleWorkInstructionsTrainingCommuniqueEditorClose = (): void => {
        setTrainingCommuniqueEditorOpen(false);
    };

    const handleWorkInstructionsTrainingCommuniqueEditorConfirm = (trainingCommunique: string): void => {
        setTrainingCommuniqueEditorOpen(false);

        if (trainingCommunique !== props.actionStepProps.config.trainingCommunique) {
            props.onChanged({
                ...props.actionStepProps,
                config: {
                    ...props.actionStepProps.config,
                    trainingCommunique,
                    trainingCommuniqueProcessVersion: "",
                },
            });
        }
    };

    return (
        <WorkInstructionsEditorContext.Provider value={{ elementMenuItems, setElementMenuItems, updateElement: saveElement }}>
            <div
                className={classes.root}
                style={
                    bom
                        ? { pointerEvents: readOnly ? "none" : undefined, gridColumnGap: "16px", gridTemplateColumns: "1fr 1fr" }
                        : { pointerEvents: readOnly ? "none" : undefined, justifyItems: "center" }
                }
                id="workInstructionsEditionContainerId"
            >
                <div className={classes.mediaViewbox}>
                    {/* TOOLBAR */}
                    <div
                        className={classes.toolbarContainer}
                        id="workInstructionsEditionToolbarId"
                        style={bom ? { width: mediaHeight * VIEW_RATIO } : {}}
                    >
                        <WorkInstructionsElementsToolbar
                            className={classes.elementsToolbar}
                            onClick={handleInsertElement}
                            userAccessLevel={userAccessLevel}
                        />
                        <WorkInstructionsDisplayModesToolbar
                            className={classes.displayModesToolbar}
                            onClick={handleDisplayModeChange}
                            trainingHighlighted={Boolean(actionStepProps.config.trainingCommunique)}
                            userAccessLevel={userAccessLevel}
                        />
                    </div>

                    {/* EDITION CANVAS */}
                    <Paper className={classes.svgContainer} style={{ height: mediaHeight, width: mediaHeight * VIEW_RATIO }}>
                        <svg
                            ref={svgCanvasRef}
                            height={normalizeSVGDimension(mediaHeight)}
                            width={normalizeSVGDimension(mediaHeight * VIEW_RATIO)}
                            id="workInstructionsEditionCanvasId"
                            viewBox={`0 0 ${VIEW_BOX_WIDTH} ${VIEW_BOX_HEIGHT}`}
                            xmlns="http://www.w3.org/2000/svg"
                            onMouseDown={handleSvgMouseDown}
                        >
                            {/* ELEMENTS */}
                            {workInstructionElements
                                .sort((a, b) => a.zIndex - b.zIndex)
                                .map((elementProps, index): JSX.Element => {
                                    const elementState: WorkInstructionsStepState = {
                                        _id: "",
                                        formItemState: [],
                                        textItemState: { text: "" },
                                        markerItemState: { text: "" },
                                    };
                                    return (
                                        <WorkInstructionsElement
                                            disabled={readOnly}
                                            elementState={elementState}
                                            elementProps={elementProps}
                                            key={index}
                                            onDoubleClick={handleElementDoubleClick}
                                            onMouseDown={handleElementMouseDown(elementProps._id)}
                                            selected={selectedElementsId.findIndex((id: string): boolean => id === elementProps._id) !== -1}
                                        />
                                    );
                                })}

                            {/* SVG Mouse Draw */}
                            {mouseDrawCoords.x && mouseDrawCoords.y && (
                                <SvgMouseDraw
                                    canvasRef={svgCanvasRef}
                                    canvasScale={VIEW_BOX_HEIGHT / mediaHeight}
                                    disabled={readOnly}
                                    onDrawCompleted={handleSvgMouseDrawCompleted}
                                    initialX={mouseDrawCoords.x}
                                    initialY={mouseDrawCoords.y}
                                />
                            )}

                            {/* ELEMENT MANIPULATOR */}
                            <DraggableElementManipulator
                                {...elementManipulatorProps}
                                eleRef={elementManipulatorRef}
                                canvasScale={VIEW_BOX_HEIGHT / mediaHeight}
                                onTransformCompleted={handleElementTransform}
                                disabled={elementManipulatorDisabled}
                            />
                        </svg>

                        {/* THREE DOTS MENU */}
                        <WorkInstructionsElementMenu
                            onDelete={handleDeleteSelectedElements}
                            onEdit={handleElementEdit}
                            onSort={handleElementSort}
                            position={elementMenuPosition}
                        />
                    </Paper>
                </div>
                {/* BOM */}
                {bom && (
                    <WorkInstructionsBomProvider>
                        <WorkInstructionsBom />
                    </WorkInstructionsBomProvider>
                )}

                {/* ELEMENT EDITORS */}
                {editedElement && displayElementEditor(editedElement)}

                {/* TRAINING COMMUNIQUE EDITOR */}
                <WorkInstructionsTrainingCommuniqueEditor
                    communique={actionStepProps.config.trainingCommunique}
                    disabled={readOnly}
                    onCancel={handleWorkInstructionsTrainingCommuniqueEditorClose}
                    onConfirm={handleWorkInstructionsTrainingCommuniqueEditorConfirm}
                    open={trainingCommuniqueEditorOpen}
                />

                {/* TEMPLATE EDITOR */}
                <WorkInstructionsTemplateEditor
                    onCancel={handleCancelTemplate}
                    onConfirm={handleCreateTemplate}
                    open={templateEditorOpen}
                />

                {/* TEMPLATE SELECTOR */}
                <WorkInstructionsTemplateSelector
                    onCancel={handleCloseTemplateSelector}
                    onSelect={handleSelectTemplate}
                    open={templateSelectorOpen}
                    stepTemplateId={actionStepProps && actionStepProps.config.templateId && actionStepProps.config.templateId}
                />
            </div>
        </WorkInstructionsEditorContext.Provider>
    );
}
