import { IWoBomItem } from "@kortex/aos-common";
import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import { PlayerControlsBomItemRow } from "./row";

const useStyles = makeStyles({
    root: {}, // To overwrite with props
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    item: IWoBomItem;
    index: number;
}

const PlayerControlsBomItem: FC<IOwnProps> = (props) => {
    const { classes, item, index } = props;

    return <PlayerControlsBomItemRow classes={classes} item={item} key={`playerControlsBomItemRow${index}`} index={index} />;
};

export default PlayerControlsBomItem;
