import { IKortexSpeedDialProps, KortexSpeedDial, KortexTextField, greyPalette, theme } from "@aos/react-components";
import {
    ComparisonOperator,
    ConditionBaseConfig,
    ConditionMode,
    LogicalOperator,
    ProcessActionStepLoop,
    ProcessVariableStorer,
    ProcessVariableStoringMethod,
    ProcessVariableType
} from "@kortex/aos-common";
import { Checkbox, FormControlLabel, IconButton, Menu, MenuItem, Paper, Table, TableCell, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";
import { useState } from "react";

import VariablePicker from "../../../../../../components/core/VariablePicker/VariablePicker";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { userCanWrite } from "../../../../../../utilitites/IUserRights";
import LogicalAndIcon from "../../../../../core/Icons/LogicalAnd/LogicalAnd";
import LogicalOrIcon from "../../../../../core/Icons/LogicalOr/LogicalOr";
import { IActionStepProps } from "../IActionStepProps";

import ConditionRowExpert from "./ConditionRowExpert";
import ConditionRowSimplified from "./ConditionRowSimplified";
import { getConditionsPreview } from "./Helpers";

const useStyles = makeStyles({
    sectionHeader: {
        padding: "15px",
    },
    loopListContainer: {
        height: "calc(100vh - 346px)", // Header (75px), margins (32px), loop preview textfield (89px) + config (150px)
        overflowY: "auto",
        width: "100%",
    },
    loopConfigContainer: {
        height: "150px", // Header (75px), margins (32px), loop preview textfield (89px)
        overflowY: "auto",
        width: "100%",
    },
    checkAlwaysOnce: {
        paddingLeft: "15px",
        paddingTop: "10px",
    },
    loopConfigs: {
        padding: "10px",
    },
    loopList: {
        overflowY: "auto",
    },
    loopRow: {
        marginRight: "16px",
    },
    logicalOperatorSelect: {
        width: "100%",
    },
    menuIcon: {
        alignSelf: "center",
        height: "48px",
        width: "48px",
    },
    root: {
        height: "100%",
        display: "grid",
        justifyItems: "center",
    },
    speedDial: {
        position: "fixed",
        bottom: "32px",
        right: "48px",
    },
    speedDialActionFab: {
        width: "35px",
        height: "35px",
        boxShadow: "0px 4px 5px 0px",
        backgroundColor: theme.palette.grey[300],
    },
    speedDialTooltipLabel: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey[50],
    },
    previewBoxContainer: {
        width: "100%",
    },
    previewBox: {
        width: "90%",
        margin: "16px",
    },
    tableCellComparison: {
        width: "150px",
    },
    tableCellLogical: {
        width: "75px",
    },
    tableCellMenuIcon: {
        width: "48px",
    },
    tableCellOperand: {
        width: "350px",
    },
    tableCellType: {
        width: "100px",
    },
    tableHeader: {
        backgroundColor: greyPalette[200],
    },
});

const INPUT_DELAY_MS = 500;

type IAllProps = IActionStepProps<ProcessActionStepLoop>;

function LoopEditor(props: IAllProps): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    const { actionStepProps, onChanged, userAccessLevel } = props;
    const { incrementVariable, maxLoopCount, alwaysDoOnce, conditions, resetVariable } = actionStepProps.config;

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const [loopMenuAnchorEl, setLoopMenuAnchorEl] = useState<HTMLElement | undefined>(undefined);

    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Edit the loop state and update the parent component
     *
     * @param {number} index - index of updated loop
     */
    const handleConditionChange =
        (index: number): ((updatedLoop: ConditionBaseConfig) => void) =>
        (updatedLoop: ConditionBaseConfig): void => {
            const conditionsCopy = [...conditions];
            conditionsCopy[index] = updatedLoop;

            onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    conditions: conditionsCopy,
                },
            });
        };

    /**
     * Deletes a loop row
     */
    const handleDeleteRow = (): void => {
        const conditionsCopy = [...conditions];

        conditionsCopy.splice(selectedRowIndex, 1);
        conditionsCopy[conditionsCopy.length - 1].logicalOperator = LogicalOperator.NA;

        setSelectedRowIndex(-1);

        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                conditions: conditionsCopy,
            },
        });
    };

    /**
     * Close the 3dots menu
     */
    const handleLoopMenuClose = (): void => {
        setLoopMenuAnchorEl(undefined);
    };

    /**
     * Opens a menu when the 3dots icon is clicked
     *
     * @param {number} index - loop's row index
     */
    const handleMenuIconClick =
        (index: number): ((event: React.MouseEvent<HTMLElement>) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            setSelectedRowIndex(index);
            setLoopMenuAnchorEl(event.currentTarget);
        };

    /**
     * Handles logical operator change
     *
     * @param {number} index - index the updated logical operator
     */
    const handleLogicalOperatorChange =
        (index: number): ((event: React.ChangeEvent<HTMLSelectElement>) => void) =>
        (event: React.ChangeEvent<HTMLSelectElement>): void => {
            const conditionsCopy = [...conditions];
            conditionsCopy[index].logicalOperator = event.target.value as LogicalOperator;

            onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    conditions: conditionsCopy,
                },
            });
        };

    /**
     * Called when clicking on a speed dial action
     * Add a loop row with the selected logical operator
     *
     * @param {LogicalOperator} action - selected logical operator
     */
    const handleSpeedDialActionClick =
        (action: LogicalOperator): (() => void) =>
        (): void => {
            const conditionsCopy = [...conditions];

            conditionsCopy[conditions.length - 1].logicalOperator = action;
            conditionsCopy.push(new ConditionBaseConfig());

            onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    conditions: conditionsCopy,
                },
            });
        };

    /*
     * Handles the change of mode (simplified or expert)
     * Also resets all loop properties
     */
    const handleToggleMode = (): void => {
        if (conditions[selectedRowIndex].mode === ConditionMode.SIMPLIFIED) {
            conditions[selectedRowIndex].mode = ConditionMode.EXPERT;
        } else {
            conditions[selectedRowIndex].mode = ConditionMode.SIMPLIFIED;
        }

        resetCondition(selectedRowIndex);
        setSelectedRowIndex(-1);
    };

    /**
     * Reset all loop properties to default values
     *
     * @param {number} index - loop's row index
     */
    const resetCondition = (index: number): void => {
        const conditionsCopy = [...conditions];

        conditionsCopy[index].expert = "";
        conditionsCopy[index].simplified = {
            type: ProcessVariableType.STRING,
            leftOperand: "",
            comparisonOperator: ComparisonOperator.EQUALS,
            rightOperand: "",
        };

        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                conditions: conditionsCopy,
            },
        });
    };

    /**
     * Called when incrementValue textfield is changed
     *
     * @param {string} identifier - textfield value
     * @param {ProcessVariableStoringMethod} storingMethod - overwrite or append
     */
    const handleIncrementVariableChange = (identifier: string, storingMethod?: ProcessVariableStoringMethod): void => {
        const method = storingMethod ? storingMethod : incrementVariable.storingMethod;
        if (method !== incrementVariable.storingMethod || identifier !== incrementVariable.identifier) {
            onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    incrementVariable: new ProcessVariableStorer(identifier, storingMethod),
                },
            });
        }
    };

    /**
     * Called when incrementValue checkbox is changed
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - unused event
     * @param {boolean} checked - checkbox check status
     */
    const handleAlwaysDoOnceCheckedChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                alwaysDoOnce: checked,
            },
        });
    };

    /**
     * Called when reset variable checkbox is changed
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - unused event
     * @param {boolean} checked - checkbox check status
     */
    const handleResetVariableCheckedChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                resetVariable: checked,
            },
        });
    };

    /**
     * Max loop count change handler, change from the user
     *
     * @param { number } value - new max loop counts
     */
    const handleMaxCountChange = (value: number): void => {
        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                maxLoopCount: value,
            },
        });
    };

    // Defines the addActions given to SpeedDial
    const addActions: Pick<IKortexSpeedDialProps, "actions"> = {
        actions: [
            {
                callback: handleSpeedDialActionClick(LogicalOperator.AND),
                icon: <LogicalAndIcon />,
                label: translate("action.condition.logical.and"),
                classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
            },
            {
                callback: handleSpeedDialActionClick(LogicalOperator.OR),
                icon: <LogicalOrIcon />,
                label: translate("action.condition.logical.or"),
                classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
            },
        ],
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.loopConfigContainer}>
                <Typography className={classes.sectionHeader} variant="h6">
                    {translate("action.loop.configurations")}
                </Typography>
                <div className={classes.loopConfigs}>
                    {/* LOOP CONFIGURATION */}
                    <KortexTextField
                        label={translate("action.loop.maxLoopCount")}
                        value={maxLoopCount}
                        onChanged={handleMaxCountChange}
                        TextFieldProps={{
                            autoFocus: true,
                            required: true,
                            disabled: readOnly,
                        }}
                        changedDelayMS={INPUT_DELAY_MS}
                    />
                    <VariablePicker
                        onChange={handleIncrementVariableChange}
                        storingMethod={incrementVariable.storingMethod}
                        KortexTextFieldProps={{
                            label: translate("action.loop.incrementVariable"),
                        }}
                        userAccessLevel={userAccessLevel}
                        value={incrementVariable.identifier}
                        variableTypeFilter={ProcessVariableType.NUMBER}
                    />

                    <FormControlLabel
                        className={classes.checkAlwaysOnce}
                        control={<Checkbox checked={alwaysDoOnce} onChange={handleAlwaysDoOnceCheckedChange} />}
                        label={translate("action.loop.alwaysDoOnce")}
                        disabled={readOnly}
                    />
                    <FormControlLabel
                        className={classes.checkAlwaysOnce}
                        control={<Checkbox checked={resetVariable} onChange={handleResetVariableCheckedChange} />}
                        label={translate("action.loop.resetVariable")}
                        disabled={readOnly}
                    />
                </div>
            </Paper>
            <Paper className={classes.loopListContainer}>
                <Typography className={classes.sectionHeader} variant="h6">
                    {translate("action.loop.conditions")}
                </Typography>
                {/* CONDITION TABLE */}
                <Table>
                    {/* LOGICAL OPERATOR */}
                    {conditions.map(
                        (condition: ConditionBaseConfig, index: number): JSX.Element => (
                            <TableRow key={index}>
                                <TableCell className={classes.tableCellLogical}>
                                    {index !== 0 && (
                                        <KortexTextField
                                            className={classes.logicalOperatorSelect}
                                            label={translate("action.condition.logical")}
                                            onChange={handleLogicalOperatorChange(index - 1)}
                                            TextFieldProps={{
                                                disabled: readOnly,
                                                select: true,
                                            }}
                                            value={conditions[index - 1].logicalOperator}
                                            variant="standard"
                                        >
                                            <MenuItem value={LogicalOperator.AND}>{translate("action.condition.logical.and")}</MenuItem>
                                            <MenuItem value={LogicalOperator.OR}>{translate("action.condition.logical.or")}</MenuItem>
                                        </KortexTextField>
                                    )}
                                </TableCell>
                                {/* SIMPLIFIED CONDITION */}
                                {condition.mode === ConditionMode.SIMPLIFIED && (
                                    <ConditionRowSimplified
                                        condition={condition}
                                        onChange={handleConditionChange(index)}
                                        userAccessLevel={userAccessLevel}
                                    />
                                )}
                                {/* EXPERT CONDITION */}
                                {condition.mode === ConditionMode.EXPERT && (
                                    <ConditionRowExpert
                                        condition={condition}
                                        onChange={handleConditionChange(index)}
                                        userAccessLevel={userAccessLevel}
                                    />
                                )}
                                {/* MENU ICON */}
                                <TableCell className={classes.tableCellMenuIcon}>
                                    <IconButton className={classes.menuIcon} disabled={readOnly} onClick={handleMenuIconClick(index)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </Table>
            </Paper>
            {/* CONDITION PREVIEW */}
            <div className={classes.previewBoxContainer}>
                <KortexTextField
                    className={classes.previewBox}
                    label={translate("action.loop.preview")}
                    TextFieldProps={{
                        disabled: true,
                    }}
                    value={getConditionsPreview(conditions)}
                />
            </div>
            {/* SPEED DIAL */}
            {!readOnly && (
                <KortexSpeedDial ariaLabel="LoopSpeedDial" SpeedDialProps={{ className: classes.speedDial }} actions={addActions.actions} />
            )}
            {/* MENU */}
            <Menu anchorEl={loopMenuAnchorEl} onClose={handleLoopMenuClose} open={Boolean(loopMenuAnchorEl)}>
                <MenuItem onClick={handleToggleMode} onMouseDown={handleLoopMenuClose}>
                    {selectedRowIndex !== -1 &&
                        (conditions[selectedRowIndex].mode === ConditionMode.SIMPLIFIED
                            ? translate("action.condition.expert")
                            : translate("action.condition.simplified"))}
                </MenuItem>
                <MenuItem onClick={handleDeleteRow} onMouseDown={handleLoopMenuClose} disabled={conditions.length === 1}>
                    {translate("action.condition.delete")}
                </MenuItem>
            </Menu>
        </div>
    );
}

export default LoopEditor;
