import { IconButton, TableCell, TableRow, Typography, makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React from "react";

import { useProcessEditorContext } from "../../../../../context";

const useStyles = makeStyles({
    bomItemsHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    infoBomHeader: {
        display: "flex",
        textAlign: "right",
        margin: "0px 10px",
        justifyContent: "space-between",
    },
    textInfoBom: {
        margin: "0px 10px",
    },
});

interface IOwnProps {
    displayStepItems: boolean;
    expanded: boolean;
    onToggleExpand: () => void;
    title: string;
}

function WorkInstructionsBomItemsHeader(props: IOwnProps): JSX.Element {
    const { displayStepItems, expanded, onToggleExpand, title } = props;

    const classes = useStyles();
    const { bom } = useProcessEditorContext();

    return (
        <TableRow>
            <TableCell width={"5px"} height={"5px"}>
                <IconButton aria-label="expand row" size="small" onClick={onToggleExpand} id={"expandIconId"}>
                    {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            <TableCell align="left" className={classes.bomItemsHeader}>
                <Typography variant={"subtitle1"}>{title}</Typography>
                {!displayStepItems && (
                    <div className={classes.infoBomHeader}>
                        <Typography variant={"subtitle1"} className={classes.textInfoBom} id={"bomId"}>
                            {bom?.bomId}
                        </Typography>
                        <Typography
                            variant={"subtitle1"}
                            className={classes.textInfoBom}
                            id={"bomRevisionId"}
                        >{`rev: ${bom?.revision}`}</Typography>
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
}

export default WorkInstructionsBomItemsHeader;
