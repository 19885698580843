import { KortexTextField, theme } from "@aos/react-components";
import {
    getReleasedProcessVersions,
    ProcessApprovalStatusFilter,
    ProcessId,
    ProcessUiModel,
    RepositoryEditorRightsEnum,
    StorageEditorsRightsEnum,
    TreeNodeId,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { CircularProgress, makeStyles, MenuItem, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import { processVersionGetAll } from "../../../redux/process-manager/process-thunks-process";
import { useSelectorProcesses } from "../../../redux/selectors";
import { getProcessStatus, ProcessStatus } from "../../../utilitites/getProcessStatus";

import { StatusIcon } from "./statusIcon";
import { filterAndOrderVersions, getCurrentVersion } from "./utils";

const LATEST = "latest";

const useStyles = makeStyles({
    circularProgress: {
        height: "24px !important",
        width: "24px !important",
    },
    selectIconOutlined: {
        right: 0,
    },
    textFieldInput: {
        color: theme.palette.primary[500],
        cursor: "pointer",
        display: "flex",
        fontSize: "1.2rem",
        fontWeight: 400,
        lineHeight: "24px",
        padding: "0 24px 0 0",
        "&:hover": {
            backgroundColor: theme.palette.grey[300],
            borderRadius: "5px",
            cursor: "pointer",
        },
    },
    versionSelectNotchedOutline: {
        border: "0 !important",
    },
    versionTextAndIcons: {
        display: "flex",
        alignItems: "center",
    },
});

export interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    excludedProcessIds?: ProcessId[];
    latest?: boolean;
    onVersionChange?: (processId: ProcessUiModel["processId"], latest?: boolean, changedOnLoad?: boolean) => void;
    onVersionsLoaded?: () => void;
    processId?: ProcessId;
    readOnly?: boolean;
    showPurgeIcon?: boolean;
    supportLatest?: boolean;
    treeNodeId: TreeNodeId;
    userAccessLevel?: RepositoryEditorRightsEnum | StorageEditorsRightsEnum;
    variant?: ProcessApprovalStatusFilter;
}

export default function ProcessVersionPicker(props: IOwnProps): JSX.Element {
    const {
        excludedProcessIds,
        latest,
        onVersionChange,
        onVersionsLoaded,
        processId,
        readOnly,
        showPurgeIcon,
        supportLatest = false,
        treeNodeId,
        userAccessLevel,
        variant = ProcessApprovalStatusFilter.ALL,
    } = props;
    const classes = useStyles(props);
    const dispatch = useThunkDispatch();
    const processes = useSelectorProcesses();
    const translate = useTranslate();

    const [loading, setLoading] = useState(true);
    const [versionSelected, setVersionSelected] = useState<string | undefined>(!processId && latest ? LATEST : undefined);

    const versions = filterAndOrderVersions(
        processes.filter((process) => process.treeNodeId === treeNodeId),
        {
            excludedProcessIds,
            userAccessLevel,
            variant,
        }
    );

    /**
     * If there is no process in the store with designated tree node ID, fetch them from the database
     */
    useEffect(() => {
        if (treeNodeId) {
            setLoading(true);

            dispatch(processVersionGetAll(treeNodeId))
                .then(([...loadedVersions]) => {
                    const filteredLoadedVersions = filterAndOrderVersions(loadedVersions, {
                        excludedProcessIds,
                        userAccessLevel,
                        variant,
                    });
                    onVersionsLoaded?.();

                    let value: string;
                    let searchProcess;

                    if (filteredLoadedVersions.length === 0) {
                        value = "";
                    } else if (latest) {
                        value = LATEST;
                    } else {
                        if (processId) {
                            searchProcess = filteredLoadedVersions.find((process) => process.processId === processId);
                        } else if (userAccessLevel === RepositoryEditorRightsEnum.READ) {
                            searchProcess = filteredLoadedVersions.find((process) => getProcessStatus(process) === ProcessStatus.RELEASED);

                            if (!searchProcess) {
                                // no process release found, check approved
                                searchProcess = filteredLoadedVersions.find(
                                    (process) => getProcessStatus(process) === ProcessStatus.APPROVED
                                );

                                if (!searchProcess) {
                                    // no process approved found, check waiting approval
                                    searchProcess = filteredLoadedVersions.find(
                                        (process) => getProcessStatus(process) === ProcessStatus.WAITING_APPROVAL
                                    );

                                    if (!searchProcess) {
                                        // no process waiting approval found, set to draft by default
                                        searchProcess = filteredLoadedVersions[0];
                                    }
                                }
                            }
                        } else {
                            // set by default to draft or last version
                            if (variant === ProcessApprovalStatusFilter.APPROVED_AND_DEFAULT_RELEASED) {
                                // Look for the first released process by default
                                searchProcess = filteredLoadedVersions.find((process) => process.releasedOn && process.releasedOn > 0);

                                if (searchProcess?.retiredOn) {
                                    // Process does not exist or is retired
                                    // Instead, look for the first approved process
                                    searchProcess = filteredLoadedVersions.find(
                                        (process) => getProcessStatus(process) === ProcessStatus.APPROVED
                                    );
                                }
                            } else if (variant === ProcessApprovalStatusFilter.APPROVED_AND_NOT_RETIRED_RELEASED_SELECTED_BY_DEFAULT) {
                                searchProcess = filteredLoadedVersions.filter((pro) => pro.releasedOn && pro.releasedOn > 0)[0];
                            } else {
                                searchProcess = filteredLoadedVersions[0];
                            }
                        }

                        if (searchProcess) {
                            // set value with result searchProcess pr null
                            value = searchProcess.processId.toString() ?? "";
                        } else {
                            if (!userAccessLevel || userAccessLevel === RepositoryEditorRightsEnum.READ) {
                                // preselect version last version release or by default to draft or null
                                const versionReleased = getReleasedProcessVersions(filteredLoadedVersions).reverse();
                                const lastVersion = versionReleased.length
                                    ? filteredLoadedVersions.find((version) => version.processId === versionReleased[0].processId)
                                    : filteredLoadedVersions[0];
                                value = lastVersion
                                    ? lastVersion.processId.toString()
                                    : (value = filteredLoadedVersions[0].processId.toString() ?? "");
                            } else {
                                // set value by default to draft or null
                                value = filteredLoadedVersions[0].processId.toString() ?? "";
                            }
                        }
                    }

                    // @ts-expect-error - Make it look like an event
                    handleVersionChange({ target: { value } }, true);
                })
                .finally(() => setLoading(false));
        }
    }, [treeNodeId, processId]);

    /**
     * Called when the process version is changed
     *
     * @param {object} event - change event data
     */
    const handleVersionChange = (event: React.ChangeEvent<HTMLInputElement>, changedOnLoad = false): void => {
        const version = event.target.value;
        setVersionSelected(version);

        if (event.target.value === LATEST) {
            onVersionChange?.(versions[0].processId, true, changedOnLoad);
        } else if (latest !== undefined) {
            onVersionChange?.(parseInt(event.target.value), false, changedOnLoad);
        } else {
            onVersionChange?.(parseInt(event.target.value), undefined, changedOnLoad);
        }
    };

    /**
     * Renders the possible versions for the select input
     */
    const renderVersions = (): JSX.Element[] => {
        const elements = versions.map((version, index) => (
            <MenuItem key={index} value={version.processId}>
                <div className={classes.versionTextAndIcons}>
                    <StatusIcon process={version} showPurgeIcon={showPurgeIcon} />
                    {version.isDraft ? translate("processVersionPicker.draft") : version.version}
                </div>
            </MenuItem>
        ));

        if (supportLatest && elements.length) {
            elements.unshift(
                <MenuItem key={LATEST} value={LATEST}>
                    {translate("processVersionPicker.latest")}
                </MenuItem>
            );
        }

        return elements;
    };

    /**
     * Renders the selected version as text
     */
    const renderVersionReadOnly = (): JSX.Element => {
        const currentVersion = getCurrentVersion(versions, treeNodeId, processId, variant, latest, translate);

        return (
            <div className={classes.versionTextAndIcons}>
                {currentVersion.process ? <StatusIcon process={currentVersion.process} showPurgeIcon={showPurgeIcon} /> : null}
                <Typography variant="h6">{currentVersion.version}</Typography>
            </div>
        );
    };

    /**
     * Stop propagation from click event
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event - event
     */
    const stopClickPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        event.stopPropagation();
    };

    return (
        <>
            {loading ? (
                <CircularProgress className={classes.circularProgress} color="inherit" />
            ) : readOnly ? (
                <>{renderVersionReadOnly()}</>
            ) : (
                <KortexTextField
                    InputProps={{
                        classes: {
                            notchedOutline: classes.versionSelectNotchedOutline,
                        },
                        id: "versionSelectorId",
                    }}
                    onChange={handleVersionChange}
                    TextFieldProps={{
                        disabled: readOnly,
                        inputProps: {
                            className: classes.textFieldInput,
                        },
                        onClick: stopClickPropagation,
                        select: true,
                        SelectProps: {
                            classes: {
                                iconOutlined: classes.selectIconOutlined,
                            },
                        },
                    }}
                    value={versionSelected ?? ""}
                >
                    {renderVersions()}
                </KortexTextField>
            )}
        </>
    );
}
