import { IVersion } from "@kortex/aos-common";

export const version: IVersion = {
    major: 1,
    minor: 17,
    patch: 0,
    pre: "r",
    build: 7,
    meta: "",
};
