import { IconButton, TableCell, TableRow, Typography, makeStyles } from "@material-ui/core";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { FC } from "react";

const useStyles = makeStyles({
    info: {
        display: "flex",
        textAlign: "right",
        margin: "0px 10px",
        justifyContent: "space-between",
    },
    root: {
        // backgroundColor: theme.palette.grey[300],
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    typography: {
        margin: "0px 10px",
    },
});

interface IOwnProps {
    expanded: boolean;
    onToggleExpand: () => void;
    title: string;
}

const PlayerControlsBomItemsHeader: FC<IOwnProps> = (props) => {
    const { expanded, onToggleExpand, title } = props;

    const classes = useStyles();

    return (
        <TableRow className={classes.root}>
            <TableCell width={"5px"}>
                <IconButton aria-label="expand row" size="small" onClick={onToggleExpand} id="openBomItemsButtonId">
                    {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </IconButton>
            </TableCell>
            <TableCell>
                <div className={classes.title}>
                    <Typography variant="subtitle1">{title}</Typography>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default PlayerControlsBomItemsHeader;
