import { greyPalette, secondaryPalette, theme } from "@aos/react-components";
import { areSimilar, IGetAllJobProcessesRes, IOperatorJobProcessesFiltersOptions, IOperatorJobProcessInfo } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { jobProcessGetAllActiveJobProcesses } from "@kortex/aos-ui/redux/work-scheduler-manager/work-schedule-thunks-job";
import { CircularProgress, makeStyles, Paper, Typography } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { InView } from "react-intersection-observer"; // TODO: Replace InView by useInView

import { useTranslate } from "../../../hooks/useTranslate";
import { useEntitiesJobProcessesOperators } from "../../../redux/effects";
import PlayerDialog from "../../core/ProcessPlayer/PlayerDialog";
import { useSnackbar } from "../../layout/snackbarConfigurator";
import SchedulerOperatorSearchBar, { defaultFilters, IFilters } from "../SchedulerOperator/SchedulerOperatorSearchBar";

import SchedulerOperatorJobProcessCard from "./SchedulerOperatorJobProcessCard";
import { IProcessIsReadyToBePlayed, processIsReadyToBePlayed } from "./utilites/processIsReadyToBePlayed";
import { convertUiFiltersToBackendFilters, verifyIfFiltersAreEmpty } from "./utilites/utilities";

const useStyles = makeStyles({
    mainContainer: {
        height: "calc(100% - 16px)",
    },
    pageContainer: {
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        height: "calc(100vh - 64px)",
        padding: "16px",
    },
    cardSpacer: {
        flexGrow: 2,
    },
    cardFooter: {
        backgroundColor: greyPalette[200],
        color: greyPalette[600],
        borderTop: "solid 1px",
        borderTopColor: greyPalette[400],
        flexFlow: "row",
        flexDirection: "row",
        display: "flex",
        padding: "0px",
    },
    menuItem: {
        width: "68px",
    },
    processPage: {
        height: "calc(100vh - 203px)", // header (64px), page paddings (32px), search bar height (77px) and bottom margin (10px), job list paddings (20px)
        justifyItems: "center",
        padding: "10px",
        overflow: "auto",
    },
    endBox: {
        width: "100%",
        minHeight: "5px",
        borderBottom: "1px",
        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
        borderTop: "0px",
    },
    searchContainer: {
        justifyItems: "center",
        marginBottom: "10px",
        padding: "10px",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 1,
    },
    containerListFeedback: {
        width: "100%",
        height: "150px",
        marginTop: "10px",
    },
    loadingUserFeedback: {
        color: secondaryPalette[500],
        textAlign: "center",
    },
    intersectionObserver: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
    },
});

const JOB_REQUEST_LIMIT = 20;
const NUMBER_OF_ITEM_BEFORE_NEXT_FETCH = JOB_REQUEST_LIMIT / 4;

export default function SchedulerOperatorPage(): JSX.Element {
    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const snackbar = useSnackbar();

    const jobProcessList = useEntitiesJobProcessesOperators(
        (value) => downloadedEndCallback && downloadedEndCallback(value),
        JOB_REQUEST_LIMIT,
        0,
        {
            optimizedQuery: true,
        }
    );

    const translate = useTranslate();
    const [filteredOperatorJobProcessList, setFilteredOperatorJobProcessList] = useState<IOperatorJobProcessInfo[] | undefined>([]);
    const [selectedJobProcessInfo, setSelectedJobProcessInfo] = useState<IOperatorJobProcessInfo | undefined>();
    const [playerDialogOpened, setPlayerDialogOpened] = useState<boolean>(false);
    const [filters, setFilters] = useState<IFilters>(defaultFilters);
    const [isPlayLoading, setIsPlayLoading] = useState(false);
    const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
    const [hasNext, setHasNext] = useState<boolean>(true);
    const [fetchOffset, setFetchOffset] = useState<number>(0);

    /**
     * Effect to trigger build up of jobsList
     */
    useEffect((): void => {
        setFilteredOperatorJobProcessList(jobProcessList);

        if (selectedJobProcessInfo) {
            const selected = jobProcessList.find(
                (jobProcessInfo): boolean =>
                    jobProcessInfo.jobProcessId === (selectedJobProcessInfo.jobProcessId ? selectedJobProcessInfo.jobProcessId : 0)
            );
            setSelectedJobProcessInfo(selected);
        }
    }, [jobProcessList]);

    useEffect(() => {
        if (isPlayLoading) {
            // Check if the process can be played
            if (selectedJobProcessInfo) {
                processIsReadyToBePlayed(selectedJobProcessInfo.processId, selectedJobProcessInfo.purgeVersion, dispatch, translate).then(
                    (process: IProcessIsReadyToBePlayed) => {
                        setPlayerDialogOpened(process.readyToBePlayed);
                        if (!process.readyToBePlayed) {
                            snackbar.warning(process.messageError);
                        }
                        setIsPlayLoading(false);
                    }
                );
            }
        }
    }, [isPlayLoading]);

    /**
     * close the runner dialog
     */
    const openPlayerDialog = (): void => {
        setIsPlayLoading(true);
    };

    /**
     * close the runner dialog
     */
    const closePlayerDialog = (): void => {
        setPlayerDialogOpened(false);
    };

    /**
     * Handle called when the user select process within a job
     *
     * @param {IOperatorJobProcessInfo} processInfo - Selected process
     */
    const handleSelectProcess = (processInfo: IOperatorJobProcessInfo): void => {
        if (processInfo.jobProcessId !== selectedJobProcessInfo?.jobProcessId) {
            setSelectedJobProcessInfo(processInfo);
        }
    };

    /**
     * Called when the filters change. Sets local filters state
     *
     * @param {IFilters} newFilters - newly set filters
     */
    const handleFiltersChange = (newFilters: IFilters): void => {
        // verify if filters has changed
        if (areSimilar(filters, newFilters)) return;

        setDataIsLoading(true);
        setFilteredOperatorJobProcessList([]);
        getFilteredJobsFromBackEnd(newFilters);
        setFilters(newFilters);
    };

    const getFilteredJobsFromBackEnd = (newFilters: IFilters): void => {
        // Restart at 0 Offset the query
        setHasNext(true);
        getJobsListPagination(JOB_REQUEST_LIMIT, 0, convertUiFiltersToBackendFilters(newFilters));
    };

    const fetchNextJobsList = (): void => {
        setDataIsLoading(true);
        getJobsListPagination(JOB_REQUEST_LIMIT, fetchOffset, convertUiFiltersToBackendFilters(filters));
    };

    const getJobsListPagination = (
        JOB_REQUEST_LIMIT: number,
        jobRequestOffset: number,
        filterOptions: IOperatorJobProcessesFiltersOptions
    ): void => {
        dispatch(jobProcessGetAllActiveJobProcesses(JOB_REQUEST_LIMIT, jobRequestOffset, filterOptions)).then(
            (value) => downloadedEndCallback && downloadedEndCallback(value)
        );
    };

    const downloadedEndCallback = (value: Readonly<IGetAllJobProcessesRes>): void => {
        // Compute has next?
        setHasNext((value.chunkSize as number) >= JOB_REQUEST_LIMIT);
        setFetchOffset(value.nextCursor as number);

        // Verify is there is no value returned
        if (value.chunkSize === 0 && value.prevCursor === 0) {
            setFilteredOperatorJobProcessList([]);
        }

        setDataIsLoading(false);
    };

    const handleIntersectionObserverOnChange = (inView: boolean): void => {
        if (!inView) {
            return;
        }
        if (jobProcessList.length > 0) {
            fetchNextJobsList();
        }
    };

    /**
     * Display "No job found if filters are used. Otherwise, return "Add new Job."
     */
    const displayListFeedback = (): JSX.Element => {
        return (
            <div className={classes.containerListFeedback}>
                <Typography className={classes.loadingUserFeedback} variant="body1">
                    {dataIsLoading && (
                        <>
                            {translate("scheduler.LoadingJobs")}
                            <br></br>
                            <CircularProgress color="secondary" />
                        </>
                    )}
                    {!dataIsLoading &&
                        filteredOperatorJobProcessList &&
                        filteredOperatorJobProcessList.length == 0 &&
                        translate(verifyIfFiltersAreEmpty(filters) ? "scheduler.AddNewJobSuggestion" : "scheduler.NoJobFound")}
                    {!dataIsLoading &&
                        filteredOperatorJobProcessList &&
                        filteredOperatorJobProcessList.length > JOB_REQUEST_LIMIT &&
                        translate(hasNext ? "scheduler.ScrollToLoadMoreItems" : "scheduler.NoMoreJobs")}
                </Typography>
            </div>
        );
    };

    const observerJobProcessId =
        filteredOperatorJobProcessList && filteredOperatorJobProcessList.length > NUMBER_OF_ITEM_BEFORE_NEXT_FETCH
            ? filteredOperatorJobProcessList[filteredOperatorJobProcessList.length - NUMBER_OF_ITEM_BEFORE_NEXT_FETCH - 1].jobProcessId
            : undefined;

    return (
        <>
            <div className={classes.mainContainer} id="SchedulerOperatorPageId">
                <div className={classes.pageContainer}>
                    <Paper className={classes.searchContainer}>
                        <SchedulerOperatorSearchBar onFiltersChange={handleFiltersChange} disabled={playerDialogOpened} />
                    </Paper>

                    <Paper className={classes.processPage}>
                        {/* JOB PROCESS LIST */}
                        {filteredOperatorJobProcessList &&
                            filteredOperatorJobProcessList.map((jobProcessInfo, index): JSX.Element => {
                                return (
                                    <React.Fragment key={jobProcessInfo.jobProcessId}>
                                        <SchedulerOperatorJobProcessCard
                                            index={index}
                                            isSelected={jobProcessInfo.jobProcessId === selectedJobProcessInfo?.jobProcessId}
                                            jobProcessInfo={jobProcessInfo}
                                            onSelection={handleSelectProcess}
                                            onPlay={openPlayerDialog}
                                        />
                                        {jobProcessInfo.jobProcessId === observerJobProcessId && !dataIsLoading && hasNext && (
                                            <InView
                                                as="div"
                                                onChange={handleIntersectionObserverOnChange}
                                                className={classes.intersectionObserver}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        {displayListFeedback()}
                    </Paper>
                    {/* END SPACER*/}
                    <div className={classes.cardSpacer} />
                    {/* BOTTOM MENU */}
                    {selectedJobProcessInfo && selectedJobProcessInfo.job && selectedJobProcessInfo && (
                        <PlayerDialog
                            processId={selectedJobProcessInfo.processId}
                            dryRunMode={false}
                            testRunMode={false}
                            reworkId={selectedJobProcessInfo.reworkId}
                            onClose={closePlayerDialog}
                            jobProcessInfo={selectedJobProcessInfo}
                            open={Boolean(selectedJobProcessInfo) && playerDialogOpened}
                            title={selectedJobProcessInfo.process.treeNodeProcess.label}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
