export var AccessLevelEnum;
(function (AccessLevelEnum) {
    AccessLevelEnum[AccessLevelEnum["NONE"] = 0] = "NONE";
    AccessLevelEnum[AccessLevelEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    AccessLevelEnum[AccessLevelEnum["READ"] = 20] = "READ";
    AccessLevelEnum[AccessLevelEnum["WRITE"] = 30] = "WRITE";
    AccessLevelEnum[AccessLevelEnum["INSERT"] = 40] = "INSERT";
    AccessLevelEnum[AccessLevelEnum["ARCHIVE"] = 50] = "ARCHIVE";
})(AccessLevelEnum || (AccessLevelEnum = {}));
/**
 * Return the Right Level Key to be used to translate
 */
export function getRightLevelLabelKey(level, yesNoType) {
    if (yesNoType) {
        switch (level) {
            case AccessLevelEnum.NONE:
                return "general.no";
            case AccessLevelEnum.AUTHENTICATED:
                return "general.no";
            case AccessLevelEnum.WRITE:
                return "general.yes";
            default:
                return "general.na";
        }
    }
    else {
        switch (level) {
            case AccessLevelEnum.NONE:
                return "accessLevel.none";
            case AccessLevelEnum.AUTHENTICATED:
                return "accessLevel.none";
            case AccessLevelEnum.READ:
                return "accessLevel.read";
            case AccessLevelEnum.WRITE:
                return "accessLevel.write";
            case AccessLevelEnum.INSERT:
                return "accessLevel.insert";
            case AccessLevelEnum.ARCHIVE:
                return "accessLevel.archive";
            default:
                return "general.na";
        }
    }
}
export var SystemRightsEnum;
(function (SystemRightsEnum) {
    SystemRightsEnum[SystemRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    SystemRightsEnum[SystemRightsEnum["READ"] = 20] = "READ";
    SystemRightsEnum[SystemRightsEnum["WRITE"] = 30] = "WRITE";
})(SystemRightsEnum || (SystemRightsEnum = {}));
export var RepositoryEditorRightsEnum;
(function (RepositoryEditorRightsEnum) {
    RepositoryEditorRightsEnum[RepositoryEditorRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    RepositoryEditorRightsEnum[RepositoryEditorRightsEnum["READ"] = 20] = "READ";
    RepositoryEditorRightsEnum[RepositoryEditorRightsEnum["WRITE"] = 30] = "WRITE";
    RepositoryEditorRightsEnum[RepositoryEditorRightsEnum["INSERT"] = 40] = "INSERT";
    RepositoryEditorRightsEnum[RepositoryEditorRightsEnum["ARCHIVE"] = 50] = "ARCHIVE";
})(RepositoryEditorRightsEnum || (RepositoryEditorRightsEnum = {}));
export var ProcessEditorRightsEnum;
(function (ProcessEditorRightsEnum) {
    ProcessEditorRightsEnum[ProcessEditorRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    ProcessEditorRightsEnum[ProcessEditorRightsEnum["READ"] = 20] = "READ";
    ProcessEditorRightsEnum[ProcessEditorRightsEnum["WRITE"] = 30] = "WRITE";
    ProcessEditorRightsEnum[ProcessEditorRightsEnum["INSERT"] = 40] = "INSERT";
    ProcessEditorRightsEnum[ProcessEditorRightsEnum["ARCHIVE"] = 50] = "ARCHIVE";
})(ProcessEditorRightsEnum || (ProcessEditorRightsEnum = {}));
export var ProcessReleaseRightsEnum;
(function (ProcessReleaseRightsEnum) {
    ProcessReleaseRightsEnum[ProcessReleaseRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    ProcessReleaseRightsEnum[ProcessReleaseRightsEnum["WRITE"] = 30] = "WRITE";
})(ProcessReleaseRightsEnum || (ProcessReleaseRightsEnum = {}));
export var ProcessApprovalRightsEnum;
(function (ProcessApprovalRightsEnum) {
    ProcessApprovalRightsEnum[ProcessApprovalRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    ProcessApprovalRightsEnum[ProcessApprovalRightsEnum["READ"] = 20] = "READ";
    ProcessApprovalRightsEnum[ProcessApprovalRightsEnum["WRITE"] = 30] = "WRITE";
})(ProcessApprovalRightsEnum || (ProcessApprovalRightsEnum = {}));
export var ProcessPlayerRightsEnum;
(function (ProcessPlayerRightsEnum) {
    ProcessPlayerRightsEnum[ProcessPlayerRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    ProcessPlayerRightsEnum[ProcessPlayerRightsEnum["WRITE"] = 30] = "WRITE";
})(ProcessPlayerRightsEnum || (ProcessPlayerRightsEnum = {}));
export var RoleEditorRightsEnum;
(function (RoleEditorRightsEnum) {
    RoleEditorRightsEnum[RoleEditorRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    RoleEditorRightsEnum[RoleEditorRightsEnum["READ"] = 20] = "READ";
    RoleEditorRightsEnum[RoleEditorRightsEnum["WRITE"] = 30] = "WRITE";
    RoleEditorRightsEnum[RoleEditorRightsEnum["INSERT"] = 40] = "INSERT";
    RoleEditorRightsEnum[RoleEditorRightsEnum["ARCHIVE"] = 50] = "ARCHIVE";
})(RoleEditorRightsEnum || (RoleEditorRightsEnum = {}));
export var UserEditorRightsEnum;
(function (UserEditorRightsEnum) {
    UserEditorRightsEnum[UserEditorRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    UserEditorRightsEnum[UserEditorRightsEnum["READ"] = 20] = "READ";
    UserEditorRightsEnum[UserEditorRightsEnum["WRITE"] = 30] = "WRITE";
    UserEditorRightsEnum[UserEditorRightsEnum["INSERT"] = 40] = "INSERT";
    UserEditorRightsEnum[UserEditorRightsEnum["ARCHIVE"] = 50] = "ARCHIVE";
})(UserEditorRightsEnum || (UserEditorRightsEnum = {}));
export var GroupEditorRightsEnum;
(function (GroupEditorRightsEnum) {
    GroupEditorRightsEnum[GroupEditorRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    GroupEditorRightsEnum[GroupEditorRightsEnum["READ"] = 20] = "READ";
    GroupEditorRightsEnum[GroupEditorRightsEnum["WRITE"] = 30] = "WRITE";
    GroupEditorRightsEnum[GroupEditorRightsEnum["INSERT"] = 40] = "INSERT";
    GroupEditorRightsEnum[GroupEditorRightsEnum["ARCHIVE"] = 50] = "ARCHIVE";
})(GroupEditorRightsEnum || (GroupEditorRightsEnum = {}));
export var StorageEditorsRightsEnum;
(function (StorageEditorsRightsEnum) {
    StorageEditorsRightsEnum[StorageEditorsRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    StorageEditorsRightsEnum[StorageEditorsRightsEnum["READ"] = 20] = "READ";
    StorageEditorsRightsEnum[StorageEditorsRightsEnum["WRITE"] = 30] = "WRITE";
    StorageEditorsRightsEnum[StorageEditorsRightsEnum["INSERT"] = 40] = "INSERT";
    StorageEditorsRightsEnum[StorageEditorsRightsEnum["ARCHIVE"] = 50] = "ARCHIVE";
})(StorageEditorsRightsEnum || (StorageEditorsRightsEnum = {}));
export var ReportsRightsEnum;
(function (ReportsRightsEnum) {
    ReportsRightsEnum[ReportsRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    ReportsRightsEnum[ReportsRightsEnum["READ"] = 20] = "READ";
})(ReportsRightsEnum || (ReportsRightsEnum = {}));
export var SchedulerRightsEnum;
(function (SchedulerRightsEnum) {
    SchedulerRightsEnum[SchedulerRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    SchedulerRightsEnum[SchedulerRightsEnum["READ"] = 20] = "READ";
    SchedulerRightsEnum[SchedulerRightsEnum["WRITE"] = 30] = "WRITE";
    SchedulerRightsEnum[SchedulerRightsEnum["INSERT"] = 40] = "INSERT";
    SchedulerRightsEnum[SchedulerRightsEnum["ARCHIVE"] = 50] = "ARCHIVE";
})(SchedulerRightsEnum || (SchedulerRightsEnum = {}));
export var SchedulerOperatorRightsEnum;
(function (SchedulerOperatorRightsEnum) {
    SchedulerOperatorRightsEnum[SchedulerOperatorRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    SchedulerOperatorRightsEnum[SchedulerOperatorRightsEnum["READ"] = 20] = "READ";
})(SchedulerOperatorRightsEnum || (SchedulerOperatorRightsEnum = {}));
export var DashboardRightsEnum;
(function (DashboardRightsEnum) {
    DashboardRightsEnum[DashboardRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    DashboardRightsEnum[DashboardRightsEnum["READ"] = 20] = "READ";
    DashboardRightsEnum[DashboardRightsEnum["WRITE"] = 30] = "WRITE";
    DashboardRightsEnum[DashboardRightsEnum["INSERT"] = 40] = "INSERT";
    DashboardRightsEnum[DashboardRightsEnum["ARCHIVE"] = 50] = "ARCHIVE";
})(DashboardRightsEnum || (DashboardRightsEnum = {}));
export var ReworkRightsEnum;
(function (ReworkRightsEnum) {
    ReworkRightsEnum[ReworkRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    ReworkRightsEnum[ReworkRightsEnum["READ"] = 20] = "READ";
    ReworkRightsEnum[ReworkRightsEnum["WRITE"] = 30] = "WRITE";
    ReworkRightsEnum[ReworkRightsEnum["INSERT"] = 40] = "INSERT";
    ReworkRightsEnum[ReworkRightsEnum["ARCHIVE"] = 50] = "ARCHIVE";
})(ReworkRightsEnum || (ReworkRightsEnum = {}));
export var KanbanRightsEnum;
(function (KanbanRightsEnum) {
    KanbanRightsEnum[KanbanRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    KanbanRightsEnum[KanbanRightsEnum["INSERT"] = 40] = "INSERT";
})(KanbanRightsEnum || (KanbanRightsEnum = {}));
export var TrainingRightsEnum;
(function (TrainingRightsEnum) {
    TrainingRightsEnum[TrainingRightsEnum["AUTHENTICATED"] = 10] = "AUTHENTICATED";
    TrainingRightsEnum[TrainingRightsEnum["READ"] = 20] = "READ";
    TrainingRightsEnum[TrainingRightsEnum["WRITE"] = 30] = "WRITE";
    TrainingRightsEnum[TrainingRightsEnum["INSERT"] = 40] = "INSERT";
    TrainingRightsEnum[TrainingRightsEnum["ARCHIVE"] = 50] = "ARCHIVE";
})(TrainingRightsEnum || (TrainingRightsEnum = {}));
