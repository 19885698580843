export var EnumActionStatus;
(function (EnumActionStatus) {
    EnumActionStatus["COMPLETED"] = "C";
    EnumActionStatus["FAILED"] = "F";
    EnumActionStatus["IDLE"] = "I";
    EnumActionStatus["PLAYING"] = "P";
    EnumActionStatus["PAUSED"] = "PA";
    EnumActionStatus["TRAINEE_SIGNATURE_REQUIRED"] = "TE";
    EnumActionStatus["TRAINER_SIGNATURE_REQUIRED"] = "TR";
    EnumActionStatus["WAITING_USER_NEXT"] = "WN";
    EnumActionStatus["VALIDATION_REQUIRED"] = "V";
})(EnumActionStatus || (EnumActionStatus = {}));
// Width in pixels, can't put px in this case because of linting where it's used
export const ActionBlockSizeEnum = {
    CORNER_RADIUS: 4,
    DEFAULT_HEIGHT: 120,
    DEFAULT_WIDTH: 205,
    HEADER_HEIGHT: 40,
    HEIGHT_INCREMENT: 20,
    INPUT_OFFSET: 40,
    INPUT_OUTPUT_RADIUS: 7,
    OUTPUT_OFFSET: 195,
    SHADOW_OFFSET: 15,
    USER_DESCRIPTION_OFFSET: 55,
    RETURN_OFFSET: -20,
    GRID_RESOLUTION: 5,
};
