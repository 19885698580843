/*
 * ENUM
 * This enum is only used in the AOSError class
 * This enum is in this file and not the class to modify a single file when adding or updating an enum
 */
export var TitleKeyEnum;
(function (TitleKeyEnum) {
    TitleKeyEnum["APPROVAL_PROCESS_VERSION"] = "titleKeyError.approvalProcessVersion";
    TitleKeyEnum["APPROVAL_ROUTING_VERSION"] = "titleKeyError.approvalRoutingVersion";
    TitleKeyEnum["ASSERT_PROCESS_VERSION"] = "titleKeyError.assertProcessVersion";
    TitleKeyEnum["CANNOT_COPY_EMPTY_PAYLOAD"] = "titleKeyError.cannotCopyEmptyPayload";
    TitleKeyEnum["CONFIG_ERROR"] = "titleKeyError.configError";
    TitleKeyEnum["CONFIGURATION_ORGANIZATION_SETTINGS"] = "titleKeyError.configurationOrganizationSettings";
    TitleKeyEnum["DATA_BASE_ERROR"] = "titleKeyError.databaseCommunicationError";
    TitleKeyEnum["ERP_ERROR"] = "titleKeyError.erpError";
    TitleKeyEnum["EXECUTION_ERROR"] = "titleKeyError.executionError";
    TitleKeyEnum["HOLD_PROCESS_VERSION"] = "titleKeyError.holdProcessVersion";
    TitleKeyEnum["INVALID_ELECTRONIC_SIGNATURE"] = "titleKeyError.invalidElectronicSignature";
    TitleKeyEnum["INVALID_USER"] = "titleKeyError.invalidUser";
    TitleKeyEnum["INVALID_VARIABLE_IDENTIFIER"] = "titleKeyError.invalidVariableIdentifier";
    TitleKeyEnum["JOB_PROCESS_QTY_MAX_REACH"] = "titleKeyError.jobProcessQtyMaxReach";
    TitleKeyEnum["JOB_QTY_MAX_REACH"] = "titleKeyError.jobQtyMaxReach";
    TitleKeyEnum["NO_PROCESS_VERSION_AVAILABLE"] = "titleKeyError.noProcessVersionAvailable";
    TitleKeyEnum["NO_RUNNER_AVAILABLE"] = "titleKeyError.noRunnerAvailable";
    TitleKeyEnum["PROCESS_IS_DRAFT"] = "titleKeyError.processIsDraft";
    TitleKeyEnum["PROCESS_PRINT_ERROR"] = "titleKeyError.processPrintError";
    TitleKeyEnum["REQUEST_FAILED_RUNNER"] = "titleKeyError.requestFailedRunner";
    TitleKeyEnum["UNABLE_TO_PERFORM_YOUR_ACTION"] = "titleKeyError.unableToPerformYourAction";
    TitleKeyEnum["UNABLE_CANCEL_PROCESS_VERSION"] = "titleKeyError.unableCancelProcessVersion";
    TitleKeyEnum["UNABLE_COPY_PROCESS_ACTIONS"] = "titleKeyError.unableCopyProcessActions";
    TitleKeyEnum["UNABLE_CREATE_NEW_JOB"] = "titleKeyError.unableCreateNewJob";
    TitleKeyEnum["UNABLE_CREATE_NEW_COLUMN"] = "titleKeyError.unableCreateNewColumn";
    TitleKeyEnum["UNABLE_CREATE_NEW_TRAINING"] = "titleKeyError.unableCreateNewTraining";
    TitleKeyEnum["UNABLE_CREATE_PROCESS_VERSION"] = "titleKeyError.unableCreateProcessVersion";
    TitleKeyEnum["UNABLE_CREATE_REWORK_JOB_AND_NEW_FAILURE_TICKET"] = "titleKeyError.unableCreateReworkJobAndNewFailureTicket";
    TitleKeyEnum["UNABLE_CREATE_TRAINING_CERTIFICATE"] = "titleKeyError.unableCreateTrainingCertificate";
    TitleKeyEnum["UNABLE_CREATE_TRAINING_CERTIFICATION"] = "titleKeyError.unableCreateTrainingCertification";
    TitleKeyEnum["UNABLE_CREATE_TRAINING_READING_CERTIFICATE"] = "titleKeyError.unableCreateTrainingReadingCertificate";
    TitleKeyEnum["UNABLE_GET_ERP_SETTING"] = "titleKeyError.unableGetErpSettings";
    TitleKeyEnum["UNABLE_SIGN_TRAINING_CERTIFICATE"] = "titleKeyError.unableSignTrainingCertificate";
    TitleKeyEnum["UNABLE_SIGN_TRAINING_CERTIFICATION"] = "titleKeyError.unableSignTrainingCertification";
    TitleKeyEnum["UNABLE_HOLD_PROCESS_VERSION"] = "titleKeyError.unableHoldProcessVersion";
    TitleKeyEnum["UNABLE_INSERT_ROUTING"] = "titleKeyError.unableInsertRouting";
    TitleKeyEnum["UNABLE_INSERT_USER"] = "titleKeyError.unableInsertUser";
    TitleKeyEnum["UNABLE_UPDATE_PASSWORD"] = "titleKeyError.unableUpdatePassword";
    TitleKeyEnum["UNABLE_INSERT_TREE_FILE"] = "titleKeyError.unableInsertTreeFile";
    TitleKeyEnum["UNABLE_UPDATE_JOB"] = "titleKeyError.unableUpdateJob";
    TitleKeyEnum["UNABLE_UPDATE_PROCESS"] = "titleKeyError.unableUpdateProcess";
    TitleKeyEnum["UNABLE_UPDATE_ROUTING"] = "titleKeyError.unableUpdateRouting";
    TitleKeyEnum["UNABLE_UPDATE_TREE_FILE"] = "titleKeyError.unableUpdateTreeFile";
    TitleKeyEnum["UNABLE_INSERT_LOG"] = "titleKeyError.unableInsertLog";
    TitleKeyEnum["UNABLE_INSERT_USER_ROLE"] = "titleKeyError.unableInsertUserRole";
    TitleKeyEnum["UNABLE_INSERT_PROCESS_FILE"] = "titleKeyError.unableInsertProcessFile";
    TitleKeyEnum["UNABLE_UPDATE_USER_ROLE"] = "titleKeyError.unableUpdateUserRole";
    TitleKeyEnum["UNABLE_DELETE_USER_ROLE"] = "titleKeyError.unableDeleteUserRole";
    TitleKeyEnum["UNABLE_USER_CONNECTION"] = "titleKeyError.unableUserConnection";
    TitleKeyEnum["UNHANDLED_ERROR"] = "titleKeyError.unhandledError";
    TitleKeyEnum["UNKNOWN_ERROR"] = "titleKeyError.unknownError";
    TitleKeyEnum["ACCESS_UNAUTHORIZED"] = "titleKeyError.accessUnauthorized";
})(TitleKeyEnum || (TitleKeyEnum = {}));
