import React, { useState } from "react";

import WorkInstructionsBomItemsTable from "../BomItems/Table/WorkInstructionsBomItemsTable";

import WorkInstructionsBomItemsHeader from "./Header/WorkInstructionsBomItemsHeader";

interface IOwnProps {
    displayStepItems?: boolean;
    expandedInitialValue?: boolean;
    title: string;
}

function WorkInstructionsBomItems(props: IOwnProps): JSX.Element {
    const { displayStepItems = false, expandedInitialValue = false, title } = props;

    const [expanded, setExpanded] = useState(expandedInitialValue);

    /**
     *
     */
    const handleToggleExpand = (): void => {
        setExpanded((prevState) => !prevState);
    };

    return (
        <React.Fragment>
            <WorkInstructionsBomItemsHeader
                displayStepItems={displayStepItems}
                expanded={expanded}
                onToggleExpand={handleToggleExpand}
                title={title}
            />
            <WorkInstructionsBomItemsTable displayStepItems={displayStepItems} expanded={expanded} />
        </React.Fragment>
    );
}

export default WorkInstructionsBomItems;
