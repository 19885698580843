import * as RoleTypes from "../interfaces/models/users/UserRoleTypes";
import { isTextValid, trimText } from "../utilities/utilities";
/**
 * Verify if user role is valid
 *
 * @param {IUserRoleDbModel} role - User role to be verified
 */
export function isUserRoleValid(role) {
    return isUserRoleNameValid(role);
}
/**
 * Verify if user role name is valid
 *
 * @param {IUserRoleDbModel} role - User role to be verified
 */
export function isUserRoleNameValid(role) {
    role.name = trimText(role.name, "");
    return isTextValid(role.name, 1, 50);
}
/**
 * Generate a new user role with default values
 */
export function newUserRole() {
    return {
        userRoleId: 0,
        name: "",
        isAdmin: false,
        system: RoleTypes.SystemRightsEnum.AUTHENTICATED,
        repositoryEditor: RoleTypes.RepositoryEditorRightsEnum.READ,
        processEditor: RoleTypes.ProcessEditorRightsEnum.READ,
        processRelease: RoleTypes.ProcessReleaseRightsEnum.AUTHENTICATED,
        processApproval: RoleTypes.ProcessApprovalRightsEnum.AUTHENTICATED,
        processPlayer: RoleTypes.ProcessPlayerRightsEnum.AUTHENTICATED,
        userEditor: RoleTypes.UserEditorRightsEnum.AUTHENTICATED,
        roleEditor: RoleTypes.RoleEditorRightsEnum.AUTHENTICATED,
        reports: RoleTypes.ReportsRightsEnum.AUTHENTICATED,
        storageEditor: RoleTypes.StorageEditorsRightsEnum.AUTHENTICATED,
        groupEditor: RoleTypes.GroupEditorRightsEnum.AUTHENTICATED,
        scheduler: RoleTypes.SchedulerRightsEnum.AUTHENTICATED,
        schedulerOperator: RoleTypes.SchedulerOperatorRightsEnum.AUTHENTICATED,
        dashboard: RoleTypes.DashboardRightsEnum.AUTHENTICATED,
        rework: RoleTypes.ReworkRightsEnum.AUTHENTICATED,
        kanban: RoleTypes.KanbanRightsEnum.AUTHENTICATED,
        training: RoleTypes.TrainingRightsEnum.AUTHENTICATED,
    };
}
/**
 * Generate the admin role
 */
export function getUserRoleAdmin() {
    return {
        ...newUserRole(),
        dashboard: RoleTypes.DashboardRightsEnum.ARCHIVE,
        groupEditor: RoleTypes.GroupEditorRightsEnum.ARCHIVE,
        isAdmin: true,
        kanban: RoleTypes.KanbanRightsEnum.AUTHENTICATED,
        name: "admin",
        processApproval: RoleTypes.ProcessApprovalRightsEnum.WRITE,
        processEditor: RoleTypes.ProcessEditorRightsEnum.ARCHIVE,
        processPlayer: RoleTypes.ProcessPlayerRightsEnum.WRITE,
        processRelease: RoleTypes.ProcessReleaseRightsEnum.WRITE,
        reports: RoleTypes.ReportsRightsEnum.READ,
        repositoryEditor: RoleTypes.RepositoryEditorRightsEnum.ARCHIVE,
        rework: RoleTypes.ReworkRightsEnum.ARCHIVE,
        roleEditor: RoleTypes.RoleEditorRightsEnum.ARCHIVE,
        scheduler: RoleTypes.SchedulerRightsEnum.ARCHIVE,
        schedulerOperator: RoleTypes.SchedulerOperatorRightsEnum.READ,
        storageEditor: RoleTypes.StorageEditorsRightsEnum.ARCHIVE,
        system: RoleTypes.SystemRightsEnum.WRITE,
        training: RoleTypes.TrainingRightsEnum.ARCHIVE,
        userEditor: RoleTypes.UserEditorRightsEnum.ARCHIVE,
    };
}
