import { EnumProcessStatus } from "./IProcessState";
export class ProcessState {
    constructor() {
        this.dryRunMode = false;
        this.endTime = 0;
        this.isCompleted = false;
        this.isStopped = false;
        this.jobProcessId = 0;
        this.pauseDuration = 0;
        this.stopDuration = 0;
        this.reworkId = 0;
        this.runnerId = "";
        this.serialNumber = "";
        this.serialNumberInstances = [];
        this.startTime = 0;
        this.status = EnumProcessStatus.IDLE;
        this.timers = [];
        this.trackingNumber = "";
        this.userId = 0;
        this.isTestRun = false;
        this.batchEnable = false;
        this.batchNew = true;
        this.batchQty = 1;
        this.failQty = 0;
        this.activateReworkProcessOnFail = true;
        this.inFailPath = false;
        this.qty = {
            total: 0,
            started: 0,
            passed: 0,
            failed: 0,
        };
        this.currentStepIndex = -1;
        this.reworkNewItemStatusId = 0;
        this.reworkComment = "";
        this.resumeTimeHistory = 0;
        this.instancesBeingResumed = "";
        this.resultProcessHistoryIdBeingResumed = 0;
        this.isResumeOfAnFailure = false;
        this.resultMasterId = 0;
        this.training = {
            certified: null,
            electronicSignatureId: null,
            electronicSignatureRequired: null,
            required: null,
            trained: null,
            trainer: {
                electronicSignatureId: null,
                required: null,
            },
            trainingCommuniqueRequired: null,
            trainingId: null,
        };
    }
}
