import { KortexTextField } from "@aos/react-components";
import { OrUndefined } from "@kortex/aos-api-client";
import { IBomFollowUp, IWoBomItem } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { bomInsertFollowUp } from "@kortex/aos-ui/redux/bom-manager/bom-thunks";
import { IconButton, Menu, PopoverPosition, TableCell, TableRow, makeStyles } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { FC, useState } from "react";

import { usePlayerContext } from "../../../../../../../../../core/ProcessPlayer/context";
import ItemCardOfTooltipPlayer from "../../../../utilities/ItemCardOfTooltipPlayer";

import { PlayerControlsBomItemRowMenuMultipleTraceability, PlayerControlsBomItemRowMenuReplacement } from "./menu";
import { PlayerControlsBomItemRowMenuEdit } from "./menu/edit";
import { PlayerControlsBomItemRowMenuOverconsumption } from "./menu/overconsumption";

const useStyles = makeStyles({
    root: {}, // To overwrite with props
    allContent: {
        display: "grid",
    },
    content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    rowDetails: {
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "15% 65% 20%",
    },
    traceability: {
        display: "flex",
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    item: IWoBomItem;
    index: number;
}

const PlayerControlsBomItemRow: FC<IOwnProps> = (props) => {
    const { item, index } = props;

    const classes = useStyles(props);
    const dispatch = useThunkDispatch();
    const { bom, bomStepItems, jobProcessInfo, playerState } = usePlayerContext();

    const [menuPosition, setMenuPosition] = useState<PopoverPosition | null>(null);
    const [followUpLineSelected, setFollowUpLineSelected] = useState<OrUndefined<IBomFollowUp>>(undefined);

    const processActionStepId = playerState.uiActionProps?.stepProps.processActionStepId;
    const isOptionsButtonDisabled =
        !Boolean(bom) || !Boolean(jobProcessInfo) || processActionStepId === undefined || !item.followUp[processActionStepId];
    const quantityMax = bomStepItems[item.partNumber]?.quantity ?? item.quantity;

    /**
     * Handle close menu and reset line selected
     */
    const handleMenuClose = (): void => {
        setMenuPosition(null);
        setFollowUpLineSelected(undefined);
    };

    /**
     * Handles on click menu button
     */
    const handleMoreClick =
        (followUp: OrUndefined<IBomFollowUp>): ((event: React.MouseEvent<HTMLElement>) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            setFollowUpLineSelected(followUp);
            setMenuPosition({
                left: event.clientX,
                top: event.clientY,
            });
        };

    /**
     * Handles traceability change
     **/
    const handleTraceabilityChange = async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
        const newValue = event.target.value;

        if (!newValue.trim().length) return void 0;

        dispatch(
            bomInsertFollowUp({
                jobRefId: jobProcessInfo!.job.jobRefId,
                partNumber: item.partNumber,
                processActionStepId,
                quantity: quantityMax,
                traceability: newValue,
                trackingId: playerState.processState.serialNumber,
                trackingInstances: playerState.processState.serialNumberInstances.toString(),
            })
        );
    };

    const renderRowDetail = (bomFollowUpLine: IBomFollowUp, indexRowDetail: number): JSX.Element => {
        return (
            <div className={classes.rowDetails} key={indexRowDetail}>
                {/* QUANTITY */}
                <TableCell align="left" id={`quantity${indexRowDetail}Id`}>
                    {bomFollowUpLine.quantityOver > 0
                        ? `+ ${bomFollowUpLine.quantityOver}`
                        : `${bomFollowUpLine.quantity ?? ""}/ ${quantityMax}`}
                </TableCell>
                {/* TRACEABILITY */}
                <TableCell align="center" className={classes.traceability}>
                    <KortexTextField
                        TextFieldProps={{
                            id: `traceabilityInput${indexRowDetail}Id`,
                            disabled: true,
                            placeholder: item.lotSerialType,
                        }}
                        value={bomFollowUpLine.traceability}
                        variant="standard"
                    />
                </TableCell>
                {/* OPTIONS BUTTON */}
                <TableCell align="right">
                    <IconButton
                        disabled={isOptionsButtonDisabled}
                        id={`playerControlsBomItemMore${indexRowDetail}Id`}
                        onClick={handleMoreClick(bomFollowUpLine)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </TableCell>
            </div>
        );
    };

    const renderRows = (): JSX.Element => {
        let quantityAccumulator = 0;
        const elements: JSX.Element[] = [];
        if (processActionStepId && item.followUp[processActionStepId]) {
            for (const [index, line] of item.followUp[processActionStepId].entries()) {
                quantityAccumulator += line.quantity;
                if (quantityAccumulator > quantityMax && line.quantityOver === 0 && !line.quantity) {
                    break;
                }
                elements.push(renderRowDetail(line, index));
            }
        } else {
            elements.push(
                <div className={classes.rowDetails} key={`playerControlsBomItemRowEmptyRow${elements.length}`}>
                    {/* QUANTITY */}
                    <TableCell align="center" id={`quantity${index}Id`}>
                        {quantityMax}
                    </TableCell>
                    {/* TRACEABILITY */}
                    <TableCell align="center" className={classes.traceability}>
                        {item.lotSerialType.length > 0 && item.lotSerialType !== "NONE" && (
                            <KortexTextField
                                TextFieldProps={{
                                    id: `traceabilityInput${index}Id`,
                                    placeholder: item.lotSerialType,
                                }}
                                onBlur={handleTraceabilityChange}
                                value={""}
                                variant="standard"
                            />
                        )}
                    </TableCell>
                    {/* OPTIONS BUTTON */}
                    <TableCell align="center">
                        <IconButton
                            disabled={isOptionsButtonDisabled}
                            id={`playerControlsBomItemMore${index}Id`}
                            onClick={handleMoreClick(followUpLineSelected)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </TableCell>
                </div>
            );
        }

        return <div className={classes.allContent}>{elements}</div>;
    };

    return (
        <>
            <TableRow className={classes.root} key={item.partNumber}>
                {/* BUBBLE NUMBER */}
                <TableCell component="th" scope="row">
                    {item.bubbleNumber}
                </TableCell>
                {/* PART NUMBER */}
                <ItemCardOfTooltipPlayer item={item} />

                {renderRows()}
            </TableRow>
            {/* OPTIONS MENU */}
            {!isOptionsButtonDisabled && followUpLineSelected ? (
                <Menu
                    anchorPosition={menuPosition ?? undefined}
                    anchorReference="anchorPosition"
                    open={menuPosition !== null}
                    onClose={handleMenuClose}
                    id="itemStepMenuId"
                >
                    <PlayerControlsBomItemRowMenuEdit
                        followUp={followUpLineSelected}
                        closeMenu={handleMenuClose}
                        key={"playerControlsBomItemRowMenuEdit"}
                    />
                    <PlayerControlsBomItemRowMenuMultipleTraceability
                        closeMenu={handleMenuClose}
                        followUp={followUpLineSelected}
                        key={"playerControlsBomItemRowMenuMultipleTraceability"}
                    />
                    <PlayerControlsBomItemRowMenuReplacement
                        followUp={followUpLineSelected}
                        closeMenu={handleMenuClose}
                        key={"playerControlsBomItemRowMenuReplacement"}
                    />
                    <PlayerControlsBomItemRowMenuOverconsumption
                        followUp={followUpLineSelected}
                        closeMenu={handleMenuClose}
                        key={"playerControlsBomItemRowMenuOverconsumption"}
                    />
                </Menu>
            ) : null}
        </>
    );
};

export default PlayerControlsBomItemRow;
