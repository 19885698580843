import { theme, warningPalette } from "@aos/react-components";
import {
    DAY_TO_HOUR,
    EnumProcessStatus,
    HOUR_TO_MIN,
    IProcessUiModel,
    MIN_TO_SEC,
    ProcessApprovalStatusFilter,
    SEC_TO_MS,
    getTime,
} from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { Drawer, Tooltip, makeStyles } from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Debug from "debug";
import React, { FC, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesSettingOrganizations } from "../../../../../redux/effects";
import { IPlayerState } from "../../../../../redux/player-manager/player-types";
import ChangeLogs from "../../../../pages/ProcessEditor/ProcessEditor/ChangeLogs";
import ProcessIcon from "../../../Icons/Process/Process";
import ProcessVersionPicker from "../../../ProcessVersionPicker/ProcessVersionPicker";

const debug = Debug("kortex:ui:player-page");
debug("Loaded");
Debug.enable("kortex:ui:player-page");

const useStyles = makeStyles({
    stepContainerHeader: {
        height: "60px",
        borderBottom: `2px solid ${theme.palette.grey[300]}`,
        color: theme.palette.primary[500],
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 5px",
    },
    feedbackIcon: {
        color: theme.palette.primary[500],
        cursor: "pointer",
        width: "40px",
        height: "40px",
        marginTop: "5px",
    },
    feedbackIconNew: {
        color: warningPalette[900],
        cursor: "pointer",
        width: "40px",
        height: "40px",
        marginTop: "5px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

interface IOwnProps {
    // Own Props
    dryRunMode?: boolean;
    playerState: IPlayerState; // the actual player state
    processWithActions: IProcessUiModel | null;
    onVersionChange: (processId: number) => void;
    showPurgeIcon?: boolean;
}

const PlayerControlsHeader: FC<IOwnProps> = (props) => {
    const { dryRunMode, playerState, processWithActions, showPurgeIcon, onVersionChange } = props;

    const classes = useStyles();

    // FIXME: waiting for "current" to be implemented
    const orgInfo = useEntitiesSettingOrganizations()[0];
    const translate = useTranslate();

    const [changeLogsOpen, setChangeLogsOpen] = useState<boolean>(false);

    useStopKeybindPropagation(changeLogsOpen, { important: true });

    const versionPickerReadOnly = !(
        dryRunMode &&
        playerState.processState &&
        playerState.processState.status !== EnumProcessStatus.PLAYING
    );

    /*
     * Called when the process version is changed
     *
     * @param {number} processId - ID of the selected process version
     */
    const handleProcessVersionChange = (processId: number): void => {
        onVersionChange(processId);
    };

    /**
     * handle show change log
     */
    const handleShowChangeLog = (): void => {
        setChangeLogsOpen(true);
    };

    /**
     * handle change log drawer close
     */
    const handleCloseChangeLogs = (): void => {
        setChangeLogsOpen(false);
    };

    return (
        <div className={classes.stepContainerHeader}>
            <ProcessIcon />
            <ProcessVersionPicker
                latest={false}
                onVersionChange={handleProcessVersionChange}
                processId={processWithActions ? processWithActions.processId : undefined}
                readOnly={versionPickerReadOnly}
                treeNodeId={(processWithActions && processWithActions.treeNodeId) ?? 0}
                showPurgeIcon={showPurgeIcon}
                variant={ProcessApprovalStatusFilter.ALL}
            />
            <div>
                {processWithActions && orgInfo && (
                    <>
                        <Tooltip classes={{ tooltip: classes.tooltip }} title={translate("player.changeLog")} placement="top">
                            <ListAltIcon
                                id="newReleasedIconId"
                                className={
                                    getTime() - (processWithActions.releasedOn ?? 0) <=
                                    orgInfo.processVersionWarningDays * DAY_TO_HOUR * HOUR_TO_MIN * MIN_TO_SEC * SEC_TO_MS
                                        ? classes.feedbackIconNew
                                        : classes.feedbackIcon
                                }
                                onClick={handleShowChangeLog}
                            />
                        </Tooltip>
                        <Drawer anchor="right" open={changeLogsOpen} onClose={handleCloseChangeLogs}>
                            <ChangeLogs onClose={handleCloseChangeLogs} process={processWithActions} readOnly={true} />
                        </Drawer>
                    </>
                )}
            </div>
        </div>
    );
};

export default PlayerControlsHeader;
