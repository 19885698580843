import { TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";

import { useTranslate } from "../../../../../../../../../../hooks/useTranslate";

function WorkInstructionsBomItemsTableHeader(): JSX.Element {
    const translate = useTranslate();

    return (
        <React.Fragment>
            <TableHead>
                <TableRow>
                    <TableCell width={"5px"}>{translate("action.stepEditor.itemBubbleNumber")}</TableCell>
                    <TableCell>{translate("action.stepEditor.itemPartNumber")}</TableCell>
                    <TableCell width={"5px"}></TableCell>
                    <TableCell align="center" width={"10%"}>
                        {translate("action.stepEditor.quantityItemBom")}
                    </TableCell>
                    <TableCell />
                    <TableCell />
                </TableRow>
            </TableHead>
        </React.Fragment>
    );
}

export default WorkInstructionsBomItemsTableHeader;
