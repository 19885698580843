import { OrUndefined } from "@kortex/aos-api-client";
import { IOperatorJobProcessInfo, IProcessUiModel, IWoBomTrackedFull, ProcessId, WoBomItems } from "@kortex/aos-common";
import { initialState } from "@kortex/aos-ui/redux/player-manager/player-reducer";
import { IPlayerState } from "@kortex/aos-ui/redux/player-manager/player-types";
import { useSelectorBoms, useSelectorPlayer, useSelectorProcesses } from "@kortex/aos-ui/redux/selectors";
import React, { FC, PropsWithChildren, createContext, useContext } from "react";

interface IPlayerContext {
    bom: IWoBomTrackedFull | undefined;
    bomStepItems: WoBomItems;
    playerState: IPlayerState;
    process: IProcessUiModel | null;
    jobProcessInfo: OrUndefined<IOperatorJobProcessInfo>;
}

export const PlayerContext = createContext<IPlayerContext>({
    bom: {
        items: {},
        jobRefId: "",
        processId: 0,
        trackingId: "",
        treeNodeId: 0,
    },
    bomStepItems: {},
    playerState: initialState,
    process: null,
    jobProcessInfo: undefined,
});

type PlayerProviderProps = PropsWithChildren<{
    processId: ProcessId;
    jobProcessInfo?: IOperatorJobProcessInfo;
}>;

export const PlayerProvider: FC<PlayerProviderProps> = (props) => {
    const { children, processId, jobProcessInfo } = props;

    // const dispatch = useThunkDispatch();

    const bom: IWoBomTrackedFull | undefined = useSelectorBoms()[0];
    const playerState = useSelectorPlayer();
    const process: IProcessUiModel | undefined = useSelectorProcesses((p) => p.processId === processId)[0];

    // Reset BOM when process is completed
    // TODO: Reactivate this feature when we will start working on BOM
    // useEffect(() => {
    //     if (playerState.processState.status === EnumProcessStatus.COMPLETED || playerState.processState.isCompleted) {
    //         dispatch(bomClearBoms());
    //     }
    // }, [playerState.processState.status, playerState.processState.isCompleted]);

    /**
     * Get the items' follow-up when a serial number is received
     */
    // TODO: Reactivate this feature when we will start working on BOM
    // useEffect(() => {
    //     if (process && jobProcessInfo && playerState.processState.serialNumber.length > 0) {
    //         dispatch(
    //             bomGetWoBom(jobProcessInfo.job.jobRefId, playerState.processState.serialNumber, process.processId, process.treeNodeId)
    //         );
    //     }
    // }, [playerState.processState.serialNumber]);

    /**
     * Get the BOM items for current step
     */
    // TODO: Reactivate this feature when we will start working on BOM
    // const getStepBomItems = (): WoBomItems => {
    //     // Initialize an object to store BOM items for this step
    //     const items: WoBomItems = {};

    //     // Check if the BOM is valid and if the current action is a work instruction
    //     if (!bom || playerState.uiActionProps?.baseProps.type !== "core-work-instructions") return items;

    //     // Iterate through the BOM items for this step
    //     const step = playerState.uiActionProps.stepProps as ProcessActionStepWorkInstructions;

    //     for (const item of Object.values(step.config.bomItems)) {
    //         // Create an entry in bomByStep with updated follow-up information
    //         items[item.partNumber] = {
    //             ...bom.items[item.partNumber],
    //             quantity: item.quantity,
    //         };
    //     }

    //     return items;
    // };

    return (
        <PlayerContext.Provider
            value={{
                bom,
                bomStepItems: {}, // getStepBomItems(),
                jobProcessInfo,
                playerState,
                process,
            }}
        >
            {children}
        </PlayerContext.Provider>
    );
};

export const usePlayerContext = (): IPlayerContext => useContext<IPlayerContext>(PlayerContext);
