import {
    BomFollowUpEditItemReq,
    BomFollowUpEditItemRes,
    BomFollowUpInsertReq,
    BomFollowUpInsertRes,
    BomFollowUpMultipleTraceabilitiesItemReq,
    BomFollowUpMultipleTraceabilitiesItemRes,
    BomFollowUpOverconsumeItemReq,
    BomFollowUpOverconsumeItemRes,
    BomFollowUpReplaceItemReq,
    BomFollowUpReplaceItemRes,
    ErpGetBomByTrackingIdRes,
    ErpGetWoBomRes,
    IBomFollowUp,
    ProcessId,
    TrackingId,
    TreeNodeId,
    UnwrapAOSPayload,
} from "@kortex/aos-common";

import { handleAPIError } from "../handleAPIError";
import { processUpdatedAction } from "../process-manager/process-actions";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { treeProcessUpdatedAction } from "../tree-manager/tree-actions";

import {
    bomItemEditedAction,
    bomItemInsertedAction,
    bomItemMultipleTraceabilitiesAction,
    bomItemOverconsumedAction,
    bomItemReplacedAction,
    bomSetAction,
    bomsClearAction,
} from "./bom-actions";

/**
 * Clear BOMs from state
 *
 */
export function bomClearBoms(): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(bomsClearAction());
    };
}

/**
 * Get WO BOM
 */
export function bomGetWoBom(
    jobRefId: IBomFollowUp["jobRefId"],
    trackingId: IBomFollowUp["trackingId"],
    processId: ProcessId = 0,
    treeNodeId: TreeNodeId = 0
): StandardThunk<UnwrapAOSPayload<ErpGetWoBomRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ErpGetWoBomRes> | undefined> {
        return api.services.erp
            .getWoBom({ jobRefId, trackingId })({ timeout: 30_000 /* 30 seconds */ })
            .then((res) => {
                // Set bom list
                dispatch(
                    bomSetAction([
                        {
                            items: res.items,
                            processId,
                            treeNodeId,
                            jobRefId: res.jobRefId,
                            trackingId: res.trackingId,
                        },
                    ])
                );

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Get boms by tracking ID
 */
export function bomGetByTrackingId(trackingId: TrackingId): StandardThunk<UnwrapAOSPayload<ErpGetBomByTrackingIdRes>> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ErpGetBomByTrackingIdRes>> {
        return api.services.erp
            .getBomByTrackingId({ trackingId })({ timeout: 30_000 /* 30 seconds */ })
            .then((res) => {
                // Set bom list
                dispatch(bomSetAction(res.boms.map((b) => b.bom)));

                // Add tree nodes and processes
                for (const bom of res.boms) {
                    dispatch(treeProcessUpdatedAction([bom.treeNode]));
                    dispatch(processUpdatedAction(bom.process));
                }

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return { boms: [] };
            });
    };
}

/**
 * Edit a BOM item
 */
export function bomEditItem(
    followUp: UnwrapAOSPayload<BomFollowUpEditItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpEditItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpEditItemRes> | undefined> {
        return api.services.bomFollowUp
            .editItem(followUp)()
            .then((res) => {
                dispatch(bomItemEditedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Create a BOM follow-up
 */
export function bomInsertFollowUp(
    followUp: UnwrapAOSPayload<BomFollowUpInsertReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpInsertRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpInsertRes> | undefined> {
        return api.services.bomFollowUp
            .insert(followUp)()
            .then((res) => {
                dispatch(bomItemInsertedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Overconsume a BOM item
 */
export function bomOverconsumeItem(
    followUp: UnwrapAOSPayload<BomFollowUpOverconsumeItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpOverconsumeItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpOverconsumeItemRes> | undefined> {
        return api.services.bomFollowUp
            .overconsumeItem(followUp)()
            .then((res) => {
                dispatch(bomItemOverconsumedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Replace a BOM item
 */
export function bomReplaceItem(
    followUp: UnwrapAOSPayload<BomFollowUpReplaceItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpReplaceItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpReplaceItemRes> | undefined> {
        return api.services.bomFollowUp
            .replaceItem(followUp)()
            .then((res) => {
                dispatch(bomItemReplacedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Make a BOM item have multiple traceabilities
 */
export function bomMultipleTraceabilitiesItem(
    followUp: UnwrapAOSPayload<BomFollowUpMultipleTraceabilitiesItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpMultipleTraceabilitiesItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpMultipleTraceabilitiesItemRes> | undefined> {
        return api.services.bomFollowUp
            .multilpleTraceabilitiesItem(followUp)()
            .then((res) => {
                dispatch(bomItemMultipleTraceabilitiesAction([...res]));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}
