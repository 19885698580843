import { JOB_QTY_MAX } from "../../../../../Job/jobUtils";
import { DATASTORE_TABLE_VALUE_COLUMN_MAXIMUM_COUNT } from "../../../processActionStep/config/DataStore";
export const EXPLANATION_FR = {
    "explanation.accessUnauthorized": "Vos permissions ne vous permettent pas d'effectuer cette action.",
    "explanation.approvalNotRequired": "Votre approbation n'est pas requise.",
    "explanation.cannotCopyEmptyPayload": "La charge utile contient un tableau vide et ne peut pas être copiée.",
    "explanation.cannotCopyUncopyableProcessActions": "Les actions suivantes ne peuvent être copiées: Échec, Entrée et Sortie.",
    "explanation.cannotCreateRoutingTraining": "Une formation ne peut pas être assignée à un routage.",
    "explanation.cannotDeleteRoleUsedByAUser": "Impossible de supprimer le rôle car il est actuellement utilisé par un utilisateur.",
    "explanation.changelogBlank": "Le journal des modifications est resté vide.",
    "explanation.cannotFindTreeFileToUpdate": "Impossible de trouver le fichier d'arborescence à mettre à jour.",
    "explanation.columnsCountHasExceededLimit": `Le nombre de colonnes a dépassé la limite de ${DATASTORE_TABLE_VALUE_COLUMN_MAXIMUM_COUNT}.`,
    "explanation.couldNotCancelVersionNotApprovedOrOnHold": "Il n'est pas possible d'annuler la version car elle n'est pas approuvée ou suspendu.",
    "explanation.couldNotCancelVersionAlreadyReleased": "Il n'est pas possible d'annuler la version car elle a déjà été publiée.",
    "explanation.couldNotGetErpSetting": "Aucun configuration d'ERP trouvée dans la base de données.",
    "explanation.couldNotHoldVersionNotApproved": "Il n'est pas possible de suspendre la version car elle n'est pas approuvée.",
    "explanation.couldNotHoldVersionAlreadyReleased": "Il n'est pas possible de suspendre la version car elle a déjà été publiée.",
    "explanation.couldNotUpdateJobArchived": "Il n'est pas possible de mettre à jour un job archivé.",
    "explanation.databaseQueryFailed": "La requête à la base de données a échouée.",
    "explanation.electronicSignatureConsentUnchecked": "Vous n'avez pas consenti à l'accord de signature électronique.",
    "explanation.erpQueryFailed": "La requête envoyée au système ERP a échouée.",
    "explanation.executionError": "Erreur durant l'exécution du code.",
    "explanation.executionNotAssociatedToReworkId": "L'exécution n'est pas associée à un billet d'échec.",
    "explanation.informationIncorrectOrMissing": "Des informations sont incorrectes ou manquantes.",
    "explanation.invalidPassword": "Mot de passe invalide.",
    "explanation.invalidProcessVersionStatusForTrainingRequirementUpdate": "Le requis de formation ne peut pas être mise à jour avec le statut actuel du processus.",
    "explanation.invalidUsercode": "Code d'utilisateur invalide.",
    "explanation.invalidUsername": "Nom d'utilisateur invalide.",
    "explanation.invalidUsernameOrPassword": "Nom d'utilisateur ou mot de passe invalide.",
    "explanation.invalidReleaseDate": "Date de publication invalide.",
    "explanation.invalidRole": "Le nom ou les paramètres du rôle ne sont pas valides.",
    "explanation.invalidVariableIdentifierPrefixSys": "Impossible d'utiliser le préfixe SYS_ pour identifier une variable. Ce préfixe est reservé au variable systeme de l'application.",
    "explanation.invalidVariableIdentifierAlphanumericUnderscore": "L'identifiant de la variable ne peut contenir que des caractères alphanumeriques et des caractères de soulignement.",
    "explanation.itemStatusNotExistOrArchived": "Le statut de la pièce n'existe pas ou est archivé.",
    "explanation.jobQtyMaxReach": `La quantité maximale d'items d'un job est de ${JOB_QTY_MAX}.`,
    "explanation.jobAlreadyCreated": "Le bon de commande a déjà été créé.",
    "explanation.trainingAlreadyCreated": "Le formation a déjà été créée.",
    "explanation.jobProcessQtyMaxReach": `La quantité maximale d'items d'un processus du job est de ${JOB_QTY_MAX}.`,
    "explanation.leastOnProcessVersionNotApprovedInRouting": "Au moins un processus du routage n'a pas de version approuvée.",
    "explanation.missingPassword": "Mot de passe manquant.",
    "explanation.noAccessRightAvalableOrFileAlreadyArchived": "L'utilisateur n'a pas les droits d'accès pour archiver le fichier ou pour modifier un fichier déjà archivé.",
    "explanation.noOrganizationSettingConfigured": "Les paramètres de l'organisation ne sont pas configurés dans l'application.",
    "explanation.noFolderHasBeenSelected": "Aucun dossier n'a été sélectionné.",
    "explanation.noProcessApprovedOrReleaseInRouting": "Ce routage ne contient pas de version de processus publiée ou approuvée.",
    "explanation.noProcessVersionApproved": "Aucune version approuvée de processus n'est disponible.",
    "explanation.noProcessVersionReleased": "Aucune version publiée de processus n'est disponible.",
    "explanation.noRunnerAvailable": "Une erreur s'est produite avec le système d'exécution de processus.",
    "explanation.notAllMandatoryApprovalGroups": "Tous les groupes d'approbation obligatoires n'ont pas été sélectionnés pour créer une nouvelle version.",
    "explanation.numberOfVersionBlank": "Numéro de la version est resté vide.",
    "explanation.passwordTemporaryDisabled": "Mot de passe temporairement désactivé.",
    "explanation.selfApprovedProcessVersion": "Impossible d'approuver une version que vous avez créée.",
    "explanation.statusReworkIsArchived": "Le ticket d'échec est archivé.",
    "explanation.processVersionIsNotDraft": "La version du processus n'est pas un brouillon.",
    "explanation.referenceIdBlank": "ID de référence est resté vide.",
    "explanation.storeResultError": "Les données collectées lors de l'exécution d'un processus n'ont pas pu être envoyées à la base de données.",
    "explanation.trainerNotQualified": "Le formateur est un utilisateur non formé et/ou non certifié ou ne fait pas partie d'un groupe de formateurs.",
    "explanation.unableCreateTrainingCertificate": "Le processus sélectionné ou les versions précédentes n'ont pas d'exigences de formation. Il est donc impossible de créer une attestation de formation pour un processus qui ne nécessite pas de formation.",
    "explanation.unableCreateTrainingReadingCertificate": "L'exigence de formation pour le processus sélectionné n'est pas Lecture. Il est donc impossible de créer une attestation de lecture pour cette formation.",
    "explanation.unableCreateTrainingCertificateTrainerNotQualified": "Le formateur est un utilisateur non formé et/ou non certifié ou ne fait pas partie d'un groupe de formateurs.",
    "explanation.unableCreateTrainingCertificateTrainerIsTrainee": "Impossible de créer l'attestation, le formateur et la personne formée sont le même utilisateur.",
    "explanation.unableCreateTrainingCertificateAuditorIsCertified": "Impossible de créer la certification, l'auditeur et la personne certifiée sont le même utilisateur.",
    "explanation.unableSignTrainingCertificate": "La formation en attente a été signée par un utilisateur différent de celui affecté à l'attestation formation.",
    "explanation.unableCreateTrainingReadingCertificateMissingTrainingCertificate": "Une attestation de formation est requise pour cette version.",
    "explanation.unableCreateTrainingCertification": "Le processus sélectionné ou les versions précédentes n'ont pas d'exigences de formation. Il est donc impossible de créer une certification de formation pour un processus qui ne nécessite pas de formation.",
    "explanation.unableCreateTrainingCertificationAuditorNotQualified": "L'auditeur est un utilisateur non formé et/ou non certifié ou ne fait pas partie d'un groupe de formateurs.",
    "explanation.unableSignTrainingCertification": "La certification en attente a été signée par un utilisateur différent de celui affecté à la certification de la formation.",
    "explanation.unablePerformActionVersionNotCorrect": "Impossible d'effectuer votre action, la version de processus sélectionnée n'est pas correcte pour ce type d'action.",
    "explanation.unhandledError": "Erreur non gérée durant l'exécution du code.",
    "explanation.unknownError": "Erreur inconnue.",
    "explanation.userNotActive": "L'utilisateur n'est pas actif ou n'existe pas.",
    "explanation.variableSameIdentifier": "Une autre variable a le même identifiant.",
    "explanation.versionAlreadyApproved": "Cette version est déjà approuvée.",
    "explanation.versionAlreadyPendingApprovedOrWaitingReleased": "Une autre version est déjà en attente d'approbation ou en attente de publication.",
    "explanation.versionAlreadyExists": "Une version avec le même numéro de version existe déjà.",
};
