import { Kinova } from "@kortex/aos-api-server";
import jwt from "jsonwebtoken";
import { AOSError, AccessLevelEnum, AccessUnauthorizedError, UnhandledError } from "../interfaces/models";
const EMPTY_TOKEN = "";
/**
 * Create an AOS payload
 *
 * @param {object} payload - payload
 * @param {Function} payloadModifier - callback to modify payload
 */
export function createAOSPayload(payload, payloadModifier) {
    const aosPayload = {
        payload,
        security: {
            token: void 0,
        },
    };
    if (payloadModifier) {
        return payloadModifier(aosPayload);
    }
    return aosPayload;
}
/**
 * Sign token received from request
 *
 * @param {string} connectionHandle - connection handle
 * @param {object} sessionOptions - session options
 */
function signToken(connectionHandle, sessionOptions) {
    const { expiresIn = "1h", salt, sessionManager } = sessionOptions;
    const currentToken = sessionManager.get(connectionHandle)?.data.token ?? EMPTY_TOKEN;
    return currentToken ? jwt.sign(currentToken, salt, { expiresIn }) : EMPTY_TOKEN;
}
/**
 * Set request resolver callback
 *
 * Signs received token from request
 * Performs security tests with middlewares
 * Unwraps request and reponse for client use
 *
 * @param {Function} cb - callback with request and response
 * @param {object} [options] - options
 */
export const setRequestResolverCallback = (cb, options) => (req, res) => {
    // ------------------------------
    // SIGN TOKEN
    // ------------------------------
    if (options?.session) {
        if (req.payload.security) {
            req.payload.security.token = signToken(req.connectionHandle, options.session);
        }
        else {
            const sessionError = new AccessUnauthorizedError({
                message: "Missing token.",
                title: AccessUnauthorizedError.TitleKeyEnum.ACCESS_UNAUTHORIZED,
                explanation: AccessUnauthorizedError.ExplanationKeyEnum.ACCESS_UNAUTHORIZED,
                suggestion: AccessUnauthorizedError.SuggestionKeyEnum.CONTACT_AOS_DEV_TEAM,
                level: AccessUnauthorizedError.MessageLevelEnum.ERROR,
            }, undefined, { request: req, sessionInfo: options.session.sessionManager.get(req.connectionHandle) });
            options?.onError?.("session", sessionError);
            return res(null, {
                errorCode: Kinova.Api.ErrorCodes.ERROR_DEVICE,
                errorSubCode: 1,
                errorSubString: JSON.stringify(sessionError.errorToJSON()),
            });
        }
    }
    // ------------------------------
    // MIDDLEWARES
    // ------------------------------
    if (options?.middlewares) {
        // ------------------------------
        // AUTHORIZATION MIDDLEWARE
        // ------------------------------
        if (options.middlewares.authorization) {
            const { minLevel, type } = options.middlewares.authorization;
            let tokenDecoded;
            // If authorization required, check token
            if (minLevel !== AccessLevelEnum.NONE) {
                if (req.payload.security?.token && options.session?.salt) {
                    // Validate that the auth token is valid
                    try {
                        tokenDecoded = jwt.verify(req.payload.security.token, options.session.salt);
                    }
                    catch (error) {
                        const sessionError = new AccessUnauthorizedError({
                            message: "Could not decode the token.",
                            title: AccessUnauthorizedError.TitleKeyEnum.ACCESS_UNAUTHORIZED,
                            explanation: AccessUnauthorizedError.ExplanationKeyEnum.ACCESS_UNAUTHORIZED,
                            suggestion: AccessUnauthorizedError.SuggestionKeyEnum.CONTACT_AOS_DEV_TEAM,
                            level: AccessUnauthorizedError.MessageLevelEnum.ERROR,
                        }, undefined, { request: req, sessionInfo: options.session.sessionManager.get(req.connectionHandle) });
                        options?.onError?.("session", sessionError);
                        return res(null, {
                            errorCode: Kinova.Api.ErrorCodes.ERROR_DEVICE,
                            errorSubCode: 1,
                            errorSubString: JSON.stringify(sessionError.errorToJSON()),
                        });
                    }
                    // Filter user rights based on request authorization type and user token information
                    // To prevent unwanted behaviour until story AOS-1227 is addressed, this check is temporarily disabled
                    if (tokenDecoded.roleRights[type] < minLevel) {
                        const authorizationError = new AccessUnauthorizedError({
                            message: `Access unauthorized to the ${type} module. Your level is ${tokenDecoded.roleRights[type]} but the minimum level required is ${minLevel}.`,
                            title: AccessUnauthorizedError.TitleKeyEnum.ACCESS_UNAUTHORIZED,
                            explanation: AccessUnauthorizedError.ExplanationKeyEnum.ACCESS_UNAUTHORIZED,
                            suggestion: AccessUnauthorizedError.SuggestionKeyEnum.CONTACT_AOS_DEV_TEAM,
                            level: AccessUnauthorizedError.MessageLevelEnum.ERROR,
                        }, undefined, { sessionInfo: options.session.sessionManager.get(req.connectionHandle) });
                        options?.onError?.("authorization", authorizationError);
                        return res(null, {
                            errorCode: Kinova.Api.ErrorCodes.ERROR_DEVICE,
                            errorSubCode: 1,
                            errorSubString: JSON.stringify(authorizationError.errorToJSON()),
                        });
                    }
                }
                else {
                    const sessionError = new AccessUnauthorizedError({
                        message: "Could not validate the token.",
                        title: AccessUnauthorizedError.TitleKeyEnum.ACCESS_UNAUTHORIZED,
                        explanation: AccessUnauthorizedError.ExplanationKeyEnum.ACCESS_UNAUTHORIZED,
                        suggestion: AccessUnauthorizedError.SuggestionKeyEnum.CONTACT_AOS_DEV_TEAM,
                        level: AccessUnauthorizedError.MessageLevelEnum.ERROR,
                    }, undefined, { request: req, sessionInfo: options?.session?.sessionManager.get(req.connectionHandle) });
                    options?.onError?.("session", sessionError);
                    return res(null, {
                        errorCode: Kinova.Api.ErrorCodes.ERROR_DEVICE,
                        errorSubCode: 1,
                        errorSubString: JSON.stringify(sessionError.errorToJSON()),
                    });
                }
            }
        } // END AUTHORIZATION MIDDLEWARE
    } // END MIDDLEWARES
    // ------------------------------
    // UNWRAP REQUEST AND RESPONSE
    // ------------------------------
    return cb(
    // Unwrap request
    {
        ...req,
        payload: req.payload.payload,
    }, 
    // Wrap response
    async (response, error) => {
        // Verifiy if there's an error, else send wrapped response
        if (error) {
            // Format error
            let aosError;
            let apiError;
            if (error instanceof AOSError) {
                aosError =
                    ((error.argument = {
                        ...error.argument,
                        sessionInfo: options?.session?.sessionManager.get(req.connectionHandle),
                    }),
                        error);
                apiError = {
                    errorCode: Kinova.Api.ErrorCodes.ERROR_DEVICE,
                    errorSubCode: 1,
                    errorSubString: JSON.stringify(aosError.errorToJSON()),
                };
            }
            else if (error instanceof Error) {
                aosError = new UnhandledError({
                    message: "Unhandled Error",
                    title: UnhandledError.TitleKeyEnum.UNHANDLED_ERROR,
                    explanation: UnhandledError.ExplanationKeyEnum.UNHANDLED_ERROR,
                    suggestion: UnhandledError.SuggestionKeyEnum.CONTACT_AOS_DEV_TEAM,
                    level: UnhandledError.MessageLevelEnum.ERROR,
                }, error, { sessionInfo: options?.session?.sessionManager.get(req.connectionHandle) });
                apiError = {
                    errorCode: Kinova.Api.ErrorCodes.ERROR_DEVICE,
                    errorSubCode: 1,
                    errorSubString: JSON.stringify(aosError.errorToJSON()),
                };
            }
            else {
                apiError = error;
            }
            if (aosError) {
                options?.onError?.("request", aosError);
            }
            return res(null, apiError);
        }
        else {
            return res({
                payload: response,
                security: {
                    token: options?.session ? signToken(req.connectionHandle, options.session) : EMPTY_TOKEN,
                },
            });
        }
    });
};
/**
 * Wraps a resolver's "notify" function
 *
 * @param {Function} notifyCb - resolver's notify function
 */
export function getWrappedNotify(notifyCb) {
    // Return a callback with an unwrapped notification payload
    return async (notification, connectionHandle, filters) => {
        // Wrap notification payload
        notifyCb(createAOSPayload(notification), connectionHandle, filters);
    };
}
/**
 * Returns a notification options object to send a notification to self
 */
export function notifyOneOptions(connectionHandle) {
    return {
        excludeSelf: false,
        filter: (candidates) => candidates === connectionHandle,
    };
}
