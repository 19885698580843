import { KortexSideMenu, primaryPalette, theme } from "@aos/react-components";
import { KanbanRightsEnum, LocationKeys } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { clearProcessesAction } from "@kortex/aos-ui/redux/process-manager/process-actions";
import { clearSchedulerAction } from "@kortex/aos-ui/redux/scheduler-manager/scheduler-actions";
import { setEditedTreeProcessNodeIdAction } from "@kortex/aos-ui/redux/tree-manager/tree-actions";
import { CircularProgress, Dialog, DialogContent, Drawer, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useState } from "react";

import LoginPage from "../../../components/pages/Login/LoginPage";
import ProcessEditorPage from "../../../components/pages/ProcessEditor/ProcessEditorPage";
import ReportingPage from "../../../components/pages/Reporting/ReportingPage";
import SchedulerPage from "../../../components/pages/Scheduler/SchedulerPage";
import StorageManager from "../../../components/pages/StorageManager/StorageManager";
import { getUserMainMenu } from "../../../configs/menu";
import { useTranslate } from "../../../hooks/useTranslate";
import { setLanguage, setRoute } from "../../../redux/general-manager/general-thunks-general";
import { EnumPageTypes } from "../../../redux/general-manager/general-types";
import {
    useSelectorCurrentPage,
    useSelectorDesktopUpdateReady,
    useSelectorLanguage,
    useSelectorReconnecting,
    useSelectorUserSession,
} from "../../../redux/selectors";
import { logout } from "../../../redux/user-manager/UserActions";
import { getDesktopVersion, getElectronAPIClient } from "../../../utilitites/desktopUtils";
import * as CloudStorage from "../../../utilitites/storage";
import { TrainingCertificateSignatureDialog } from "../../core/TrainingCertificateSignatureDialog";
import { TrainingCertificationSignatureDialog } from "../../core/TrainingCertificationSignatureDialog";
import FailureTicketPage from "../../pages/FailureTicket/FailureTicketPage";
import InfoPage from "../../pages/Info/InfoPage";
import { PasswordUpdatePage } from "../../pages/PasswordUpdate/PasswordUpdatePage";
import { ScannerPage } from "../../pages/Scanner";
import SchedulerOperatorPage from "../../pages/SchedulerOperator/SchedulerOperatorPage";
import SystemEditor from "../../pages/SettingsEditor/GlobalSettingEditor/GlobalSettings";
import GroupEditor from "../../pages/SettingsEditor/GroupEditor/GroupEditor";
import RoleEditorPage from "../../pages/SettingsEditor/RoleEditor/RoleEditorPage";
import TagEditor from "../../pages/SettingsEditor/TagEditor/TagEditor";
import UserEditorPage from "../../pages/SettingsEditor/UserEditor/UserEditorPage";
import TasksPage from "../../pages/Tasks/TasksPage";
import { TrainingPage } from "../../pages/Training";
import { TrainingPageProvider } from "../../pages/Training/utilities/trainingPageContext";
import Header from "../Header";
import { useSnackbar } from "../snackbarConfigurator";

const drawerWidth = 240;

const useStyles = makeStyles({
    root: {
        height: "100vh",
        width: "100vw",
        zIndex: 1,
        position: "relative",
        display: " grid",
        gridTemplateRows: "auto 1fr",
    },
    mainContent: {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        height: "calc(100vh - 64px)",
        width: "100vw",
    },
    topToolbar: {
        backgroundColor: primaryPalette[500],
        color: "white",
        height: "64px",
        width: "100vw",
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
        paddingLeft: "0px",
        boxSizing: "border-box",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        color: "white",
        backgroundColor: "black",
        height: "48px",
    },
    logo: {
        width: "142px",
    },
    content: {
        height: "calc(100vh - 64px)",
        overflowX: "auto",
    },
    drawer: {
        height: "100%",
        boxShadow: theme.shadows[14],
    },
    drawerPaper: {
        display: "flex",
        flexDirection: "column",
        width: `${drawerWidth}px`,
        height: "100%",
        position: "static",
    },
    menuContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    reconnectingDialog: {
        padding: "20px",
    },
    reconnectingDialogContent: {
        width: "250px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        padding: "20px",
    },
    spacer: {
        flexGrow: 1,
    },
    snackbarUpdateAppButton: {
        border: "1px solid",
        color: theme.palette.common.white,
        pointerEvents: "all",
    },
});

export default function AppLayout(): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    const dispatch = useThunkDispatch();

    const currentPage = useSelectorCurrentPage();
    const desktopUpdateReady = useSelectorDesktopUpdateReady();
    const language = useSelectorLanguage();
    const userInfo = useSelectorUserSession();

    const [menuOpen, setMenuOpen] = useState(false);

    const reconnecting = useSelectorReconnecting();
    const snackbar = useSnackbar();

    // Action related to snack message for update, include button to update
    const updateAction = (): JSX.Element => (
        <Button className={classes.snackbarUpdateAppButton} onClick={handleDesktopUpdateClick}>
            {<Typography variant="button">{translate("appLayout.updateAndRestart")}</Typography>}
        </Button>
    );

    // TODO: create a custom bootstrap effect
    useEffect(() => {
        CloudStorage.bootstrap();
    }, []);

    // Send snackbar on new update available
    useEffect(() => {
        if (desktopUpdateReady === true) {
            snackbar.info(translate("appLayout.updateAvailable").toUpperCase(), {
                action: updateAction,
                persist: true,
            });
        }
    }, [desktopUpdateReady]);

    const handleOpenUsersMenu = (): void => {
        dispatch(setRoute(EnumPageTypes.SETTINGS_USERS));
    };

    /**
     * Switch to Tasks Page using the router
     **/
    const handleGoToTasksPage = (): void => {
        dispatch(setRoute(EnumPageTypes.TASKS));
    };

    const handleLogout = (): void => {
        dispatch(logout());
    };

    const handleChangePage = (page: string): void => {
        const pageSanitized = page.replace("/", "");
        if (currentPage !== pageSanitized) {
            // If leaving or entering scheduler or operator scheduler pages, wipe redux for now for incompatibilities with optmized and non-optimized modules.
            if (
                currentPage === EnumPageTypes.SCHEDULER ||
                pageSanitized === EnumPageTypes.SCHEDULER ||
                currentPage === EnumPageTypes.OPERATOR_SCHEDULER ||
                pageSanitized === EnumPageTypes.OPERATOR_SCHEDULER
            ) {
                dispatch(clearSchedulerAction());
                dispatch(clearProcessesAction());
                dispatch(setEditedTreeProcessNodeIdAction(undefined));
            }
            dispatch(setRoute(pageSanitized));
        }
        setMenuOpen(false);
    };

    const handleGoToHomePage = (): void => {
        dispatch(setRoute(EnumPageTypes.PROCESS));
    };

    /**
     * Open or close side menu
     */
    const handleDrawerToggle = (): void => {
        setMenuOpen(!menuOpen);
    };

    /**
     * Handle language change
     */
    const handleLanguageChange = (lang: LocationKeys): void => {
        dispatch(setLanguage(lang));
    };

    /**
     * Handle desktop update click
     */
    const handleDesktopUpdateClick = (): void => {
        getElectronAPIClient().services.general.desktopStartUpdate({})();
    };

    return (
        <div className={classes.root}>
            {!(userInfo && userInfo.roleRights.kanban === KanbanRightsEnum.INSERT) && (
                <div className={classes.topToolbar}>
                    <Header
                        onGoToUsersPage={handleOpenUsersMenu}
                        onGoToTasksPage={handleGoToTasksPage}
                        onLogout={handleLogout}
                        onDrawerToggle={handleDrawerToggle}
                        loading={false}
                        language={language}
                        username={userInfo ? userInfo.userName : ""}
                        onLanguageChange={handleLanguageChange}
                    />
                </div>
            )}

            <div className={classes.mainContent}>
                {/* MAIN MENU */}
                {currentPage !== EnumPageTypes.LOGIN && currentPage !== EnumPageTypes.PASSWORD && (
                    <div>
                        <Drawer
                            variant="temporary"
                            open={menuOpen}
                            onClose={handleDrawerToggle}
                            className={classes.drawer}
                            classes={{ paper: classes.drawerPaper }}
                        >
                            <div className={classes.menuContainer}>
                                <KortexSideMenu
                                    configList={getUserMainMenu(userInfo)}
                                    version={"version: " + getDesktopVersion()}
                                    currentPage={currentPage}
                                    onChangeMenuItem={handleChangePage}
                                    onCloseMenu={handleDrawerToggle}
                                    onGoToHomePage={handleGoToHomePage}
                                />
                            </div>
                        </Drawer>
                    </div>
                )}

                {/* MAIN CONTENT  */}
                <main className={classes.content}>
                    {currentPage === EnumPageTypes.PROCESS && <ProcessEditorPage />}
                    {currentPage === EnumPageTypes.REPORTS && <ReportingPage />}
                    {currentPage === EnumPageTypes.TRAINING && (
                        <TrainingPageProvider>
                            <TrainingPage />
                        </TrainingPageProvider>
                    )}
                    {currentPage === EnumPageTypes.SCHEDULER && <SchedulerPage />}
                    {currentPage === EnumPageTypes.OPERATOR_SCHEDULER && <SchedulerOperatorPage />}
                    {/* TODO: Reactivate this feature when we will start working on BOM {currentPage === EnumPageTypes.BOM && <BomPage />} */}
                    {currentPage === EnumPageTypes.REWORKS && <FailureTicketPage />}
                    {currentPage === EnumPageTypes.TASKS && <TasksPage />}
                    {currentPage === EnumPageTypes.STORAGE_MANAGER && (
                        <div style={{ margin: "16px", height: "calc(100% - 32px)" }}>
                            <StorageManager userAccessLevel={userInfo && userInfo.roleRights.storageEditor} />
                        </div>
                    )}
                    {currentPage === EnumPageTypes.KANBAN && <ScannerPage />}
                    {currentPage === EnumPageTypes.INFO && <InfoPage />}
                    {currentPage === EnumPageTypes.SETTINGS_USERS && (
                        <UserEditorPage userAccessLevel={userInfo && (userInfo.roleRights.userEditor as number)} />
                    )}

                    {currentPage === EnumPageTypes.SETTINGS_GROUPS && (
                        <GroupEditor userAccessLevel={userInfo && (userInfo.roleRights.groupEditor as number)} />
                    )}
                    {currentPage === EnumPageTypes.SETTINGS_ROLES && (
                        <RoleEditorPage userAccessLevel={userInfo && (userInfo.roleRights.roleEditor as number)} />
                    )}
                    {currentPage === EnumPageTypes.SETTINGS_TAGS && (
                        <TagEditor userAccessLevel={userInfo && (userInfo.roleRights.system as number)} />
                    )}
                    {currentPage === EnumPageTypes.SETTINGS_GLOBALS && (
                        <SystemEditor userAccessLevel={userInfo && userInfo.roleRights.system} />
                    )}
                </main>
            </div>

            <PasswordUpdatePage
                open={currentPage === EnumPageTypes.PASSWORD}
                userId={userInfo ? userInfo.userId : 0}
                userName={userInfo ? userInfo.userName : ""}
            />

            {/* LOGIN PAGE */}
            <LoginPage open={currentPage === EnumPageTypes.LOGIN || !userInfo} />

            {/* Reconnect Dialog */}
            <Dialog open={reconnecting} className={classes.reconnectingDialog}>
                <DialogContent className={classes.reconnectingDialogContent}>
                    <div className={classes.spacer} />
                    <CircularProgress />
                    <div className={classes.spacer} />
                    {translate("appLayout.reconnecting")}
                    <div className={classes.spacer} />
                </DialogContent>
            </Dialog>

            {userInfo && currentPage !== EnumPageTypes.LOGIN && currentPage !== EnumPageTypes.PASSWORD && (
                <>
                    <TrainingCertificateSignatureDialog userId={userInfo.userId} />
                    <TrainingCertificationSignatureDialog userId={userInfo.userId} />
                </>
            )}
        </div>
    );
}
