import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Table, TableBody, makeStyles } from "@material-ui/core";
import React from "react";

import { PlayerControlsBomItems } from "./bomItems";

const useStyles = makeStyles({
    root: {
        // FIXME: Find another way to calculate height? Might need to rework the CSS of the entire PlayerControls component...
        height: "calc(100vh - 352px)", // 70px (header) + 40px (body padding) + 48px (tabs) + 62px (controls header) - 132px (controls footer)
        overflowY: "scroll",
    },
});

function PlayerControlsBom(): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.root}>
            <Table>
                <TableBody>
                    <PlayerControlsBomItems expandedInitialValue={true} title={translate("player.bom.stepItems")} />
                </TableBody>
            </Table>
        </div>
    );
}

export default PlayerControlsBom;
