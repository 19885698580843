import { IKortexTextFieldProps, KortexTextField } from "@aos/react-components";
import { useKeybind } from "@kortex/aos-ui/hooks/useKeybind";
import { ClickAwayListener, Divider, IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FocusEvent, useEffect, useRef, useState } from "react";

import { Item, TextFieldSelectMenu, TextFieldSelectMenuProps } from "./TextFieldSelectMenu";

const useStyles = makeStyles({
    root: {}, // To overwrite with props
    divider: {
        width: "1px",
        height: "30px",
    },
});

export interface OwnProps extends Pick<TextFieldSelectMenuProps, "MenuItemProps" | "MenuListProps" | "PaperProps" | "PopperProps"> {
    classes?: Partial<ReturnType<typeof useStyles>> & TextFieldSelectMenuProps["classes"];
    disabled?: boolean;
    initialValue?: Item["textFieldValue"]; // Uncontrolled value
    items: Item[];
    KortexTextFieldProps?: Partial<IKortexTextFieldProps>;
    onChange: (value: Item["value"]) => void;
    /**
     * When undefined we want to set the value with the lastSelectedItemTextFieldValue when onBlur is called.
     * If it is false, then put an empty string.
     */
    showClearButton?: boolean;
    value?: Item["textFieldValue"]; // Controlled value
}

function TextFieldSelect(props: OwnProps): JSX.Element {
    /**
     * Props
     */
    const { disabled, initialValue, items, KortexTextFieldProps = {}, onChange, showClearButton = false, ...menuProps } = props;
    const { TextFieldProps = {}, ...restKortexTextFieldProps } = KortexTextFieldProps;
    const { inputProps, ...restTextFieldProps } = TextFieldProps;

    /**
     * State
     */
    const [lastSelectedItemTextFieldValue, setLastSelectedItemTextFieldValue] = useState<Item["textFieldValue"]>(
        getDefaultValue(items, initialValue ?? props.value)
    );
    const [menuOpened, setMenuOpened] = useState(false);
    const [value, setValue] = useState<Item["textFieldValue"]>(getDefaultValue(items, initialValue ?? props.value));

    /**
     * Other hooks
     */
    const classes = useStyles(props);
    const textFieldRef = useRef<HTMLInputElement | null>(null);

    useKeybind("Escape", () => handleBlur());
    useKeybind("Tab", () => handleBlur());

    // Effect to control value from parent
    useEffect(() => {
        const updatedValue = getDefaultValue(items, props.value);

        if (updatedValue !== value) setValue(updatedValue);
    }, [props.value]);

    /**
     * Functions
     */
    const handleBlur = (): void => {
        setMenuOpened(false);
        setValue(value === "" && showClearButton === true ? "" : lastSelectedItemTextFieldValue);
    };

    const handleChanged = (updatedValue: Item["textFieldValue"]): void => {
        setValue(updatedValue);
        openMenuIfValueNotFound(updatedValue);
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        event.target.select();
        openMenuIfValueNotFound();
    };

    const handleItemClick = (...[itemValue, itemTextFieldValue]: Parameters<TextFieldSelectMenuProps["onItemClick"]>): void => {
        setValue(itemTextFieldValue);
        setLastSelectedItemTextFieldValue(itemTextFieldValue);
        onChange(itemValue);
        openMenuIfValueNotFound(itemTextFieldValue);
    };

    const openMenuIfValueNotFound = (updatedValue = value): void =>
        setMenuOpened(!items.some((item) => item.textFieldValue === updatedValue));

    /**
     * Called when the ClearInput button is clicked
     */
    const handleClearInput = (): void => {
        setValue("");
        setMenuOpened(false);
        onChange("");
    };

    /**
     * Component
     */
    return (
        <ClickAwayListener onClickAway={handleBlur}>
            <div className={classes.root}>
                <KortexTextField
                    blurOnEnter={false}
                    changedDelayMS={200}
                    onChanged={handleChanged}
                    skipStopPropagationOnHandledKey={true}
                    submitOnEnter={false}
                    TextFieldProps={{
                        autoComplete: "off",
                        disabled,
                        fullWidth: true,
                        inputProps: {
                            onFocus: handleFocus,
                            ...inputProps,
                        },
                        ref: textFieldRef,
                        ...restTextFieldProps,
                    }}
                    value={value}
                    {...restKortexTextFieldProps}
                    InputProps={{
                        ...restKortexTextFieldProps.InputProps,
                        endAdornment: (
                            <>
                                {restKortexTextFieldProps.InputProps?.endAdornment}
                                {showClearButton ? (
                                    <InputAdornment position="end">
                                        <Divider className={classes.divider} />
                                        <IconButton onClick={handleClearInput} id={"closeFilterId"}>
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null}
                            </>
                        ),
                    }}
                />
                <TextFieldSelectMenu
                    classes={{
                        menuItem: props.classes?.menuItem,
                        papper: props.classes?.papper,
                        popper: props.classes?.popper,
                    }}
                    disabled={disabled}
                    items={items}
                    onItemClick={handleItemClick}
                    open={menuOpened}
                    textFieldRef={textFieldRef}
                    textFieldValue={value}
                    {...menuProps}
                />
            </div>
        </ClickAwayListener>
    );
}

export default TextFieldSelect;

function getDefaultValue(items: Item[], initialValue?: Item["textFieldValue"]): Item["textFieldValue"] {
    return items.find((item) => item.value === initialValue)?.textFieldValue ?? "";
}
