import { Box, Collapse, Table, TableCell, TableRow } from "@material-ui/core";
import React from "react";

import WorkInstructionsBomStepItemsTableBody from "./Body/WorkInstructionsBomItemsTableBody";
import WorkInstructionsBomStepItemsTableHeader from "./Header/WorkInstructionsBomItemsTableHeader";

interface IOwnProps {
    displayStepItems: boolean;
    expanded: boolean;
}

function WorkInstructionsBomItemsTable(props: IOwnProps): JSX.Element {
    const { displayStepItems, expanded } = props;
    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={6}>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Box>
                            <Table>
                                <WorkInstructionsBomStepItemsTableHeader />
                                <WorkInstructionsBomStepItemsTableBody displayStepItems={displayStepItems} />
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default WorkInstructionsBomItemsTable;
