import React, { FC, useState } from "react";

import PlayerControlsBomItemsBody from "./body/playerControlsBomItemsBody";
import { PlayerControlsBomItemsHeader } from "./header";

interface IOwnProps {
    expandedInitialValue?: boolean;
    title: string;
}

const PlayerControlsBomItems: FC<IOwnProps> = (props) => {
    const { expandedInitialValue = false, title } = props;

    const [expanded, setExpanded] = useState(expandedInitialValue);

    /**
     *
     */
    const handleToggleExpand = (): void => {
        setExpanded((prevState) => !prevState);
    };

    return (
        <>
            <PlayerControlsBomItemsHeader expanded={expanded} onToggleExpand={handleToggleExpand} title={title} />
            <PlayerControlsBomItemsBody expanded={expanded} />
        </>
    );
};

export default PlayerControlsBomItems;
