export const bomFollowUp = {
    rpc: {
        insert: {
            funcId: 3701,
            funcName: "bomFollowUpInsert",
        },
        inserted: {
            funcId: 3702,
            funcName: "bomFollowUpInserted",
        },
        updated: {
            funcId: 3703,
            funcName: "bomFollowUpUpdated",
        },
        replaceItem: {
            funcId: 3704,
            funcName: "bomFollowUpReplaceItem",
        },
        overconsumeItem: {
            funcId: 3705,
            funcName: "bomFollowUpOverconsumeItem",
        },
        editItem: {
            funcId: 3706,
            funcName: "bomFollowUpEditItem",
        },
        multipleTraceabilitiesItem: {
            funcId: 3707,
            funcName: "bomFollowUpMultipleTraceabilitiesItem",
        },
    },
    serviceId: 37,
    serviceVersion: 1,
};
