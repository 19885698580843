import { IProcessUiModel, ProcessActionId } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { IPlayerState } from "@kortex/aos-ui/redux/player-manager/player-types";
import { AppBar, CircularProgress, Tab, Tabs } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

import { usePlayerContext } from "../../context";

import { PlayerControlsBom } from "./bom";
import { PlayerControlsTimeline } from "./timeline";

// FIXME: Rework this interface. This component should be independent of parent.
// Child components should be self-sufficient or use a context
interface IOwnProps {
    timelineProps: {
        onActionStepChanged: () => void;
        onRewindTo: (processActionId: ProcessActionId, stepIndex: number) => void;
        playerState: IPlayerState; // the actual player state
        playingHistoryIndex: number;
        processWithActions: IProcessUiModel | null;
        playingHistory?: boolean; // are we playing a history snaphot
    };
}

const PlayerControlsContent: FC<IOwnProps> = (props) => {
    const { timelineProps } = props;

    const translate = useTranslate();
    const { bom } = usePlayerContext();

    const [selectedTab, setSelectedTab] = useState(bom && Object.keys(bom).length > 0 ? 0 : 1);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (bom && Object.keys(bom).length > 0) {
            setSelectedTab(0);
            setLoading(false);
        }
    }, [bom]);

    /**
     *
     */
    const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number): void => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <AppBar position="static">
                <Tabs onChange={handleTabChange} value={selectedTab}>
                    <Tab key={0} label={translate("player.bom.bom")} />
                    <Tab key={1} label={translate("player.bom.step")} />
                </Tabs>
            </AppBar>
            {selectedTab === 0 && (loading ? <CircularProgress color="inherit" /> : <PlayerControlsBom />)}
            {selectedTab === 1 && <PlayerControlsTimeline {...timelineProps} />}
        </>
    );
};

export default PlayerControlsContent;
