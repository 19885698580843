import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { IBomFollowUp } from "@kortex/aos-common";
import KortexLabelText from "@kortex/aos-ui/components/core/KortexLabelText";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { bomOverconsumeItem } from "@kortex/aos-ui/redux/bom-manager/bom-thunks";
import { CircularProgress, IconButton, MenuItem, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { FC, useState } from "react";

const useStyles = makeStyles({
    dialogContent: {
        display: "grid",
        gridGap: "5px",
    },
    traceability: {
        display: "flex",
        alignItems: "center",
    },
    quantity: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

interface IOwnProps {
    closeMenu: () => void;
    followUp: IBomFollowUp;
}

const PlayerControlsBomItemRowMenuOverconsumption: FC<IOwnProps> = (props) => {
    const { followUp, closeMenu } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const [justification, setJustification] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [quantity, setQuantity] = useState<number>(followUp.quantity);
    const [traceability, setTraceability] = useState(followUp.traceability);

    /**
     * Closes the dialog
     */
    const handleClose = (): void => {
        setOpen(false);
        setJustification("");
        closeMenu();
    };

    /**
     * Overconsumes the item
     */
    const handleConfirm = async (): Promise<void> => {
        setLoading(true);

        dispatch(
            bomOverconsumeItem({
                bomFollowUpId: followUp.bomFollowUpId,
                justification,
                quantity,
                traceability,
            })
        )
            .then(handleClose)
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Opens the dialog
     */
    const handleOpen = (): void => {
        setOpen(true);
    };

    /**
     * Changes the justification
     */
    const handleJustificationChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setJustification(event.target.value);
    };

    /**
     * Changes the traceability
     */
    const handleTraceabilityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setTraceability(event.target.value);
    };

    /**
     * handles increase the quantity
     */
    const handleQuantityIncrease = (): void => {
        setQuantity(quantity + 1);
    };

    /**
     * handles decrease the quantity
     */
    const handleQuantityDecrease = (): void => {
        setQuantity(quantity - 1);
    };

    return (
        <>
            <MenuItem
                disabled={!Boolean(followUp) || followUp.quantityOver > 0}
                id="playerControlsBomItemRowMenuOverconsumptionId"
                onClick={handleOpen}
            >
                {translate("player.bom.overconsumption")}
            </MenuItem>
            <KortexDialogConfirmation
                closeOnEscape={true}
                confirmDisabled={traceability.length === 0 || justification.length < 3}
                onCancel={handleClose}
                onConfirm={handleConfirm}
                open={open}
                textLabels={{
                    cancelButtonLabel: translate("general.cancel"),
                    proceedButtonLabel: translate("general.confirm"),
                    titleLabel: translate("player.bom.overconsumption"),
                }}
                textLabelsIcons={{
                    proceedButtonIcon: loading ? <CircularProgress /> : undefined,
                }}
            >
                <div className={classes.dialogContent}>
                    {/* TRACEABILITY */}
                    <div className={classes.traceability}>
                        <KortexTextField
                            label={translate("player.bom.traceability")}
                            onChange={handleTraceabilityChange}
                            value={traceability}
                            variant="outlined"
                            TextFieldProps={{
                                required: true,
                            }}
                        />

                        <div className={classes.quantity}>
                            <IconButton id="addQtyButtonId" onClick={handleQuantityIncrease}>
                                <AddIcon fontSize="small" />
                            </IconButton>
                            <KortexLabelText label={translate("player.bom.quantity")} text={`${quantity}`} />
                            <IconButton id="removeQtyButtonId" onClick={handleQuantityDecrease}>
                                <RemoveIcon fontSize="small" />
                            </IconButton>
                        </div>
                    </div>

                    {/* JUSTIFICATION */}
                    <KortexTextField
                        label={translate("player.bom.justification")}
                        onChange={handleJustificationChange}
                        TextFieldProps={{
                            multiline: true,
                            rows: 5,
                            required: true,
                        }}
                        value={justification}
                        variant="outlined"
                    />
                </div>
            </KortexDialogConfirmation>
        </>
    );
};

export default PlayerControlsBomItemRowMenuOverconsumption;
