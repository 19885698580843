import { IBom } from "@kortex/aos-common";
import { OrUndefined } from "@kortex/aos-ui/redux/app.types";
import React, { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useContext, useState } from "react";

// Context for process editor
interface IProcessEditorContext {
    bom: OrUndefined<IBom>;
    setBom: Dispatch<SetStateAction<OrUndefined<IBom>>>;
}

export const ProcessEditorContext = createContext<IProcessEditorContext>({
    bom: undefined,
    setBom: () => void 0,
});

type ProcessEditorProviderProps = PropsWithChildren<{}>;

export const ProcessEditorProvider: FC<ProcessEditorProviderProps> = (props) => {
    const { children } = props;
    const [bom, setBom] = useState<OrUndefined<IBom>>(undefined);

    return (
        <ProcessEditorContext.Provider
            value={{
                bom,
                setBom,
            }}
        >
            {children}
        </ProcessEditorContext.Provider>
    );
};

export const useProcessEditorContext = (): IProcessEditorContext => useContext<IProcessEditorContext>(ProcessEditorContext);
