import { KortexTextField } from "@aos/react-components";
import { DataStoreActionTypeEnum, EnumResultSettingType, IDataStoreTableValueConfig, ProcessEditorRightsEnum } from "@kortex/aos-common";
import { IconButton, Menu, MenuItem, Paper, makeStyles } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useEffect, useRef, useState } from "react";

import VariablePicker from "../../../../../../../components/core/VariablePicker/VariablePicker";
import { useTranslate } from "../../../../../../../hooks/useTranslate";
import { useSelectorResultSettingItems } from "../../../../../../../redux/selectors";
import { IUserRightsProps, userCanWrite } from "../../../../../../../utilitites/IUserRights";

const INPUT_DELAY_MS = 500;

const useStyles = makeStyles({
    root: {
        marginBottom: "16px",
        padding: "16px",
        display: "grid",
        gridTemplateRows: "auto 1fr",
        gridRowGap: "16px",
    },
    header: {
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
    },
    formControl: {
        width: "140px",
        margin: "5px",
    },
    tableContainer: {
        overflowX: "scroll",
        paddingBottom: "28px",
    },
    columnsContainer: {
        display: "flex",
    },
    columnInfo: {
        width: "166px",
        marginRight: "16px",
        marginLeft: "5px",
        flex: "0 0 auto",
    },
});

interface IDataStoreTableValueEditorProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    onTableValuePropsChanged: (tableValueProps: IDataStoreTableValueConfig, tableValueIndex: number) => void;
    onTableValuePropsDeleted: (tableValueIndex: number) => void;
    tableValueIndex: number;
    tableValueProps: IDataStoreTableValueConfig;
}

export default function DataStoreTableValueItemEditor(props: IDataStoreTableValueEditorProps): JSX.Element {
    const { tableValueIndex, tableValueProps, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const resultSettingItems = useSelectorResultSettingItems();

    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Component states
     */
    const [tableId, setTableId] = useState<DataStoreActionTypeEnum>(tableValueProps.tableId);
    const [columnsValue, setColumnsValue] = useState<string[]>(tableValueProps.columnsValue);
    const [actionChanged, setActionChanged] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const menuRef = useRef(null);

    /**
     * Effect that update local state when props are
     * updated from parent
     */
    useEffect((): void => {
        setTableId(tableValueProps.tableId);
        setColumnsValue(tableValueProps.columnsValue);
    }, [tableValueProps]);

    /**
     * Effect that trigger action props update to database
     */
    useEffect((): void => {
        if (actionChanged) {
            props.onTableValuePropsChanged({ ...tableValueProps, tableId, columnsValue }, tableValueIndex);
            setActionChanged(false);
        }
    }, [actionChanged]);

    /**
     * Handle user change of table
     */
    const handleTableChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setTableId(parseInt(e.target.value));
        setActionChanged(true);
        setColumnsValue([]);
    };

    /**
     * Handle storeTo change
     */
    const handleColumnsStoreToChange =
        (index: number) =>
        (value: string): void => {
            const newColumnsValue = [...columnsValue];
            newColumnsValue[index] = value;
            setColumnsValue(newColumnsValue);

            setActionChanged(true);
        };

    /**
     * Handle open menu click
     */
    const handleMenuClick = (): void => {
        setMenuOpen(true);
    };

    /**
     * Handle menu close
     */
    const handleMenuClose = (): void => {
        setMenuOpen(false);
    };

    /**
     * Handle delete key value
     */
    const handleDeleteKeyValue = (): void => {
        props.onTableValuePropsDeleted(props.tableValueIndex);
    };

    return (
        <Paper className={classes.root}>
            {/* Table */}
            <div className={classes.header}>
                <KortexTextField
                    className={classes.formControl}
                    variant="standard"
                    label={translate("action.datastore.table")}
                    onChange={handleTableChange}
                    TextFieldProps={{
                        disabled: readOnly,
                        select: true,
                    }}
                    value={tableId}
                >
                    {resultSettingItems
                        .filter((settingItem): boolean => settingItem.type === EnumResultSettingType.TABLE)
                        .map((tableItem, index): JSX.Element => {
                            return (
                                <MenuItem key={index} value={tableItem.resultSettingItemId}>
                                    {tableItem.label}
                                </MenuItem>
                            );
                        })}
                </KortexTextField>

                <div />

                <div ref={menuRef}>
                    <IconButton disabled={readOnly} onClick={handleMenuClick}>
                        <MoreVertIcon />
                    </IconButton>
                </div>

                <Menu anchorEl={menuRef.current} open={menuOpen} onClose={handleMenuClose}>
                    <MenuItem onClick={handleDeleteKeyValue} onMouseDown={handleMenuClose}>
                        {translate("action.datastore.delete")}
                    </MenuItem>
                </Menu>
            </div>

            <div className={classes.tableContainer}>
                <div className={classes.columnsContainer}>
                    {resultSettingItems
                        .filter(
                            (settingItem): boolean => settingItem.type === EnumResultSettingType.COLUMN && settingItem.parentId === tableId
                        )
                        .map((columnItem, index): JSX.Element => {
                            return (
                                <VariablePicker
                                    key={index}
                                    KortexTextFieldProps={{
                                        label: columnItem.label,
                                        className: classes.columnInfo,
                                        variant: "standard",
                                        changedDelayMS: INPUT_DELAY_MS,
                                    }}
                                    userAccessLevel={userAccessLevel}
                                    value={columnsValue[index]}
                                    onChange={handleColumnsStoreToChange(index)}
                                />
                            );
                        })}
                </div>
            </div>
        </Paper>
    );
}
