/*
 * ENUM
 * This enum is only used in the AOSError class
 * This enum is in this file and not the class to modify a single file when adding or updating an enum
 */
export var ExplanationKeyEnum;
(function (ExplanationKeyEnum) {
    ExplanationKeyEnum["ACCESS_UNAUTHORIZED"] = "explanation.accessUnauthorized";
    ExplanationKeyEnum["APPROVAL_NOT_REQUIRED"] = "explanation.approvalNotRequired";
    ExplanationKeyEnum["CANNOT_COPY_EMPTY_PAYLOAD"] = "explanation.cannotCopyEmptyPayload";
    ExplanationKeyEnum["CANNOT_COPY_UNCOPYABLE_PROCESS_ACTIONS"] = "explanation.cannotCopyUncopyableProcessActions";
    ExplanationKeyEnum["CANNOT_CREATE_ROUTING_TRAINING"] = "explanation.cannotCreateRoutingTraining";
    ExplanationKeyEnum["CANNOT_DELETE_ROLE_USED_BY_A_USER"] = "explanation.cannotDeleteRoleUsedByAUser";
    ExplanationKeyEnum["CHANGELOG_BLANK"] = "explanation.changelogBlank";
    ExplanationKeyEnum["CANNOT_FIND_TREE_FILE_TO_UPDATE"] = "explanation.cannotFindTreeFileToUpdate";
    ExplanationKeyEnum["COLUMNS_COUNT_HAS_EXCEEDED_LIMIT"] = "explanation.columnsCountHasExceededLimit";
    ExplanationKeyEnum["COULD_NOT_CANCEL_VERSION_NOT_APPROVED_OR_ON_HOLD"] = "explanation.couldNotCancelVersionNotApprovedOrOnHold";
    ExplanationKeyEnum["COULD_NOT_CANCEL_VERSION_ALREADY_RELEASED"] = "explanation.couldNotCancelVersionAlreadyReleased";
    ExplanationKeyEnum["COULD_NOT_GET_ERP_SETTING"] = "explanation.couldNotGetErpSetting";
    ExplanationKeyEnum["COULD_NOT_HOLD_VERSION_NOT_APPROVED"] = "explanation.couldNotHoldVersionNotApproved";
    ExplanationKeyEnum["COULD_NOT_HOLD_VERSION_ALREADY_RELEASED"] = "explanation.couldNotHoldVersionAlreadyReleased";
    ExplanationKeyEnum["COULD_NOT_UPDATE_JOB_ARCHIVED"] = "explanation.couldNotUpdateJobArchived";
    ExplanationKeyEnum["DATA_BASE_QUERY_FAILED"] = "explanation.databaseQueryFailed";
    ExplanationKeyEnum["ELECTRONIC_SIGNATURE_CONSENT_UNCHECKED"] = "explanation.electronicSignatureConsentUnchecked";
    ExplanationKeyEnum["ERP_QUERY_FAILED"] = "explanation.erpQueryFailed";
    ExplanationKeyEnum["EXECUTION_ERROR"] = "explanation.executionError";
    ExplanationKeyEnum["EXECUTION_NOT_ASSOCIATED_TO_REWORKID"] = "explanation.executionNotAssociatedToReworkId";
    ExplanationKeyEnum["INFORMATION_INCORRECT_OR_MISSING"] = "explanation.informationIncorrectOrMissing";
    ExplanationKeyEnum["INVALID_PASSWORD"] = "explanation.invalidPassword";
    ExplanationKeyEnum["INVALID_PROCESS_VERSION_STATUS_FOR_TRAINING_REQUIREMENT_UPDATE"] = "explanation.invalidProcessVersionStatusForTrainingRequirementUpdate";
    ExplanationKeyEnum["INVALID_USERCODE"] = "explanation.invalidUsercode";
    ExplanationKeyEnum["INVALID_USERNAME"] = "explanation.invalidUsername";
    ExplanationKeyEnum["INVALID_USERNAME_OR_PASSWORD"] = "explanation.invalidUsernameOrPassword";
    ExplanationKeyEnum["INVALID_RELEASE_DATE"] = "explanation.invalidReleaseDate";
    ExplanationKeyEnum["INVALID_ROLE"] = "explanation.invalidRole";
    ExplanationKeyEnum["INVALID_VARIABLE_IDENTIFIER_PREFIX_SYS_"] = "explanation.invalidVariableIdentifierPrefixSys";
    ExplanationKeyEnum["INVALID_VARIABLE_IDENTIFIER_ALPHANUMERIC_UNDERSCORE"] = "explanation.invalidVariableIdentifierAlphanumericUnderscore";
    ExplanationKeyEnum["ITEM_STATUS_NOT_EXIST_OR_ARCHIVED"] = "explanation.itemStatusNotExistOrArchived";
    ExplanationKeyEnum["JOB_PROCESS_QTY_MAX_REACH"] = "explanation.jobProcessQtyMaxReach";
    ExplanationKeyEnum["JOB_QTY_MAX_REACH"] = "explanation.jobQtyMaxReach";
    ExplanationKeyEnum["JOB_ALREADY_CREATED"] = "explanation.jobAlreadyCreated";
    ExplanationKeyEnum["TRAINING_ALREADY_CREATED"] = "explanation.trainingAlreadyCreated";
    ExplanationKeyEnum["LEAST_ONE_PROCESS_VERSION_NOT_APPROVED_IN_ROUNTIN"] = "explanation.leastOnProcessVersionNotApprovedInRouting";
    ExplanationKeyEnum["MISSING_PASSWORD"] = "explanation.missingPassword";
    ExplanationKeyEnum["NO_ACCESS_RIGHT_AVALABLE_OR_FILE_ALREADY_ARCHIVED"] = "explanation.noAccessRightAvalableOrFileAlreadyArchived";
    ExplanationKeyEnum["NO_FOLDER_HAS_BEEN_SELECTED"] = "explanation.noFolderHasBeenSelected";
    ExplanationKeyEnum["NO_ORGANIZATION_SETTING_CONFIGURED"] = "explanation.noOrganizationSettingConfigured";
    ExplanationKeyEnum["NO_PROCESS_APPROVED_OR_RELEASE_IN_ROUTING"] = "explanation.noProcessApprovedOrReleaseInRouting";
    ExplanationKeyEnum["NO_PROCESS_VERSION_APPROVED"] = "explanation.noProcessVersionApproved";
    ExplanationKeyEnum["NO_PROCESS_VERSION_RELEASED"] = "explanation.noProcessVersionReleased";
    ExplanationKeyEnum["NO_RUNNER_AVAILABLE"] = "explanation.noRunnerAvailable";
    ExplanationKeyEnum["NOT_ALL_MANDATORY_APPROVAL_GROUPS"] = "explanation.notAllMandatoryApprovalGroups";
    ExplanationKeyEnum["NUMBER_OF_VERSION_BLANK"] = "explanation.numberOfVersionBlank";
    ExplanationKeyEnum["PASSWORD_TEMPORARY_DISABLE"] = "explanation.passwordTemporaryDisabled";
    ExplanationKeyEnum["SELF_APPROVED_PROCESS_VERSION"] = "explanation.selfApprovedProcessVersion";
    ExplanationKeyEnum["STATUS_REWORK_IS_ARCHIVED"] = "explanation.statusReworkIsArchived";
    ExplanationKeyEnum["PROCESS_VERSION_IS_NOT_DRAFT"] = "explanation.processVersionIsNotDraft";
    ExplanationKeyEnum["REFERENCE_ID_BLANK"] = "explanation.referenceIdBlank";
    ExplanationKeyEnum["STORE_RESULT_ERROR"] = "explanation.storeResultError";
    ExplanationKeyEnum["TRAINER_NOT_QUALIFIED"] = "explanation.trainerNotQualified";
    ExplanationKeyEnum["UNABLE_CREATE_TRAINING_CERTIFICATE"] = "explanation.unableCreateTrainingCertificate";
    ExplanationKeyEnum["UNABLE_CREATE_TRAINING_READING_CERTIFICATE"] = "explanation.unableCreateTrainingReadingCertificate";
    ExplanationKeyEnum["UNABLE_CREATE_TRAINING_READING_CERTIFICATE_MISSING_TRAINING_CERTIFICATE"] = "explanation.unableCreateTrainingReadingCertificateMissingTrainingCertificate";
    ExplanationKeyEnum["UNABLE_CREATE_TRAINING_CERTIFICATE_TRAINER_NOT_QUALIFIED"] = "explanation.unableCreateTrainingCertificateTrainerNotQualified";
    ExplanationKeyEnum["UNABLE_CREATE_TRAINING_CERTIFICATE_TRAINER_IS_TRAINEE"] = "explanation.unableCreateTrainingCertificateTrainerIsTrainee";
    ExplanationKeyEnum["UNABLE_CREATE_TRAINING_CERTIFICATE_AUDITOR_IS_CERTIFIED"] = "explanation.unableCreateTrainingCertificateAuditorIsCertified";
    ExplanationKeyEnum["UNABLE_CREATE_TRAINING_CERTIFICATION"] = "explanation.unableCreateTrainingCertification";
    ExplanationKeyEnum["UNABLE_CREATE_TRAINING_CERTIFICATION_AUDITOR_NOT_QUALIFIED"] = "explanation.unableCreateTrainingCertificationAuditorNotQualified";
    ExplanationKeyEnum["UNABLE_SIGN_TRAINING_CERTIFICATE"] = "explanation.unableSignTrainingCertificate";
    ExplanationKeyEnum["UNABLE_SIGN_TRAINING_CERTIFICATION"] = "explanation.unableSignTrainingCertification";
    ExplanationKeyEnum["UNABLE_PERFORM_ACTION_VERSION_NOT_CORRECT"] = "explanation.unablePerformActionVersionNotCorrect";
    ExplanationKeyEnum["UNHANDLED_ERROR"] = "explanation.unhandledError";
    ExplanationKeyEnum["UNKNOWN_ERROR"] = "explanation.unknownError";
    ExplanationKeyEnum["USER_NOT_ACTIVE"] = "explanation.userNotActive";
    ExplanationKeyEnum["VARIABLE_SAME_IDENTIFIER"] = "explanation.variableSameIdentifier";
    ExplanationKeyEnum["VERSION_ALREADY_APPROVED"] = "explanation.versionAlreadyApproved";
    ExplanationKeyEnum["VERSION_ALREADY_PENDING_APPROVAL_OR_WAITING_RELEASED"] = "explanation.versionAlreadyPendingApprovedOrWaitingReleased";
    ExplanationKeyEnum["VERSION_ALREADY_EXISTS"] = "explanation.versionAlreadyExists";
})(ExplanationKeyEnum || (ExplanationKeyEnum = {}));
