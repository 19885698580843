import { KortexTextField } from "@aos/react-components";
import { OrUndefined } from "@kortex/aos-api-client";
import { IBomFollowUp, IWoBomItem } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { bomInsertFollowUp } from "@kortex/aos-ui/redux/bom-manager/bom-thunks";
import { IconButton, Menu, PopoverPosition, TableCell, TableRow, makeStyles } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { FC, useState } from "react";

import { usePlayerContext } from "../../context";
import {
    PlayerControlsBomItemRowMenuMultipleTraceability,
    PlayerControlsBomItemRowMenuReplacement,
} from "../../controls/content/bom/bomItems/body/item/row/menu";
import { PlayerControlsBomItemRowMenuEdit } from "../../controls/content/bom/bomItems/body/item/row/menu/edit";
import { PlayerControlsBomItemRowMenuOverconsumption } from "../../controls/content/bom/bomItems/body/item/row/menu/overconsumption";
import ItemCardOfTooltipPlayer from "../../controls/content/bom/utilities/ItemCardOfTooltipPlayer";

const useStyles = makeStyles({
    root: {}, // To overwrite with props
    traceability: {
        margin: 0,
    },
    iconButton: {
        padding: 0,
        "&:disabled": {
            padding: 0,
        },
    },
    container: {
        width: "100%",
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    item: IWoBomItem;
    itemIndex: number;
    processActionLabel: string;
    processActionStepId: number;
    processActionStepLabel: string;
}

const ProcessBomDialogRow: FC<IOwnProps> = (props) => {
    const { item, itemIndex, processActionLabel, processActionStepId, processActionStepLabel } = props;

    const classes = useStyles(props);
    const dispatch = useThunkDispatch();
    const { jobProcessInfo, playerState } = usePlayerContext();

    const [menuPosition, setMenuPosition] = useState<PopoverPosition | null>(null);
    const [followUpLineSelected, setFollowUpLineSelected] = useState<OrUndefined<IBomFollowUp>>(undefined);

    const optionsButtonDisabled = !item.followUp[processActionStepId];

    /**
     * Closes the menu
     */
    const handleMenuClose = (): void => {
        setMenuPosition(null);
        setFollowUpLineSelected(undefined);
    };

    /**
     * Opens the options menu
     */
    const handleMoreClick =
        (followUp: OrUndefined<IBomFollowUp>): ((event: React.MouseEvent<HTMLElement>) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            setFollowUpLineSelected(followUp);
            setMenuPosition({
                left: event.clientX,
                top: event.clientY,
            });
        };

    /**
     * Changes the traceability
     */
    const handleTraceabilityChange = async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
        const newValue = event.target.value;

        if (!newValue.trim().length) return void 0;

        dispatch(
            bomInsertFollowUp({
                jobRefId: jobProcessInfo!.job.jobRefId,
                partNumber: item.partNumber,
                processActionStepId,
                quantity: item.quantity,
                traceability: newValue,
                trackingId: playerState.processState.serialNumber,
                trackingInstances: playerState.processState.serialNumberInstances.toString(),
            })
        );
    };

    /**
     * Renders a table row for each traceability
     */
    const renderRows = (): JSX.Element => {
        const elements: JSX.Element[] = [];

        if (item.followUp[processActionStepId]) {
            for (const [index, line] of item.followUp[processActionStepId].entries()) {
                elements.push(
                    <TableRow key={`processBomDialogRow${itemIndex}-${index}`} className={classes.root}>
                        {index === 0 ? (
                            <>
                                {/* ACTION LABEL */}
                                <TableCell width="10%" component="th" scope="row">
                                    {processActionLabel}
                                </TableCell>
                                {/* STEP LABEL */}
                                <TableCell width="10%" component="th" scope="row">
                                    {processActionStepLabel}
                                </TableCell>
                                {/* PART NUMBER */}
                                <ItemCardOfTooltipPlayer item={item} />
                                {/* DESCRIPTION */}
                                <TableCell width="35%" id={`description${line.partNumber}${line.processActionStepId}Id`}>
                                    {item.description}
                                </TableCell>
                            </>
                        ) : (
                            <TableCell colSpan={4} />
                        )}
                        {/* QUANTITY */}
                        <TableCell width="5%" align={"center"} id={`quantity${line.partNumber}${line.processActionStepId}Id`}>
                            {`${line.quantityOver > 0 ? "+" : ""}${line.quantity}`}
                        </TableCell>
                        {/* TRACEABILITY */}
                        <TableCell width="15%" id={`traceability${item.partNumber}Id`}>
                            <KortexTextField
                                TextFieldProps={{
                                    id: `traceabilityInput${line.partNumber}${line.processActionStepId}Id`,
                                    disabled: true,
                                    placeholder: item.lotSerialType,
                                    className: classes.traceability,
                                }}
                                value={line.traceability}
                                variant="standard"
                            />
                        </TableCell>

                        {/* OPTIONS BUTTON */}
                        <TableCell width="5%" align="center">
                            <IconButton
                                className={classes.iconButton}
                                disabled={optionsButtonDisabled}
                                id={`playerControlsBomItemMore${line.partNumber}${line.processActionStepId}Id`}
                                onClick={handleMoreClick(line)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                );
            }
        } else {
            elements.push(
                <TableRow key={item.partNumber} className={classes.root}>
                    {/* ACTION LABEL */}
                    <TableCell width="10%" component="th" scope="row">
                        {processActionLabel}
                    </TableCell>
                    {/* STEP LABEL */}
                    <TableCell width="10%" component="th" scope="row">
                        {processActionStepLabel}
                    </TableCell>
                    {/* PART NUMBER */}
                    <ItemCardOfTooltipPlayer item={item} />
                    {/* DESCRIPTION */}
                    <TableCell width="35%" id={`description${item.partNumber}Id`}>
                        {item.description}
                    </TableCell>
                    {/* QUANTITY */}
                    <TableCell width="5%" align={"center"} id={`quantity${item.partNumber}Id`}>
                        {item.quantity}
                    </TableCell>
                    {/* TRACEABILITY */}
                    <TableCell width="15%" id={`traceability${item.partNumber}Id`}>
                        <KortexTextField
                            TextFieldProps={{
                                id: `traceabilityInput${item.partNumber}Id`,
                                placeholder: item.lotSerialType.length === 0 ? "NONE" : item.lotSerialType,
                                disabled: item.lotSerialType.length === 0 || item.lotSerialType === "NONE",
                                className: classes.traceability,
                            }}
                            onBlur={handleTraceabilityChange}
                            value={""}
                            variant="standard"
                        />
                    </TableCell>
                    {/* OPTIONS BUTTON */}
                    <TableCell width="5%" align="center">
                        <IconButton
                            className={classes.iconButton}
                            disabled={optionsButtonDisabled}
                            id={`playerControlsBomItemMore${item.partNumber}Id`}
                            onClick={handleMoreClick(followUpLineSelected)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        }

        return <>{elements}</>;
    };

    return (
        <>
            {/* ROWS */}
            {renderRows()}
            {/* OPTIONS MENU */}
            {!optionsButtonDisabled && followUpLineSelected ? (
                <Menu
                    anchorPosition={menuPosition ?? undefined}
                    anchorReference="anchorPosition"
                    open={menuPosition !== null}
                    onClose={handleMenuClose}
                    id="itemStepMenuId"
                >
                    <PlayerControlsBomItemRowMenuEdit
                        followUp={followUpLineSelected}
                        closeMenu={handleMenuClose}
                        key={"playerControlsBomItemRowMenuEdit"}
                    />
                    <PlayerControlsBomItemRowMenuMultipleTraceability
                        closeMenu={handleMenuClose}
                        followUp={followUpLineSelected}
                        key={"playerControlsBomItemRowMenuMultipleTraceability"}
                    />
                    <PlayerControlsBomItemRowMenuReplacement
                        followUp={followUpLineSelected}
                        closeMenu={handleMenuClose}
                        key={"playerControlsBomItemRowMenuReplacement"}
                    />
                    <PlayerControlsBomItemRowMenuOverconsumption
                        followUp={followUpLineSelected}
                        closeMenu={handleMenuClose}
                        key={"playerControlsBomItemRowMenuOverconsumption"}
                    />
                </Menu>
            ) : null}
        </>
    );
};

export default ProcessBomDialogRow;
