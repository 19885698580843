import { theme } from "@aos/react-components";
import { IWoBomItem } from "@kortex/aos-common";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
} from "@material-ui/core";
import React from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { usePlayerContext } from "../../context";

import ProcessBomDialogRow from "./ProcessBomDialogRow";

const useStyles = makeStyles({
    header: {
        backgroundColor: theme.palette.grey[300],
    },
    itemEven: {
        backgroundColor: theme.palette.grey[100],
    },
    itemOdd: {
        backgroundColor: theme.palette.grey[200],
    },
    dialogActions: {
        display: "flex",
        flexDirection: "column-reverse",
        backgroundColor: theme.palette.grey[200],
        padding: "10px",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 30px",
    },
});

interface IOwnProps {
    open: boolean;
    onClose: () => void;
}

export default function ProcessBomDialog(props: IOwnProps): JSX.Element {
    const { open, onClose } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const { bom, process, playerState } = usePlayerContext();

    /**
     * handle the continue
     */
    const handleClose = (): void => {
        onClose();
    };

    /**
     * Render all line of ProcessBomDialogRow
     */
    const render = (): JSX.Element[] => {
        // Initialize an object to store BOM items for this step
        const rows: JSX.Element[] = [];

        if (!bom) return rows;

        // Get the BOM items that have been assigned to the process
        for (const action of process?.actions ?? []) {
            if (action.type !== "core-work-instructions") continue;

            let actionItemCounter = 0; // Will be used to display the action name only for the first item of the action

            for (const step of action.steps) {
                for (const [itemIndex, item] of Object.values(step.config.bomItems).entries()) {
                    const bomItem: IWoBomItem = {
                        ...bom.items[item.partNumber],
                        quantity: item.quantity,
                    };

                    rows.push(
                        <ProcessBomDialogRow
                            classes={{
                                root: rows.length % 2 === 0 ? classes.itemEven : classes.itemOdd,
                            }}
                            item={bomItem}
                            itemIndex={rows.length}
                            key={`processBomDialogRow${bomItem.partNumber}-${step.processActionStepId}`}
                            processActionLabel={
                                // Do not display the action name if it's the same as the previous
                                actionItemCounter === 0 ? action.label : ""
                            }
                            processActionStepId={step.processActionStepId}
                            processActionStepLabel={
                                // Do not display the step name if it's the same as the previous
                                itemIndex === 0 ? step.label : ""
                            }
                        />
                    );

                    actionItemCounter++;
                }
            }
        }

        // Return the array of rows
        return rows;
    };

    return (
        <div>
            {bom && playerState.processState.serialNumber && (
                <Dialog open={open} disableAutoFocus={true} fullWidth={true} maxWidth={"lg"} id="ProcessBomDialogId">
                    <DialogContent>
                        <div>
                            <Typography variant="h4">{translate("player.bom.processBom")}</Typography>
                        </div>
                        <Table>
                            <TableHead className={classes.header}>
                                <TableRow>
                                    <TableCell width="10%">{translate("player.bom.actionName")}</TableCell>
                                    <TableCell width="10%">{translate("player.bom.stepName")}</TableCell>
                                    <TableCell width="10%">{translate("player.bom.partNumber")}</TableCell>
                                    <TableCell width="35%">{translate("player.bom.description")}</TableCell>
                                    <TableCell width="5%" align={"center"}>
                                        {translate("player.bom.quantity")}
                                    </TableCell>
                                    <TableCell colSpan={2} width="20%">
                                        {translate("player.bom.traceability")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{render()}</TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            id="closeButtonId"
                            variant="contained"
                            color="secondary"
                            onClick={handleClose}
                            className={classes.dialogButtons}
                        >
                            <Typography>{translate("general.close")}</Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
