import { IBomFollowUp, IWoBomTracked } from "@kortex/aos-common";
import { deepClone } from "@kortex/utilities";

import { BomActionType, BomActions, BomState } from "./bom-types";

const initialState: BomState = {
    boms: [],
};

export function bomReducer(state: BomState = initialState, action: BomActions): BomState {
    let bom: IWoBomTracked | undefined;
    let followUp: IBomFollowUp;
    let followUpIndex: number;
    let updatedState: BomState;
    let updatedCount = 0;

    switch (action.type) {
        case BomActionType.CLEAR:
            return { boms: [] };
        case BomActionType.EDIT_ITEM:
            updatedState = deepClone(state);
            followUp = action.value;
            bom = updatedState.boms.find((b) => b.jobRefId === action.value.jobRefId && b.trackingId === action.value.trackingId);

            if (!bom) return state; // Return original state

            // Find which follow-up to update
            followUpIndex = bom.items[followUp.partNumber].followUp[followUp.processActionStepId].findIndex(
                (followUpToUpdate) => followUpToUpdate.bomFollowUpId === followUp.bomFollowUpId
            );

            bom.items[followUp.partNumber].followUp[followUp.processActionStepId][followUpIndex] = {
                ...followUp, // Update the follow-up
                bomFollowUpHistory: [
                    ...bom.items[followUp.partNumber].followUp[followUp.processActionStepId][followUpIndex].bomFollowUpHistory,
                    ...followUp.bomFollowUpHistory, // Add the new follow-up history
                ],
            };

            return updatedState;
        case BomActionType.INSERT_ITEM:
            updatedState = deepClone(state);
            followUp = action.value;
            bom = updatedState.boms.find((b) => b.jobRefId === action.value.jobRefId && b.trackingId === action.value.trackingId);

            if (!bom) return state; // Return original state

            // Insert follow-up
            if (bom.items[followUp.partNumber].followUp[followUp.processActionStepId]) {
                bom.items[followUp.partNumber].followUp[followUp.processActionStepId].push(followUp);
            } else {
                bom.items[followUp.partNumber].followUp[followUp.processActionStepId] = [followUp];
            }

            return updatedState;
        case BomActionType.MULTIPLE_TRACEABILITIES_ITEM:
            updatedState = deepClone(state);

            for (const followUp of action.value) {
                bom = updatedState.boms.find((b) => b.jobRefId === followUp.jobRefId && b.trackingId === followUp.trackingId);

                if (!bom) continue;

                // Find which follow-up to update
                followUpIndex = bom.items[followUp.partNumber].followUp[followUp.processActionStepId].findIndex(
                    (followUpToUpdate) => followUpToUpdate.bomFollowUpId === followUp.bomFollowUpId
                );

                if (followUpIndex >= 0) {
                    // Original follow-up
                    bom.items[followUp.partNumber].followUp[followUp.processActionStepId][followUpIndex] = {
                        ...followUp, // Update the follow-up
                        bomFollowUpHistory: [
                            ...bom.items[followUp.partNumber].followUp[followUp.processActionStepId][followUpIndex].bomFollowUpHistory,
                            ...followUp.bomFollowUpHistory, // Add the new follow-up history
                        ],
                    };
                } else {
                    // New follow-up
                    bom.items[followUp.partNumber].followUp[followUp.processActionStepId].push(followUp);
                }

                updatedCount++;
            }

            return updatedCount > 0 ? updatedState : state; // If updatedCount is 0, do not trigger redux update by returning original state
        case BomActionType.OVERCONSUME_ITEM:
            updatedState = deepClone(state);
            followUp = action.value;
            bom = updatedState.boms.find((b) => b.jobRefId === action.value.jobRefId && b.trackingId === action.value.trackingId);

            if (!bom) return state; // Return original state

            bom.items[followUp.partNumber].followUp[followUp.processActionStepId].push(followUp);

            return updatedState;
        case BomActionType.REPLACE_ITEM:
            updatedState = deepClone(state);
            followUp = action.value;
            bom = updatedState.boms.find((b) => b.jobRefId === action.value.jobRefId && b.trackingId === action.value.trackingId);

            if (!bom) return state; // Return original state

            // Find which follow-up to update
            followUpIndex = bom.items[followUp.partNumber].followUp[followUp.processActionStepId].findIndex(
                (followUpToUpdate) => followUpToUpdate.bomFollowUpId === followUp.bomFollowUpId
            );

            bom.items[followUp.partNumber].followUp[followUp.processActionStepId][followUpIndex] = {
                ...followUp, // Update the follow-up
                bomFollowUpHistory: [
                    ...bom.items[followUp.partNumber].followUp[followUp.processActionStepId][followUpIndex].bomFollowUpHistory,
                    ...followUp.bomFollowUpHistory, // Add the new follow-up history
                ],
            };

            return updatedState;
        case BomActionType.SET_BOMS:
            return {
                ...state,
                boms: action.value,
            };
        default:
            return state;
    }
}
