import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { IBomFollowUp, IBomFollowUpDbModel } from "@kortex/aos-common";
import KortexLabelText from "@kortex/aos-ui/components/core/KortexLabelText";
import { usePlayerContext } from "@kortex/aos-ui/components/core/ProcessPlayer/context";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { bomMultipleTraceabilitiesItem } from "@kortex/aos-ui/redux/bom-manager/bom-thunks";
import { deepClone } from "@kortex/utilities";
import { Button, CircularProgress, IconButton, MenuItem, Typography, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { FC, useState } from "react";

const useStyles = makeStyles({
    dialogContent: {
        display: "flex",
        gridGap: "5px",
    },
    quantity: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 20px",
    },
    buttonIcon: {
        paddingRight: "10px",
    },
    typographyMaximumQuantity: {
        margin: "0 0 10px 5px",
    },
});

type AddedFollowUp = Pick<IBomFollowUpDbModel, "partNumber" | "quantity" | "traceability">;

interface IOwnProps {
    closeMenu: () => void;
    followUp: IBomFollowUp;
}

const PlayerControlsBomItemRowMenuMultipleTraceability: FC<IOwnProps> = (props) => {
    const { followUp, closeMenu } = props;

    const DEFAULT_NEW_FOLLOW_UP: AddedFollowUp = {
        partNumber: followUp.partNumber,
        quantity: 1,
        traceability: "",
    };

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const { bom } = usePlayerContext();
    const translate = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);
    const [newfollowUps, setNewfollowUps] = useState<AddedFollowUp[]>([DEFAULT_NEW_FOLLOW_UP]);
    const [open, setOpen] = useState(false);
    const [originalFollowUpQuantity, setOriginalFollowUpQuantity] = useState<number>(followUp.quantity - 1);

    const isFollowUpOverconsumption = followUp.quantityOver > 0;
    const maxQuantity = isFollowUpOverconsumption ? followUp.quantityOver : bom?.items[followUp.partNumber].quantity ?? 0;
    const totalQuantity =
        originalFollowUpQuantity + // Quantity of the item we want to 'Multiple Traceability'
        newfollowUps.map((row) => row.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0); // Sum of quantity for all traceability we want to add
    const confirmButtonDisabled =
        !newfollowUps.length || // There must be at least 1 new traceability
        maxQuantity !== totalQuantity || // Total quantity must be equal to the maximum quantity
        newfollowUps.some((row) => !row.traceability?.trim() || row.quantity <= 0); // New traceabiliies must be valid

    /**
     * Handle close dialog and menu
     */
    const handleClose = (): void => {
        setOpen(false);
        closeMenu();
    };

    /**
     * Handle to save the multitraceability and close dialog
     */
    const handleConfirm = async (): Promise<void> => {
        setLoading(true);

        dispatch(
            bomMultipleTraceabilitiesItem({
                newItems: newfollowUps.map((row) => ({
                    quantity: row.quantity,
                    traceability: row.traceability,
                })),
                originalItem: {
                    bomFollowUpId: followUp.bomFollowUpId,
                    quantity: originalFollowUpQuantity,
                },
            })
        )
            .then(handleClose)
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Handle open dialog
     */
    const handleOpen = (): void => {
        setOpen(true);
    };

    /**
     * Handles traceability change
     */
    const handleNewFollowUpTraceabilityChange =
        (index: number): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const followUp = deepClone(newfollowUps);
            followUp[index].traceability = event.target.value;
            setNewfollowUps(followUp);
        };

    /**
     * Increases the quantity of a new traceability
     */
    const handleIncreaseQuantityNewTraceability = (index: number) => (): void => {
        const followUp = deepClone(newfollowUps);
        followUp[index].quantity = followUp[index].quantity + 1;
        setNewfollowUps(followUp);
    };

    /**
     * Decreases the quantity of a new traceability
     */
    const handleDecreaseQuantityNewTraceability = (index: number) => (): void => {
        const followUp = deepClone(newfollowUps);
        followUp[index].quantity = followUp[index].quantity - 1;
        setNewfollowUps(followUp);
    };

    /**
     * Increases the quantity of the original traceability
     */
    const handleIncreaseQuantityOriginalTraceability = (): void => {
        setOriginalFollowUpQuantity((prevState) => prevState + 1);
    };

    /**
     * Decreases the quantity of the original traceability
     */
    const handleDecreaseQuantityOriginalTraceability = (): void => {
        setOriginalFollowUpQuantity((prevState) => prevState - 1);
    };

    /**
     * Add new traceability (new line)
     */
    const handleAddTraceability = (): void => {
        const newLines = deepClone(newfollowUps);
        newLines.push(DEFAULT_NEW_FOLLOW_UP);
        setNewfollowUps(newLines);
    };

    /**
     * Deletes a row
     */
    const handleDeleteRow =
        (index: number): (() => void) =>
        (): void => {
            const updatedRows = deepClone(newfollowUps);
            updatedRows.splice(index, 1);
            setNewfollowUps(updatedRows);
        };

    return (
        <>
            <MenuItem disabled={followUp.quantity <= 1} id="playerControlsBomItemRowMenuMultipleTraceabilityId" onClick={handleOpen}>
                {translate("player.bom.multiTraceability")}
            </MenuItem>
            <KortexDialogConfirmation
                closeOnEscape={true}
                confirmDisabled={confirmButtonDisabled}
                onCancel={handleClose}
                onConfirm={handleConfirm}
                open={open}
                textLabels={{
                    cancelButtonLabel: translate("general.cancel"),
                    proceedButtonLabel: translate("general.confirm"),
                    titleLabel: translate("player.bom.multiTraceability"),
                }}
                textLabelsIcons={{
                    proceedButtonIcon: loading ? <CircularProgress /> : undefined,
                }}
            >
                <div>
                    <Typography
                        className={classes.typographyMaximumQuantity}
                        color={maxQuantity === totalQuantity ? "textPrimary" : "error"}
                        variant="body2"
                    >{`${translate("bomPage.bomTable.quantitySum")} ${maxQuantity}`}</Typography>
                </div>
                <div className={classes.dialogContent}>
                    {/* TRACEABILITY */}
                    <KortexTextField
                        label={translate("player.bom.traceability")}
                        TextFieldProps={{
                            disabled: true,
                        }}
                        value={followUp.traceability}
                        variant="outlined"
                    />
                    {/* QUANTITY */}
                    <div className={classes.quantity}>
                        <IconButton id="addQtyButtonId" onClick={handleIncreaseQuantityOriginalTraceability}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                        <KortexLabelText label={translate("player.bom.quantity")} text={`${originalFollowUpQuantity}`} />
                        <IconButton id="removeQtyButtonId" onClick={handleDecreaseQuantityOriginalTraceability}>
                            <RemoveIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
                {/* NEW TRACEABILITY */}
                <div>
                    {newfollowUps.map(
                        (line, lineIndex): JSX.Element => (
                            <div className={classes.dialogContent} key={lineIndex}>
                                <KortexTextField
                                    label={translate("player.bom.traceability")}
                                    onChange={handleNewFollowUpTraceabilityChange(lineIndex)}
                                    value={line.traceability}
                                    variant="outlined"
                                />
                                {/* NEW QUANTITY */}
                                <div className={classes.quantity}>
                                    <IconButton id="addQtyButtonId" onClick={handleIncreaseQuantityNewTraceability(lineIndex)}>
                                        <AddIcon fontSize="small" />
                                    </IconButton>
                                    <KortexLabelText label={translate("player.bom.quantity")} text={`${line.quantity}`} />
                                    <IconButton id="removeQtyButtonId" onClick={handleDecreaseQuantityNewTraceability(lineIndex)}>
                                        <RemoveIcon fontSize="small" />
                                    </IconButton>
                                </div>
                                {lineIndex > 0 ? (
                                    <IconButton
                                        id={`playerControlsBomItemRowMenuMultipleTraceabilitiesDeleteRow${lineIndex}Id`}
                                        onClick={handleDeleteRow(lineIndex)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                ) : null}
                            </div>
                        )
                    )}
                </div>
                <Button
                    id="addTraceabilityButtonId"
                    variant="contained"
                    color="secondary"
                    disabled={false} // faire en fonction de la quantity par step
                    onClick={handleAddTraceability}
                    className={classes.dialogButtons}
                >
                    <AddIcon className={classes.buttonIcon} /> {translate("player.bom.addNewLine")}
                </Button>
            </KortexDialogConfirmation>
        </>
    );
};

export default PlayerControlsBomItemRowMenuMultipleTraceability;
