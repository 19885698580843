import { IVersion } from "@kortex/aos-common";

export const version: IVersion = {
    major: 1,
    minor: 18,
    patch: 0,
    pre: "r",
    build: 3,
    meta: "",
};
