import { theme } from "@aos/react-components";
import { usePlayerContext } from "@kortex/aos-ui/components/core/ProcessPlayer/context";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Collapse, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import { PlayerControlsBomItem } from "./item";

const useStyles = makeStyles({
    header: {
        backgroundColor: theme.palette.grey[300],
    },
    itemEven: {
        backgroundColor: theme.palette.grey[100],
    },
    itemOdd: {
        backgroundColor: theme.palette.grey[200],
    },
    tableCellMain: {
        padding: 0,
    },
});

interface IOwnProps {
    expanded: boolean;
}

const PlayerControlsBomItemsBody: FC<IOwnProps> = (props) => {
    const { expanded } = props;

    const classes = useStyles();
    const { bomStepItems } = usePlayerContext();
    const translate = useTranslate();

    return (
        <TableRow>
            <TableCell className={classes.tableCellMain} colSpan={4}>
                <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
                    <Table>
                        <TableHead className={classes.header}>
                            <TableRow>
                                <TableCell align="left" width={"5px"}>
                                    {translate("player.bom.bubbleNumber")}
                                </TableCell>
                                <TableCell align="left">{translate("player.bom.partNumber")}</TableCell>
                                <div>
                                    <TableCell align="left">{translate("player.bom.quantity")}</TableCell>
                                    <TableCell align="left" colSpan={2}>
                                        {translate("player.bom.traceability")}
                                    </TableCell>
                                </div>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(bomStepItems).map((item, index) => (
                                <PlayerControlsBomItem
                                    classes={{
                                        root: index % 2 === 0 ? classes.itemEven : classes.itemOdd,
                                    }}
                                    key={`PlayerControlsBomItem${index}`}
                                    item={item}
                                    index={index}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};

export default PlayerControlsBomItemsBody;
