import { theme } from "@aos/react-components";
import { IWoBomItem } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { TableCell, Tooltip, Typography, makeStyles, withStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
    tooltip: {
        backgroundColor: theme.palette.grey[600],
        color: theme.palette.common.white,
    },
});

export interface IOwnProps {
    item: IWoBomItem;
}

const TextOnlyTooltip = withStyles({
    tooltip: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.grey[600],
    },
})(Tooltip);

function ItemCardOfTooltipPlayer(props: IOwnProps): JSX.Element {
    const { item } = props;
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <TextOnlyTooltip
            title={
                <div className={classes.tooltip}>
                    <Typography variant="body2">{`${translate("player.bom.bubbleNumber")}: ${item.bubbleNumber}`}</Typography>
                    <Typography variant="body2">{`${translate("player.bom.partNumber")}: ${item.partNumber}`}</Typography>
                    <Typography variant="body2">{`${translate("player.bom.description")}: ${item.description}`}</Typography>
                    <Typography variant="body2">{`${translate("player.bom.quantity")}: ${item.quantity}`}</Typography>
                    <Typography variant="body2">{`${translate("player.bom.unit")}: ${item.unit}`}</Typography>
                    <Typography variant="body2">{`${translate("player.bom.location")}: ${item.location}`}</Typography>
                </div>
            }
            placement="left"
        >
            <TableCell id={`${item.partNumber}Id`}>{item.partNumber}</TableCell>
        </TextOnlyTooltip>
    );
}

export default ItemCardOfTooltipPlayer;
