import { KortexTextField, greyPalette, secondaryPalette, theme } from "@aos/react-components";
import {
    IDENTIFIER_ALPHANUMERIC_VALIDATOR_REGEX,
    IProcessVariable,
    IProcessVariableUsedIn,
    PROCESS_SYSTEM_VARIABLES,
    ProcessEditorRightsEnum,
    ProcessVariableStorer,
    ProcessVariableStoringMethod,
    ProcessVariableType,
    isVariableIdentifierAlphanumeric,
    isVariableIdentifierReserved,
    removeObjectFromArray,
    upsertObjectFromArray,
} from "@kortex/aos-common";
import { useSnackbar } from "@kortex/aos-ui/components/layout/snackbarConfigurator";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuItem,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Cancel from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import Done from "@material-ui/icons/Done";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { useForeground } from "../../../../../hooks/useForeground";
import { useTranslate } from "../../../../../hooks/useTranslate";
import {
    processVariableDelete,
    processVariableInsert,
    processVariableUpdate,
    processVariableWhereUsed,
} from "../../../../../redux/process-manager/process-thunks-process";
import { useSelectorEditedProcessId, useSelectorProcesses } from "../../../../../redux/selectors";
import { IUserRightsProps, userCanWrite } from "../../../../../utilitites/IUserRights";
import KortexSearchField from "../../../../core/KortexSearchField";

const DEFAULT_DECIMALS = 0;
const DEFAULT_DECIMALS_NUMBER = 2;
const TEXTFIELD_CHANGED_DELAY = 300;
const ACTION_SEARCH_ELEMENTS_HEIGHT = 220;

const useStyles = makeStyles({
    addVariableIconWrapper: {
        alignItems: "center",
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "center",
        whiteSpace: "nowrap",
    },
    addVariableLabel: {
        fontSize: "10px",
        textAlign: "center",
    },
    buttonIcons: {
        marginRight: "10px",
    },
    deleteDisabledMessage: {
        paddingLeft: "12px",
    },
    dialogActions: {
        backgroundColor: greyPalette[200],
        margin: "0px",
        padding: "8px 4px",
    },
    dialogButtons: {
        margin: "5px",
    },
    dialogContent: {
        padding: "0px",
        "&:first-child": {
            paddingTop: "0px",
        },
    },
    dialogHeader: {
        alignItems: "center",
        display: "inline-flex",
        width: "100%",
    },
    dialogTitle: {
        ...theme.typography.h5,
        backgroundColor: theme.palette.primary[500],
        color: theme.palette.common.white,
        display: "flex",
        padding: "20px",
    },
    searchTexField: {
        flex: 1,
        paddingLeft: "8px",
    },
    setVariableRadio: {
        alignItems: "center",
        display: "inline-flex",
        left: "16px",
        position: "absolute",
    },
    tableCellDecimals: {
        padding: "12px",
        width: "100px",
    },
    tableCellDefaultValue: {
        padding: "12px",
        width: "600px",
    },
    tableCellIdentifier: {
        padding: "12px",
        width: "300px",
    },
    tableCellType: {
        padding: "12px",
        width: "200px",
    },
    tableCellIcon: {
        margin: "0px",
        width: "48px",
    },
    tableCellTextField: {
        margin: "0px",
        width: "-webkit-fill-available",
    },
    tableHeader: {
        "& .MuiTableCell-stickyHeader": {
            backgroundColor: greyPalette[200],
        },
    },
    toolbar: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        height: "75px",
    },
    whereUsedTableCell: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
    },
    whereUsedTableRow: {
        backgroundColor: greyPalette["A100"],
    },
    whereUsedTextContainer: {
        padding: "16px",
    },
    tableContainer: {
        maxHeight: window.innerHeight - ACTION_SEARCH_ELEMENTS_HEIGHT + "px",
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
});

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    fullscreen?: boolean;
    onClear?: () => void;
    onClose: () => void;
    onSelect?: (variable: IProcessVariable, storingMethod?: ProcessVariableStoringMethod) => void;
    onVariableIdentifierChange?: (oldIdentifier: string, updatedIdentifier: string) => void;
    open: boolean;
    selectHidden?: boolean;
    storingMethod?: ProcessVariableStoringMethod;
    typeFilter?: ProcessVariableType;
}

export default function ProcessVariableManager(props: IOwnProps): JSX.Element {
    const { fullscreen, open, selectHidden, typeFilter } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const editedProcessId = useSelectorEditedProcessId();
    const snackbar = useSnackbar();
    const processes = useSelectorProcesses();
    const translate = useTranslate();

    const editedProcess = processes.find((process) => process.processId === editedProcessId);
    const readOnly = !userCanWrite(props.userAccessLevel);

    const [editedVariableIndex, setEditedVariableIndex] = useState<number>(-1);
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | undefined>(undefined);
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedVariableIndex, setSelectedVariableIndex] = useState<number>(-1);
    const [showDeleteDisabledMessage, setShowDeleteDisabledMessage] = useState<boolean>(false);
    const [storingMethod, setStoringMethod] = useState<ProcessVariableStoringMethod | undefined>(props.storingMethod);
    const [variables, setVariables] = useState<IProcessVariable[]>([]);
    const [whereUsed, setWhereUsed] = useState<IProcessVariableUsedIn[]>([]);
    const endOfListRef = useRef<HTMLDivElement | null>(null);
    const [showSystemVariable, setShowSystemVariable] = useState<boolean>(true);

    // make sure other components are disabled when the variableManager is open
    useForeground(open);

    /**
     * Convert variable value (array) to string
     *
     * @param {string | string[]} value - variable value (should be an array)
     * @returns {string} - comma separated string represention of the array
     */
    const convertArrayToString = (value: string | string[]): string => {
        return Array.isArray(value) ? value.join(", ") : value;
    };

    /**
     * Determine whether or not the variable is displayed in the table
     */
    const isVariableDisplayed = (variable: IProcessVariable): boolean => {
        if (searchValue && !variable.identifier.toLowerCase().includes(searchValue.toLowerCase())) {
            // Value from the seach bar is not included in the variable identifier
            return false;
        }

        if (typeFilter && variable.type !== typeFilter) {
            // Variable type is not the same as the type filter
            return false;
        }

        return true;
    };

    /**
     * Returns the default value depending on the variable type
     *
     * @param {ProcessVariableType} type - variable type
     * @returns {string} - default value for specific type
     */
    const getDefaultValueFromType = (type: ProcessVariableType): string => {
        switch (type) {
            case ProcessVariableType.BOOLEAN:
                return "true";
            case ProcessVariableType.JSON:
                return "{}";
            case ProcessVariableType.NUMBER:
                return "0";
            default:
                // ProcessVariableType.STRING
                return "";
        }
    };

    /**
     * Get corresponding label for a variable type
     *
     * @param {ProcessVariableType} type - variable type
     * @returns {string} - label based on type
     */
    const getTypeLabel = (type: ProcessVariableType): string => {
        switch (type) {
            case ProcessVariableType.BOOLEAN:
                return translate("processVariableManager.type.boolean");
            case ProcessVariableType.JSON:
                return translate("processVariableManager.type.json");
            case ProcessVariableType.NUMBER:
                return translate("processVariableManager.type.number");
            case ProcessVariableType.STRING:
            default:
                return translate("processVariableManager.type.string");
        }
    };

    /**
     * Remove all "Where used" rows from the table
     */
    const handleCloseWhereUsed = (event: React.MouseEvent): void => {
        event.nativeEvent.stopPropagation();
        setWhereUsed([]);
    };

    /**
     * Delete a variable from the table
     */
    const handleDeleteVariable = (): void => {
        if (editedProcessId) {
            dispatch(
                processVariableWhereUsed({ processId: editedProcessId, variableIdentifier: variables[selectedVariableIndex].identifier })
            ).then((whereUsed) => {
                if (whereUsed.length > 0) {
                    setWhereUsed([...whereUsed]);
                    setShowDeleteDisabledMessage(true);
                } else {
                    setVariables(
                        removeObjectFromArray(variables, variables[selectedVariableIndex], function (variable) {
                            return variable.identifier === this.identifier;
                        })
                    );

                    dispatch(
                        processVariableDelete({
                            processId: editedProcessId,
                            variableIdentifier: variables[selectedVariableIndex].identifier,
                        })
                    );
                    setSelectedVariableIndex(-1);
                }

                setMenuAnchor(undefined);
                setEditedVariableIndex(-1);
            });
        }
    };

    /**
     * Calls onClose when the Cancel button is clicked
     */
    const handleDialogClose = (): void => {
        props.onClose();
    };

    /**
     * Calls onClose when the Cancel button is clicked
     */
    const handleClear = (): void => {
        if (props.onClear) {
            props.onClear();
        }
    };

    /**
     * Called when a key is pressed while editing a text field
     * Delete: deletes selected row
     * Escape: closes the variable manager dialog
     *
     * @param {React.KeyboardEvent} event - keyboard event data
     */
    const handleDialogKeyDown = (event: React.KeyboardEvent): void => {
        event.nativeEvent.stopImmediatePropagation();
        if (event.key === "Escape" || event.key === "Enter") {
            props.onClose();
        } else if (!readOnly && event.key === "Delete" && selectedVariableIndex !== -1 && editedVariableIndex === -1) {
            handleDeleteVariable();
        }
    };

    /**
     * Calls onSelect when then Select button is click
     */
    const handleDialogSelect = (): void => {
        if (props.onSelect) {
            props.onSelect(
                selectedVariableIndex >= variables.length
                    ? PROCESS_SYSTEM_VARIABLES[selectedVariableIndex - variables.length]
                    : variables[selectedVariableIndex],
                storingMethod
            );
        }
    };

    /**
     * Called to edit a table row (variable)
     * Changes all label to textbox/dropdown
     * Also closes the menu
     */
    const handleEditVariable = (): void => {
        setMenuAnchor(undefined);
        setEditedVariableIndex(selectedVariableIndex);
    };

    /**
     * Insert a variable in the table
     */
    const handleInsertVariable = (): void => {
        if (editedProcess && editedProcessId) {
            dispatch(
                processVariableInsert({
                    processId: editedProcessId,
                    variableIdentifier: translate("processVariableManager.variable"),
                    type: typeFilter ?? ProcessVariableType.STRING,
                })
            ).then((insertedVariable) => {
                if (insertedVariable) {
                    setSelectedVariableIndex(variables.length);
                    setEditedVariableIndex(variables.length);
                    endOfListRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    setSearchValue("");
                }
            });
        }
    };

    /**
     * Called when closing the menu
     * Unselect the selected variable
     */
    const handleMenuClose = (): void => {
        setMenuAnchor(undefined);
        setSelectedVariableIndex(-1);
    };

    /**
     * Called when menu icon is clicked
     * Opens the menu and select the corresponding table row
     *
     * @param {number} index - row index
     */
    const handleMenuOpen =
        (index: number): ((event: React.MouseEvent) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            setMenuAnchor(event.currentTarget);
            setSelectedVariableIndex(index);
        };

    /**
     * Updates inner state when "Overwrite variable" checkbox is checked/unchecked
     */
    const handleVariableStoringMethodChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setStoringMethod(event.target.value as ProcessVariableStoringMethod);
    };

    /**
     * Called when search value is changed
     *
     * @param {string} value - change event data
     */
    const handleSearchChange = (value: string): void => {
        setSearchValue(value);

        // Unselect edited and/or selected variable when search value is changed
        setEditedVariableIndex(-1);
        setSelectedVariableIndex(-1);
        setWhereUsed([]);
        setShowDeleteDisabledMessage(false);
    };

    /**
     * Called when clicking a select input
     * Stop progagation
     */
    const handleSelectClick = (event: React.MouseEvent<HTMLInputElement>): void => {
        event.nativeEvent.stopPropagation();
    };

    /**
     * Called when double-clicking a table row
     * Edited the clicked row
     *
     * @param {number} index - row index
     */
    const handleRowDoubleClick =
        (index: number): (() => void) =>
        (): void => {
            if (readOnly) {
                return;
            }

            if (editedVariableIndex !== index) {
                setEditedVariableIndex(index);
            }
        };

    /**
     * Called when clicking a table row
     * Select the clicked row
     * If the row is already selected, unselect it
     *
     * @param {number} index - row index
     */
    const handleRowClick =
        (index: number): (() => void) =>
        (): void => {
            if (readOnly) {
                return;
            }

            if (selectedVariableIndex !== index) {
                setSelectedVariableIndex(index);
            }
        };

    /**
     * Called when clicking a text field
     * Select all text in input and stop progagation
     */
    const handleTextFieldClick = (event: React.MouseEvent<HTMLInputElement>): void => {
        event.nativeEvent.stopPropagation();
    };

    /**
     * Sends decimals value to database
     *
     * @param {number} index - row index
     * @returns {Promise<undefined>} - updates process variable
     */
    const handleVariableDecimalsBlur =
        (index: number): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            let decimals = parseInt(event.target.value);
            decimals = isNaN(decimals) ? variables[index].decimals : decimals;

            if (editedProcessId && variables[index].decimals !== decimals) {
                const updatedVariable: IProcessVariable = {
                    ...variables[index],
                    decimals,
                    defaultValue: Array.isArray(variables[index].defaultValue)
                        ? (variables[index].defaultValue as string[]).map((value) => parseFloat(value).toFixed(decimals))
                        : parseFloat(variables[index].defaultValue as string).toFixed(decimals),
                };

                setVariables(
                    upsertObjectFromArray(variables, updatedVariable, function (variable) {
                        return variable.identifier === this.identifier;
                    })
                );

                dispatch(
                    processVariableUpdate({
                        processId: editedProcessId,
                        oldVariableIdentifier: variables[index].identifier,
                        variable: updatedVariable,
                    })
                );
            }
        };

    /**
     * Sends updated default value to database
     *
     * @param {number} index - row index
     */
    const handleVariableDefaultValueBlur =
        (index: number): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            if (editedProcessId) {
                let defaultValue: string[] = [];

                if (variables[index].type === ProcessVariableType.NUMBER) {
                    defaultValue = event.target.value
                        .split(",")
                        .map((value) => {
                            const newValue = parseFloat(value.trim());
                            return isNaN(newValue) ? "" : newValue.toFixed(variables[index].decimals);
                        })
                        .filter((value) => value !== "");

                    if (!defaultValue.length) {
                        // If the variable is a number and its new value is invalid,
                        // change its value back to the previous one
                        defaultValue =
                            (Array.isArray(variables[index].defaultValue)
                                ? (variables[index].defaultValue as string[])
                                : [variables[index].defaultValue as string]) ?? getDefaultValueFromType(ProcessVariableType.NUMBER);
                    }
                } else if (variables[index].type === ProcessVariableType.JSON) {
                    // If variable type is JSON, we only split comas which seperate JSON object
                    defaultValue = event.target.value
                        // eslint-disable-next-line quotes
                        .split(/\,\s?(?![^\{]*\})/gm)
                        .map((value) => value.trim())
                        .filter((value) => value !== "");
                } else {
                    // Remove list's empty value
                    // If value is empty, set it to its default value
                    defaultValue = event.target.value
                        .split(",")
                        .map((value) => value.trim())
                        .filter((value) => value !== "");
                }

                if (variables[index].defaultValue !== defaultValue) {
                    const updatedVariable: IProcessVariable = {
                        ...variables[index],
                        defaultValue: defaultValue.length === 1 ? defaultValue[0] : defaultValue,
                    };

                    setVariables(
                        upsertObjectFromArray(variables, updatedVariable, function (variable) {
                            return variable.identifier === this.identifier;
                        })
                    );

                    dispatch(
                        processVariableUpdate({
                            processId: editedProcessId,
                            oldVariableIdentifier: variables[index].identifier,
                            variable: updatedVariable,
                        })
                    );
                }
            }
        };

    /**
     * Sends updated variable id to database
     *
     * @param {number} index - row index
     */
    const handleVariableIdentifierBlur =
        (index: number): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            if (!editedProcessId) {
                return;
            }
            let isIdentifierValid = true;

            if (!isVariableIdentifierAlphanumeric(event.target.value)) {
                snackbar.warning(
                    `${translate("processVariableManager.invalidIdentifier")}${event.target.value ? " (" + event.target.value + ")" : ""}`
                );
                isIdentifierValid = false;
            }
            if (isVariableIdentifierReserved(event.target.value)) {
                snackbar.warning(`${translate("processVariableManager.reservedIdentifier")} (${event.target.value})`);

                isIdentifierValid = false;
            }
            if (!isVariableIdentifierDuplicate(event.target.value, index)) {
                snackbar.warning(`${translate("processVariableManager.duplicateVariable")} ${event.target.value}`);
                isIdentifierValid = false;
            }
            if (isIdentifierValid && event.target.value !== variables[index].identifier) {
                setVariables(
                    upsertObjectFromArray(variables, { ...variables[index], identifier: event.target.value }, function (variable) {
                        return variable.identifier === variables[index].identifier;
                    })
                );

                dispatch(
                    processVariableUpdate({
                        processId: editedProcessId,
                        oldVariableIdentifier: variables[index].identifier,
                        variable: {
                            ...variables[index],
                            identifier: event.target.value,
                        },
                    })
                );

                if (props.onVariableIdentifierChange) {
                    props.onVariableIdentifierChange(variables[index].identifier, event.target.value);
                }
            }
        };

    /**
     * Updates edited process' type value (inner state and database)
     *
     * @param {number} index - row index
     */
    const handleUpdateVariableType =
        (index: number): ((event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>): void => {
            if (editedProcessId) {
                const value = event.target.value as ProcessVariableType;

                const updatedVariable: IProcessVariable = {
                    decimals: value === ProcessVariableType.NUMBER ? DEFAULT_DECIMALS_NUMBER : DEFAULT_DECIMALS, // If new type is not "number", update the decimal value
                    defaultValue: [getDefaultValueFromType(value)], // Change default value depending on the new type
                    identifier: variables[index].identifier,
                    type: value,
                };

                dispatch(
                    processVariableUpdate({
                        processId: editedProcessId,
                        oldVariableIdentifier: variables[index].identifier,
                        variable: updatedVariable,
                    })
                );
            }
        };

    /**
     * Finds every step using the selected variable
     */
    const handleWhereUsedVariable = (): void => {
        setMenuAnchor(undefined);

        if (editedProcess && editedProcessId) {
            dispatch(
                processVariableWhereUsed({
                    processId: editedProcessId,
                    variableIdentifier:
                        selectedVariableIndex >= variables.length
                            ? PROCESS_SYSTEM_VARIABLES[selectedVariableIndex - variables.length].identifier
                            : variables[selectedVariableIndex].identifier,
                })
            ).then((whereUsed) => {
                setWhereUsed([...whereUsed]);
            });
        }
    };

    /**
     * Return true is variable id is not already used, else false
     *
     * @param {string} identifier - variable identifier
     * @param {number} index - variable index
     */
    const isVariableIdentifierDuplicate = (identifier: IProcessVariable["identifier"], index: number): boolean => {
        let currentIndex = 0;
        for (const variable of variables) {
            if (currentIndex !== index && variable.identifier === identifier) {
                return false;
            }
            currentIndex += 1;
        }

        return true;
    };

    /**
     * VARIABLE PROPS UPDATE
     * Effect that updates variable list (inner state) whenever the edited process changes
     */
    useEffect((): void => {
        setVariables(editedProcess ? editedProcess.variables : []);
    }, [editedProcess?.variables]);

    /**
     * VARIABLE MANAGER DIALOG CLOSE
     * Effect that resets the edited/selected variable and clears the search value when closing the variable manager
     */
    useEffect((): void => {
        if (!open) {
            setEditedVariableIndex(-1);
            setSelectedVariableIndex(-1);
            setSearchValue("");
            setShowDeleteDisabledMessage(false);
        }
    }, [open]);

    /**
     * NEWLY SELECTED ROW
     * Unselect the edited variable when selected variable is changed
     */
    useEffect((): void => {
        setEditedVariableIndex(-1);
        setWhereUsed([]);
        setShowDeleteDisabledMessage(false);
    }, [selectedVariableIndex]);

    return (
        <Dialog fullScreen={fullscreen} id="processVariableManagerId" maxWidth="md" onKeyDown={handleDialogKeyDown} open={open}>
            {fullscreen && (
                <DialogTitle className={classes.dialogTitle} disableTypography={true}>
                    {translate("processVariableManager.title")}
                </DialogTitle>
            )}
            <DialogContent className={classes.dialogContent}>
                <div className={classes.dialogHeader}>
                    <KortexSearchField
                        className={classes.searchTexField}
                        InputProps={{
                            id: "processVariableManagerSearchId",
                        }}
                        onChange={handleSearchChange}
                        placeholder={translate("processVariableManager.search")}
                        value={searchValue}
                        clearInput={searchValue ? false : true}
                    >
                        <div className={classes.optionsItem}>
                            <Typography className={classes.filterLabel}>
                                {translate("processVariableManager.showSystemVariable")}
                            </Typography>
                            <Checkbox checked={showSystemVariable} onChange={(): void => setShowSystemVariable(!showSystemVariable)} />
                        </div>
                    </KortexSearchField>
                    <List>
                        <ListItem button={true} disabled={readOnly} id="processVariableManagerAddButtonId" onClick={handleInsertVariable}>
                            <div className={classes.addVariableIconWrapper}>
                                <AddIcon />
                                <Typography className={classes.addVariableLabel}>
                                    {translate("processVariableManager.addVariable")}
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </div>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead className={classes.tableHeader}>
                            <TableRow>
                                <TableCell className={classes.tableCellIdentifier}>
                                    <Typography>{translate("processVariableManager.variableIdentifier")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCellType}>
                                    <Typography>{translate("processVariableManager.type")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCellDecimals}>
                                    <Typography>{translate("processVariableManager.decimals")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCellDefaultValue}>
                                    <Typography>{translate("processVariableManager.defaultValue")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCellIcon} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {variables.map((variable, index): JSX.Element => {
                                const variableDisplayedStyle = isVariableDisplayed(variable) ? "table-row" : "none";
                                return (
                                    <React.Fragment key={index}>
                                        <TableRow
                                            id={"processVariableManagerUserVariableRowId" + index}
                                            onClick={handleRowClick(index)}
                                            onDoubleClick={handleRowDoubleClick(index)}
                                            style={{
                                                backgroundColor: selectedVariableIndex === index ? greyPalette[300] : undefined,
                                                cursor: readOnly ? "default" : "pointer",
                                                display: variableDisplayedStyle,
                                            }}
                                        >
                                            <TableCell
                                                className={classes.tableCellIdentifier}
                                                id="processVariableManagerUserVariableCellIdentifierId"
                                            >
                                                {editedVariableIndex === index ? (
                                                    <KortexTextField
                                                        changedDelayMS={TEXTFIELD_CHANGED_DELAY}
                                                        className={classes.tableCellTextField}
                                                        InputProps={{
                                                            autoFocus: true,
                                                            id: "processVariableManagerUserVariableCellIdentifierTextFieldId" + index,
                                                        }}
                                                        onBlur={handleVariableIdentifierBlur(index)}
                                                        regex={IDENTIFIER_ALPHANUMERIC_VALIDATOR_REGEX}
                                                        standardErrorMsgs={{
                                                            regexNoMatch: translate("processVariableManager.invalidIdentifier"),
                                                        }}
                                                        value={variable.identifier}
                                                        variant="standard"
                                                        TextFieldProps={{
                                                            onClick: handleTextFieldClick,
                                                        }}
                                                    />
                                                ) : (
                                                    <Typography>{variable.identifier}</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableCellType} id="processVariableManagerUserVariableCellTypeId">
                                                {editedVariableIndex === index ? (
                                                    <KortexTextField
                                                        className={classes.tableCellTextField}
                                                        onChange={handleUpdateVariableType(index)}
                                                        InputProps={{
                                                            id: "processVariableManagerUserVariableCellTypeSelectId" + index,
                                                        }}
                                                        TextFieldProps={{
                                                            select: true,
                                                            onClick: handleSelectClick,
                                                        }}
                                                        value={variable.type}
                                                        variant="standard"
                                                    >
                                                        <MenuItem value={ProcessVariableType.STRING}>
                                                            {getTypeLabel(ProcessVariableType.STRING)}
                                                        </MenuItem>
                                                        <MenuItem value={ProcessVariableType.JSON}>
                                                            {getTypeLabel(ProcessVariableType.JSON)}
                                                        </MenuItem>
                                                        <MenuItem value={ProcessVariableType.NUMBER}>
                                                            {getTypeLabel(ProcessVariableType.NUMBER)}
                                                        </MenuItem>
                                                        <MenuItem value={ProcessVariableType.BOOLEAN}>
                                                            {getTypeLabel(ProcessVariableType.BOOLEAN)}
                                                        </MenuItem>
                                                    </KortexTextField>
                                                ) : (
                                                    <Typography>{getTypeLabel(variable.type)}</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCellDecimals}
                                                id="processVariableManagerUserVariableCellDecimalsId"
                                            >
                                                {editedVariableIndex === index && variable.type === ProcessVariableType.NUMBER ? (
                                                    <KortexTextField
                                                        className={classes.tableCellTextField}
                                                        InputProps={{
                                                            id: "processVariableManagerDecimalsTextfieldId" + index,
                                                        }}
                                                        onBlur={handleVariableDecimalsBlur(index)}
                                                        value={variable.decimals}
                                                        variant="standard"
                                                        TextFieldProps={{
                                                            onClick: handleTextFieldClick,
                                                        }}
                                                    />
                                                ) : (
                                                    <Typography>{variable.decimals}</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCellDefaultValue}
                                                id="processVariableManagerUserVariableCellDefaultValueId"
                                            >
                                                {editedVariableIndex === index ? (
                                                    variable.type === ProcessVariableType.BOOLEAN ? (
                                                        <KortexTextField
                                                            className={classes.tableCellTextField}
                                                            onChange={handleVariableDefaultValueBlur(index)}
                                                            value={convertArrayToString(variable.defaultValue)}
                                                            variant="standard"
                                                            TextFieldProps={{
                                                                select: true,
                                                                onClick: handleSelectClick,
                                                            }}
                                                        >
                                                            <MenuItem value="true">
                                                                {translate("processVariableManager.valueTrue")}
                                                            </MenuItem>
                                                            <MenuItem value="false">
                                                                {translate("processVariableManager.valueFalse")}
                                                            </MenuItem>
                                                        </KortexTextField>
                                                    ) : (
                                                        <KortexTextField
                                                            className={classes.tableCellTextField}
                                                            InputProps={{
                                                                id: "processVariableManagerDefaultValueTextfieldId" + index,
                                                            }}
                                                            onBlur={handleVariableDefaultValueBlur(index)}
                                                            value={convertArrayToString(variable.defaultValue)}
                                                            variant="standard"
                                                            TextFieldProps={{
                                                                onClick: handleTextFieldClick,
                                                            }}
                                                        />
                                                    )
                                                ) : (
                                                    <Typography>{convertArrayToString(variable.defaultValue)}</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableCellIcon}>
                                                {whereUsed.length > 0 && index === selectedVariableIndex ? (
                                                    <IconButton
                                                        id={"processVariableManagerUserVariableMenuButtonId" + index}
                                                        onClick={handleCloseWhereUsed}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton disabled={readOnly} onClick={handleMenuOpen(index)}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        {index === selectedVariableIndex && whereUsed.length > 0 && (
                                            <TableRow
                                                className={classes.whereUsedTableRow}
                                                key={index}
                                                style={{ display: variableDisplayedStyle }}
                                            >
                                                <TableCell colSpan={5}>
                                                    {showDeleteDisabledMessage && (
                                                        <Typography className={classes.deleteDisabledMessage} color="error" variant="body2">
                                                            {translate("processVariableManager.deleteDisabledMessage")}
                                                        </Typography>
                                                    )}
                                                    <div className={classes.whereUsedTableCell}>
                                                        {whereUsed.map((action, whereUsedIndex) => (
                                                            <div className={classes.whereUsedTextContainer} key={whereUsedIndex}>
                                                                <Typography variant="body2">{action.actionLabel}</Typography>
                                                                <Typography variant="caption">{action.stepLabel}</Typography>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                            <Typography ref={endOfListRef}></Typography>

                            {!storingMethod &&
                                showSystemVariable &&
                                PROCESS_SYSTEM_VARIABLES.map((systemVariable, systemVariableIndex): JSX.Element => {
                                    const index = systemVariableIndex + variables.length;
                                    const variableDisplayedStyle = isVariableDisplayed(systemVariable) ? "table-row" : "none";
                                    return (
                                        <React.Fragment key={index}>
                                            <TableRow
                                                id={"processVariableManagerSystemVariableRowId" + index}
                                                key={index}
                                                onClick={handleRowClick(index)}
                                                style={{
                                                    backgroundColor: selectedVariableIndex === index ? greyPalette[300] : undefined,
                                                    cursor: readOnly ? "default" : "pointer",
                                                    display: variableDisplayedStyle,
                                                }}
                                            >
                                                <TableCell
                                                    className={classes.tableCellIdentifier}
                                                    id={"processVariableManagerSystemVariableCellIdentifierId" + index}
                                                >
                                                    <Typography id={"processVariableManagerSystemVariableTextIdentifierId" + index}>
                                                        {systemVariable.identifier}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCellType}
                                                    id={"processVariableManagerSystemVariableCellTypeId" + index}
                                                >
                                                    <Typography>{getTypeLabel(systemVariable.type)}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCellDecimals}
                                                    id={"processVariableManagerSystemVariableCellDecimalsId" + index}
                                                >
                                                    <Typography>{systemVariable.decimals}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCellDefaultValue}
                                                    id={"processVariableManagerSystemVariableCellDefaultValueId" + index}
                                                >
                                                    <Typography>{convertArrayToString(systemVariable.defaultValue)}</Typography>
                                                </TableCell>
                                                <TableCell className={classes.tableCellIcon}>
                                                    {whereUsed.length > 0 && index === selectedVariableIndex ? (
                                                        <IconButton
                                                            id={"processVariableManagerSystemVariableMenuButtonId" + index}
                                                            onClick={handleCloseWhereUsed}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton disabled={readOnly} onClick={handleMenuOpen(index)}>
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                            {index === selectedVariableIndex && whereUsed.length > 0 && (
                                                <TableRow
                                                    className={classes.whereUsedTableRow}
                                                    key={index}
                                                    style={{ display: variableDisplayedStyle }}
                                                >
                                                    <TableCell colSpan={5}>
                                                        {showDeleteDisabledMessage && (
                                                            <Typography
                                                                className={classes.deleteDisabledMessage}
                                                                color="error"
                                                                variant="body2"
                                                            >
                                                                {translate("processVariableManager.deleteDisabledMessage")}
                                                            </Typography>
                                                        )}
                                                        <div className={classes.whereUsedTableCell}>
                                                            {whereUsed.map((action, whereUsedIndex) => (
                                                                <div
                                                                    className={classes.whereUsedTextContainer}
                                                                    key={whereUsedIndex}
                                                                    id={"processVariableManagerWhereUsedRowId" + whereUsedIndex}
                                                                >
                                                                    <Typography variant="body2">{action.actionLabel}</Typography>
                                                                    <Typography variant="caption">{action.stepLabel}</Typography>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Menu id="processVariableManagerMenuId" anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
                    <MenuItem
                        disabled={selectedVariableIndex >= variables.length}
                        id="processVariableManagerMenuEditId"
                        onClick={handleEditVariable}
                    >
                        {translate("processVariableManager.edit")}
                    </MenuItem>
                    <MenuItem id="processVariableManagerMenuWhereUsedId" onClick={handleWhereUsedVariable}>
                        {translate("processVariableManager.whereUsed")}
                    </MenuItem>
                    <MenuItem
                        disabled={selectedVariableIndex >= variables.length}
                        id="processVariableManagerMenuDeleteId"
                        onClick={handleDeleteVariable}
                    >
                        {translate("processVariableManager.delete")}
                    </MenuItem>
                </Menu>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                {!selectHidden && storingMethod && (
                    <div className={classes.setVariableRadio}>
                        <Typography>{translate("processVariableManager.overwriteValue")}</Typography>
                        <Radio
                            aria-label="overwrite"
                            checked={storingMethod === ProcessVariableStorer.OVERWRITE}
                            disabled={readOnly}
                            id="processVariableActionOverwriteId"
                            name="radio-button-overwrite-variable"
                            onChange={handleVariableStoringMethodChange}
                            value={ProcessVariableStorer.OVERWRITE}
                        />
                        <Typography>{translate("processVariableManager.appendValue")}</Typography>
                        <Radio
                            aria-label="append"
                            checked={storingMethod === ProcessVariableStorer.APPEND}
                            disabled={readOnly}
                            id="processVariableActionAppendId"
                            name="radio-button-append-variable"
                            onChange={handleVariableStoringMethodChange}
                            value={ProcessVariableStorer.APPEND}
                        />
                    </div>
                )}
                {props.onClear && storingMethod && (
                    <Button
                        className={classes.dialogButtons}
                        color="default"
                        id="processVariableClearButtonId"
                        onClick={handleClear}
                        variant="contained"
                    >
                        {translate("processVariableManager.clear")}
                    </Button>
                )}
                <Button
                    className={classes.dialogButtons}
                    color="default"
                    id="processVariableCancelButtonId"
                    onClick={handleDialogClose}
                    variant="contained"
                >
                    <Cancel className={classes.buttonIcons} />
                    {translate("processVariableManager.close")}
                </Button>
                {!selectHidden && (
                    <Button
                        className={classes.dialogButtons}
                        color="secondary"
                        disabled={selectedVariableIndex === -1 || readOnly}
                        id="processVariableSelectButtonId"
                        onClick={handleDialogSelect}
                        variant="contained"
                    >
                        <Done className={classes.buttonIcons} />
                        {translate("processVariableManager.select")}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
