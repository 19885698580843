import {
    IInsertJobProcessInJobRes,
    IJobUiModel,
    IJobsFiltersOptions,
    IProcessUiModel,
    JobAllRes,
    JobInsertRes,
    JobProcessStatusUpdate,
    JobProgressReq,
    JobProgressRes,
    JobUpdateRes,
    ProcessId,
    TreeNodeId,
    UnwrapAOSPayload,
} from "@kortex/aos-common";

import { jobUpdated } from "../../utilitites/kortex-client/api/job/updated";
import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions, OrUndefined, Unpack } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { processesUpdatedAction, setProcessesLookupTableFromTreeNodesIdUpdated } from "../process-manager/process-actions";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import {
    archiveCompletedJobsAction,
    clearSchedulerAction,
    jobInsertedAction,
    jobUpdatedAction,
    setJobListAction,
} from "./scheduler-actions";

/**
 * Get all jobs
 */
export function jobGetAllJob(
    options?: IStandardThunkOptions,
    limit?: number,
    offset?: number,
    filterOptions?: IJobsFiltersOptions
): StandardThunk<UnwrapAOSPayload<JobAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<JobAllRes>> {
        if (!skipLookup) {
            if (fetchedOnce.was(jobGetAllJob)) {
                return { data: getState().scheduler.jobs };
            }
        }

        return api.services.job
            .getAll({
                limit: limit,
                offset: offset,
                filterOptions: filterOptions,
            })({ timeout: 60_000 })
            .then((jobs) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(jobGetAllJob);
                    if (offset === 0) {
                        dispatch(setJobListAction([...jobs.data]));
                    } else {
                        dispatch(jobUpdatedAction([...jobs.data]));
                    }
                }

                return jobs;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);

                return { data: [] };
            });
    };
}

/**
 * Get all the versions of the processes used in a job
 * All versions are saved in redux to be used during rendering of <ProcessVersionPicker>
 * Return only the version of the processes corresponding to the "JobProcess" of the job.
 *
 * @param {ProcessId[]} processIdList - processId list
 * @param {TreeNodeId[]} treeNodeIdList - treeNodeId list
 *
 * @returns {IProcessUiModel[]} - return the processes corresponding to the "JobProcess" of the job.
 */
export function jobGetAllProcess(
    processIdList: ProcessId[],
    treeNodeIdList: TreeNodeId[]
): StandardThunk<AppState["process"]["processes"]> {
    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<AppState["process"]["processes"]> {
        let processesFromTreeNodesId: IProcessUiModel[] = [];
        const treeNodesIdInStore: TreeNodeId[] = [];
        const treeNodesIdToFetch: TreeNodeId[] = [];

        for (const treeNodeId of treeNodeIdList) {
            /* Check in the lookup table if all of the processes related to the treeNode as been already downloaded */
            if (getState().process.processFromTreeNodeIdLookupTable.indexOf(treeNodeId) !== -1) {
                // Yes, request was already made in the past
                // Don't need to go to the hub
                treeNodesIdInStore.push(treeNodeId);
            } else {
                //define treeNodesIdToFetch to use it as argument of the function to get the data from the database.
                treeNodesIdToFetch.push(treeNodeId);
            }
        }

        if (treeNodesIdInStore.length > 0) {
            const processesInState = getState().process.processes.filter((process) => treeNodesIdInStore.includes(process.treeNodeId));
            processesFromTreeNodesId = processesFromTreeNodesId.concat(processesInState);
        }

        // Otherwise, let's go to the hub
        if (treeNodesIdToFetch.length > 0) {
            try {
                // Search in database
                const processesInDatabase = await api.services.job
                    .getAllProcess({ treeNodesId: treeNodesIdToFetch })({ timeout: 60_000 }) // Can be long to get
                    .then((processes) => {
                        if (processes) {
                            dispatch(setProcessesLookupTableFromTreeNodesIdUpdated(treeNodesIdToFetch));
                            dispatch(processesUpdatedAction([...processes]));

                            return processes;
                        }

                        return [];
                    });
                processesFromTreeNodesId = processesFromTreeNodesId.concat(processesInDatabase);
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }

        return processesFromTreeNodesId.filter((process) => processIdList.includes(process.processId));
    };
}

/**
 * Insert one job
 */
export function jobInsert(jobs: APIPayload<"job", "insert">): StandardThunk<UnwrapAOSPayload<JobInsertRes>> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<JobInsertRes>> => {
        return api.services.job
            .insert(jobs)()
            .then((jobs) => {
                dispatch(jobInsertedAction([...jobs]));

                return jobs;
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }

                return [];
            });
    };
}

/**
 * Insert job process in job
 */
export function jobJobProcessInsert(req: APIPayload<"job", "insertJobProcess">): StandardThunk<IInsertJobProcessInJobRes | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IInsertJobProcessInJobRes | undefined> => {
        return api.services.job
            .insertJobProcess(req)()
            .then((resp) => {
                dispatch(jobUpdatedAction([resp.job]));

                return resp;
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }

                return undefined;
            });
    };
}

/**
 * Insert job routing (process) in job
 */
export function jobJobProcessRoutingInsert(req: APIPayload<"job", "insertJobRouting">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        return api.services.job
            .insertJobRouting(req)()
            .then((job) => {
                dispatch(jobUpdatedAction([job]));
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }
            });
    };
}

export function jobUpdate(jobsToUpdate: APIPayload<"job", "update">): StandardThunk<UnwrapAOSPayload<JobUpdateRes>> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<JobUpdateRes>> => {
        return api.services.job
            .update(jobsToUpdate)()
            .then((jobsUpdated) => {
                if (jobsUpdated && jobUpdated.length > 0) {
                    dispatch(jobUpdatedAction([...jobsUpdated]));
                }

                return jobsUpdated ? jobsUpdated : [];
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }

                return [];
            });
    };
}

export function jobUpdateStatus(req: APIPayload<"job", "updateStatus">): StandardThunk<OrUndefined<Unpack<AppState["scheduler"]["jobs"]>>> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<OrUndefined<Unpack<AppState["scheduler"]["jobs"]>>> => {
        return api.services.job
            .updateStatus(req)()
            .then((job) => {
                if (job) {
                    dispatch(jobUpdatedAction([job]));
                    return job;
                }

                return void 0;
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }

                return void 0;
            });
    };
}

export function jobJobProcessUpdateStatus(req: JobProcessStatusUpdate): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        api.services.job
            .updateJobProcessStatus(req)()
            .then((job) => {
                dispatch(jobUpdatedAction([job]));
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }
            });
    };
}

export function jobJobProcessUpdate(req: APIPayload<"job", "updateJobProcess">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        api.services.job
            .updateJobProcess(req)()
            .then((job) => {
                dispatch(jobUpdatedAction([job]));
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }
            });
    };
}

export function jobJobProcessDelete(jobProcess: APIPayload<"job", "deleteJobProcess">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        api.services.job
            .deleteJobProcess(jobProcess)()
            .then((job) => {
                dispatch(jobUpdatedAction([job]));
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }
            });
    };
}

export function jobJobRoutingDelete(jobProcess: APIPayload<"job", "deleteJobRouting">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        api.services.job
            .deleteJobRouting(jobProcess)()
            .then((job) => {
                dispatch(jobUpdatedAction([job]));
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }
            });
    };
}

export function jobProgress(job: UnwrapAOSPayload<JobProgressReq>): StandardThunk<OrUndefined<UnwrapAOSPayload<JobProgressRes>>> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<OrUndefined<UnwrapAOSPayload<JobProgressRes>>> => {
        return api.services.job
            .progress(job)()
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }

                return undefined;
            });
    };
}

/**
 * Clear the jobs list
 */
export function jobClearJobs(): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(setJobListAction([]));
    };
}

export function jobArchiveAllCompleted(): StandardThunk<IJobUiModel[]> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IJobUiModel[]> => {
        return api.services.job
            .archiveAllCompleted({})()
            .then(({ archivedJobs }) => {
                dispatch(archiveCompletedJobsAction(archivedJobs));
                return archivedJobs;
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }
                return [];
            });
    };
}

/**
 * Get from Erp and create jobs
 */
export function getFromErpAndCreateJobs(): StandardThunk<number> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<number> => {
        return api.services.job
            .getFromErpAndCreateJobs({})({ timeout: 100_000 }) // Can be long to get
            .then((countJobsCreated) => {
                dispatch(clearSchedulerAction());
                return countJobsCreated.createdCount;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);

                return 0;
            });
    };
}
