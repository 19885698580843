import { isEmailValid, isPhoneValid, isTextValid } from "../utilities/utilities";
import { EnumUserStatus } from "../interfaces/models/users/IUserDbModel";
/**
 * Validation of a user database model
 *
 * @param {IUserDbModel} user - User to be validated
 */
export function isUserValid(user) {
    return (isFirstNameValid(user.firstName) &&
        isLastNameValid(user.lastName) &&
        isUserNameValid(user.userName) &&
        isUserCodeValid(user.userCode) &&
        isUserEmailValid(user.email) &&
        isUserPhoneValid(user.cell) &&
        isUserRoleListValid(user.roles) &&
        isUserGroupListValid(user.groups) &&
        isUserLanguageValid(user.language));
}
/**
 * Validation of a user first name
 *
 * @param {string} firstName - User first name
 */
export function isFirstNameValid(firstName) {
    return isTextValid(firstName, 2, 50);
}
/**
 * Validation of a user last name
 *
 * @param {string} lastName - User last name
 */
export function isLastNameValid(lastName) {
    return isTextValid(lastName, 2, 50);
}
/**
 * Validation of a user name
 *
 * @param {string} userName - User name
 */
export function isUserNameValid(userName) {
    return isTextValid(userName, 2, 50);
}
/**
 * Validation of a user code
 *
 * @param {string} [userCode] - User core
 */
export function isUserCodeValid(userCode) {
    return !userCode || isTextValid(userCode, 0, 50);
}
/**
 * Validation of a user email
 *
 * @param {string} [email] - User email
 */
export function isUserEmailValid(email) {
    return !email || isEmailValid(email);
}
/**
 * Validation of a user cell number
 *
 * @param {string} [cell] - User cell number
 */
export function isUserPhoneValid(cell) {
    return !cell || isPhoneValid(cell);
}
/**
 * Validation of a user cell role list
 *
 * @param {number[]} role - User role list
 */
export function isUserRoleListValid(role) {
    return role && role.length > 0;
}
/**
 * Validation of a user cell group list
 *
 * @param {number[]} group - User group list
 */
export function isUserGroupListValid(group) {
    return group && group.length >= 0;
}
/**
 * Validation of a user's language
 *
 * @param {string} language - User language
 */
export function isUserLanguageValid(language) {
    return language.length === 2;
}
/**
 * Generate a new user with default values
 */
export function newUser() {
    return {
        userId: 0,
        userName: "",
        firstName: "",
        lastName: "",
        status: EnumUserStatus.ACTIVE,
        roles: [],
        groups: [],
        language: "",
    };
}
