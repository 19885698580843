import { IBomItem, ProcessActionStepWorkInstructions } from "@kortex/aos-common";
import { deepClone } from "@kortex/utilities";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useContext } from "react";

import { useProcessEditorContext } from "../../../../../../../context";
import { ActionEditorContext } from "../../../../../../context";
import ItemCardOfTooltip from "../../../utilities/ItemCardOfTooltip";

interface IOwnProps {
    item: IBomItem;
    index: number;
}

function WorkInstructionsBomStepItemsRow(props: IOwnProps): JSX.Element {
    const { item, index } = props;
    const { bom } = useProcessEditorContext();
    const { editedActionStep, onChangedStep } = useContext(ActionEditorContext);

    const addDisabled = !bom?.items[item.partNumber] || item.quantity >= bom.items[item.partNumber].quantity;
    const removeDisabled = item.quantity === 0;

    /**
     * handle delete item of the step
     */
    const handleDeleteItem = (): void => {
        const step = deepClone(editedActionStep as ProcessActionStepWorkInstructions);
        delete step.config.bomItems[item.partNumber];

        onChangedStep(step);
    };

    /**
     *
     * @param {IBomItem} item - handles add item to adjust the quantity click
     */
    const handleQuantityIncrease =
        (item: IBomItem): (() => void) =>
        (): void => {
            const bomItem = bom?.items[item.partNumber];
            const updatedQuantity = item.quantity + 1;

            if (bomItem && updatedQuantity <= bomItem.quantity) {
                const step = deepClone(editedActionStep as ProcessActionStepWorkInstructions);
                step.config.bomItems[item.partNumber].quantity = updatedQuantity;

                onChangedStep(step);
            }
        };

    /**
     *
     * @param {IBomItem} item - handles remove item to adjust the quantity click
     */
    const handleQuantityDecrease =
        (item: IBomItem): (() => void) =>
        (): void => {
            const updatedQuantity = item.quantity - 1;

            const step = deepClone(editedActionStep as ProcessActionStepWorkInstructions);
            step.config.bomItems[item.partNumber].quantity = updatedQuantity < 0 ? 0 : updatedQuantity; // Make sure quantity is not below 0

            onChangedStep(step);
        };

    return (
        <TableRow key={index}>
            <TableCell component="th" scope="row" id={`${item.partNumber}BubbleNumberId`}>
                {item.bubbleNumber}
            </TableCell>
            <ItemCardOfTooltip item={item} key={index} />
            <TableCell align="right" width={"5px"} id={`${item.partNumber}AddQtyId`}>
                <IconButton id={`${item.partNumber}addQtyButtonId`} disabled={addDisabled} onClick={handleQuantityIncrease(item)}>
                    <AddIcon fontSize="small" />
                </IconButton>
            </TableCell>
            <TableCell align="center" width={"10%"} id={`${item.partNumber}QuantityId`}>
                {item.quantity}
            </TableCell>
            <TableCell align="left" width={"5px"}>
                <IconButton id={`${item.partNumber}RemoveQtyButtonId`} disabled={removeDisabled} onClick={handleQuantityDecrease(item)}>
                    <RemoveIcon fontSize="small" />
                </IconButton>
            </TableCell>
            <TableCell align="right" width={"5px"}>
                <IconButton onClick={handleDeleteItem} id={`${item.partNumber}ActionStepDeleteButtonId`}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

export default WorkInstructionsBomStepItemsRow;
